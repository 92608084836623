import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '@environments/environment';
import { AuthGuard } from './neoCore/guard/authGuard/auth.guard';
import { ADMIN_DEMO } from './app-rounting-data';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./mapa/mapa.module').then(m => m.MapaModule),
  },
  // {
  //   path: '',
  //   loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
  // },
  // {
  //   path: 'auth',
  //   loadChildren: () => import('./neoAuth/neoAuth.module').then(m => m.NeoAuthModule),
  // },
  // {
  //   path: 'error',
  //   loadChildren: () => import('./neoShared/modules/error/error.module').then(m => m.ErrorModule)
  // },
  // // admin cliente
  // {
  //   path: 'admin',
  //   canActivate: [AuthGuard],
  //   loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  // },
  // ADMIN_DEMO,  
  { path: '', redirectTo: environment.pagina_aterrizaje, pathMatch: 'full'},
  { path: '**',  redirectTo: '/error/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes
    , { scrollPositionRestoration: 'enabled' }
    // { enableTracing: true }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
