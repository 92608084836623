import { TagCampoEnum } from "@app/neoShared/helpers/enums/TagCampo.enum";
import { PerfilPropiedadTagEnum } from "@app/neoShared/helpers/enums/perfil-propiedad-tag.enum";
import { ClasesCampoLayoutCamposPorcentajeFormularioEnum } from "@app/neoShared/helpers/interfaces/IForm-input";
import { IPerfilPropiedad } from "@app/neoShared/helpers/interfaces/IPerfilPropiedad";
import * as L from 'leaflet';

export const PROYECTOS = [
    // {
    //     "id": 1,
    //     "codigo": "a09efde9-6ffd-4a6c-ade5-8303d86fa834",
    //     "liderCoordinador": "Endesa S.A.",
    //     "otrosSocios": "",
    //     "nombre": "Proyecto Hidrógeno Verde El Cierzo",
    //     "descripcion": "El proyecto de hidrógeno verde de El Cierzo consiste en la instalación de un electrolizador de 7,2 MW de potencia unido mediante conexión directa a una planta fotovoltaica y una planta eólica que Endesa S.A. está desarrollando en el término municipal de Alfajarín, en la provincia de Zaragoza. \nEl hidrógeno vede producido, unas 640 toneladas al año, será transportado mediante camión al cliente final, Industrias Químicas del Ebro. Este hidrógeno será utilizado para reemplazar parcialmente el gas natural que se consume actualmente.",
    //     "latitud": "41.69683300",
    //     "longitud": "-0.61666670",
    //     "presupuestoTotal": "20000000",
    //     "financiacionPublica": "50",
    //     "capacidadProduccion": "640",
    //     "capacidadAlmacenamientoTotal": "",
    //     "medioTransporteH2": "",
    //     "numeroEstaciones": 0,
    //     "sectorMoviliario": "",
    //     "sectorIndustrial": "",
    //     "potenciaSectorResidencial": "",
    //     "proyectoEstado": {
    //         "id": 1,
    //         "codigo": "4bc96f42-5db1-4dec-bea1-994042f4eb0d",
    //         "nombre": "aprobado",
    //         "icono": null,
    //         "color": "488c04"
    //     },
    //     "actividadPrincipal": {
    //         "id": 1,
    //         "codigo": "c82e837b-c8e5-4539-b324-448c319e76d5",
    //         "nombre": "Almacenamiento estacional de hidrógeno",
    //         "icono": "prueba.svg"
    //     }
    // }
]


export const PROPIEDADES_PROYECTO: IPerfilPropiedad[] = [
    // {
    //     tag: PerfilPropiedadTagEnum.Basico,
    //     tagCampo: TagCampoEnum.Input,
    //     identificador: 'nombre',
    //     label: 'nombre',
    //     clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent1x],
    // },
    {
        tag: PerfilPropiedadTagEnum.Basico,
        tagCampo: TagCampoEnum.Input,
        identificador: 'liderCoordinador',
        label: 'Entidad Lider / Coordinador',
        clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent6x],
    },
    {
        tag: PerfilPropiedadTagEnum.Basico,
        tagCampo: TagCampoEnum.Input,
        identificador: 'otrosSocios',
        label: 'Otros Socios',
        clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent6x],
    },
    {
        tag: PerfilPropiedadTagEnum.Basico,
        tagCampo: TagCampoEnum.Input,
        identificador: 'proyectoEstado',
        label: 'Estado ',
        clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent1x],
    },
    {
        tag: PerfilPropiedadTagEnum.Basico,
        tagCampo: TagCampoEnum.Input,
        identificador: 'actividadPrincipal',
        label: 'Actividad principal',
        clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent1x],
    },
    {
        tag: PerfilPropiedadTagEnum.Basico,
        tagCampo: TagCampoEnum.Input,
        identificador: 'presupuestoTotal',
        label: 'Presupuesto total',
        clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent1x],
    },
    {
        tag: PerfilPropiedadTagEnum.Basico,
        tagCampo: TagCampoEnum.Input,
        identificador: 'financiacionPublica',
        label: 'Financiación pública',
        clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent1x],
    },
    {
        tag: PerfilPropiedadTagEnum.Basico,
        tagCampo: TagCampoEnum.Input,
        identificador: 'capacidadProduccion',
        label: 'Capacidad de producción (t/a H2)',
        clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent1x],
    },
    {
        tag: PerfilPropiedadTagEnum.Basico,
        tagCampo: TagCampoEnum.Input,
        identificador: 'capacidadAlmacenamientoTotal',
        label: 'Capacidad de almacenamiento total (t/a H2)',
        clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent1x],
    },
    {
        tag: PerfilPropiedadTagEnum.Basico,
        tagCampo: TagCampoEnum.Input,
        identificador: 'medioTransporteH2',
        label: 'Medio de transporte de H2',
        clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent2x],
    },
    {
        tag: PerfilPropiedadTagEnum.Basico,
        tagCampo: TagCampoEnum.Cantidad,
        identificador: 'numeroEstaciones',
        label: 'Nº de estaciones de servicio',
        clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent1x],
    },
    {
        tag: PerfilPropiedadTagEnum.Basico,
        tagCampo: TagCampoEnum.Input,
        identificador: 'sectorMoviliario',
        label: 'Sector movilidad',
        clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent2x],
    },
    {
        tag: PerfilPropiedadTagEnum.Basico,
        tagCampo: TagCampoEnum.Input,
        identificador: 'sectorIndustrial',
        label: 'Sector industrial',
        clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent1x],
    },
    {
        tag: PerfilPropiedadTagEnum.Basico,
        tagCampo: TagCampoEnum.Input,
        identificador: 'potenciaSectorResidencial',
        label: 'Potencia sector residencial (kW)',
        clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent1x],
    },
    {
        tag: PerfilPropiedadTagEnum.Basico,
        tagCampo: TagCampoEnum.Textarea,
        identificador: 'descripcion',
        label: 'Breve descripción del proyecto',
        clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent6x],
    },
    // proyecto estado
    // actividad principal
];