
import { IFicheroExtendido } from "@appNeo/neoShared/helpers/interfaces/IFichero";
import { AccionesTablaEnum } from "@appNeo/neoShared/services/tabla/tabla.service";
import { EdicionTipoEnum } from "./helpers/enums/EdicionTipoEnum";
import { IEdicionSeccion, IEdicionSeccionGrupo } from "./helpers/interfaces/IEdicionSeccion";

export const DEMO_VALORES = {
    "titulo": "Este es el título",
    "texto": "<p>Este es el texto:</p><ul><li>Punto 1</li><li>Punto 2</li></ul><p>&nbsp;</p>",
    "enlace": "https://www.google.com/",
    "imagen": "importes.png"
}
// SECCION EDICION INICIO
export const DEMO_SECCION_EDITABLE_INICIO_ZONA_FAN: IEdicionSeccionGrupo = 

    {
        id: '1',
        nombre: 'Zona Fan',
        items: [
            {
                logintud : 255,
                tipo: EdicionTipoEnum.EDICION_TIPO_ITEM_INPUT,
                obligatorio: true,
                id: 'titulo'
            },
            {
                logintud : 255,
                tipo: EdicionTipoEnum.EDICION_TIPO_ITEM_TINYMCE_COMPLETO,
                obligatorio: true,
                id: 'texto'
            },
            {
                logintud : 255,
                tipo: EdicionTipoEnum.EDICION_TIPO_ITEM_INPUT,
                obligatorio: true,
                id: 'enlace'
            },
            {
                logintud : 0,
                tipo: EdicionTipoEnum.EDICION_TIPO_ITEM_IMAGEN_UNICA,
                obligatorio: true,
                id: 'imagen'
            }
        ],
        edicionItems:[
            {
                numeroItem: 1,
                contenidoItemBloque : [
                    {
                        logintud : 255,
                        tipo: EdicionTipoEnum.EDICION_TIPO_ITEM_INPUT,
                        obligatorio: true,
                        nombre: 'titulo',
                        valor: 'Este es el titulo',
                        idEdicionItem: 1
                    },
                    {
                        logintud : 255,
                        tipo: EdicionTipoEnum.EDICION_TIPO_ITEM_TINYMCE_COMPLETO,
                        obligatorio: true,
                        nombre: 'texto',
                        valor: 'Este es el titulo',
                        idEdicionItem: 2
                    },
                    {
                        logintud : 255,
                        tipo: EdicionTipoEnum.EDICION_TIPO_ITEM_INPUT,
                        obligatorio: true,
                        nombre: 'enlace',
                        valor: 'Este es el titulo',
                        idEdicionItem: 3
                    },
                    {
                        logintud : 0,
                        tipo: EdicionTipoEnum.EDICION_TIPO_ITEM_IMAGEN_UNICA,
                        obligatorio: true,
                        nombre: 'imagen',
                        valor: 'Este es el titulo',
                        idEdicionItem: 4
                    },
                ]

            }
        ],
        multiple: false       
};
export const DEMO_SECCION_EDITABLE_INICIO_TIPOS_EVENTOS: IEdicionSeccionGrupo = 

    {
        id: '2',
        nombre: 'Tipos eventos',
        items: [
            {
                logintud : 255,
                tipo: EdicionTipoEnum.EDICION_TIPO_ITEM_INPUT,
                obligatorio: true,
                id: 'titulo'
            },
            {
                logintud : 255,
                tipo: EdicionTipoEnum.EDICION_TIPO_ITEM_TINYMCE_COMPLETO,
                obligatorio: true,
                id: 'texto'
            },
            {
                logintud : 255,
                tipo: EdicionTipoEnum.EDICION_TIPO_ITEM_INPUT,
                obligatorio: true,
                id: 'enlaceDescubreMas'
            },
            {
                logintud : 255,
                tipo: EdicionTipoEnum.EDICION_TIPO_ITEM_INPUT,
                obligatorio: true,
                id: 'enlaceReserva'
            },
            {
                logintud : 0,
                tipo: EdicionTipoEnum.EDICION_TIPO_ITEM_IMAGEN_UNICA,
                obligatorio: true,
                id: 'imagen'
            },
            {
                logintud : 0,
                tipo: EdicionTipoEnum.EDICION_TIPO_ITEM_IMAGEN_MULTIPLE,
                obligatorio: true,
                id: 'imagen multiple'
            },
        ],
        edicionItems:[
            {
                numeroItem: 1,
                contenidoItemBloque : [
                    {
                        logintud : 255,
                        tipo: EdicionTipoEnum.EDICION_TIPO_ITEM_INPUT,
                        obligatorio: true,
                        nombre: 'titulo_1',
                        valor: 'Este es el titulo',
                        idEdicionItem: 100
                    },
                    {
                        logintud : 255,
                        tipo: EdicionTipoEnum.EDICION_TIPO_ITEM_TINYMCE_COMPLETO,
                        obligatorio: true,
                        nombre: 'texto_1',
                        valor: 'Este es el titulo',
                        idEdicionItem: 101
                    },
                    {
                        logintud : 255,
                        tipo: EdicionTipoEnum.EDICION_TIPO_ITEM_INPUT,
                        obligatorio: true,
                        nombre: 'enlaceDescubreMas_1',
                        valor: 'Este es el titulo',
                        idEdicionItem: 102
                    },
                    {
                        logintud : 255,
                        tipo: EdicionTipoEnum.EDICION_TIPO_ITEM_INPUT,
                        obligatorio: true,
                        nombre: 'enlaceReserva_1',
                        valor: 'Este es el titulo',
                        idEdicionItem: 103
                    },
                    {
                        logintud : 0,
                        tipo: EdicionTipoEnum.EDICION_TIPO_ITEM_IMAGEN_UNICA,
                        obligatorio: true,
                        nombre: 'imagen_1',
                        valor: 'Este es el titulo',
                        idEdicionItem: 104
                    },
                ]

            },
            {
                numeroItem: 2,
                contenidoItemBloque : [
                    {
                        logintud : 255,
                        tipo: EdicionTipoEnum.EDICION_TIPO_ITEM_INPUT,
                        obligatorio: true,
                        nombre: 'titulo_2',
                        valor: 'Este es el titulo',
                        idEdicionItem: 100
                    },
                    {
                        logintud : 255,
                        tipo: EdicionTipoEnum.EDICION_TIPO_ITEM_TINYMCE_COMPLETO,
                        obligatorio: true,
                        nombre: 'texto_2',
                        valor: 'Este es el titulo',
                        idEdicionItem: 101
                    },
                    {
                        logintud : 255,
                        tipo: EdicionTipoEnum.EDICION_TIPO_ITEM_INPUT,
                        obligatorio: true,
                        nombre: 'enlaceDescubreMas_2',
                        valor: 'Este es el titulo',
                        idEdicionItem: 102
                    },
                    {
                        logintud : 255,
                        tipo: EdicionTipoEnum.EDICION_TIPO_ITEM_INPUT,
                        obligatorio: true,
                        nombre: 'enlaceReserva_2',
                        valor: 'Este es el titulo',
                        idEdicionItem: 103
                    },
                    {
                        logintud : 0,
                        tipo: EdicionTipoEnum.EDICION_TIPO_ITEM_IMAGEN_UNICA,
                        obligatorio: true,
                        nombre: 'imagen_2',
                        valor: 'Este es el titulo',
                        idEdicionItem: 104
                    },
                ]

            },
            
        ],
        multiple: true,
        minimo: 1,

};

// SECCION EDICION ZONA FAN
export const DEMO_SECCION_EDITABLE_ZONAFAN_NUESTRA_HISTORIA: IEdicionSeccionGrupo = 

    {
        id: '3',
        nombre: 'Zona Fan',
        items: [
            {
                logintud : 255,
                tipo: EdicionTipoEnum.EDICION_TIPO_ITEM_INPUT,
                obligatorio: true,
                id: 'titulo'
            },
            {
                logintud : 255,
                tipo: EdicionTipoEnum.EDICION_TIPO_ITEM_TINYMCE_COMPLETO,
                obligatorio: true,
                id: 'texto'
            },
            {
                logintud : 0,
                tipo: EdicionTipoEnum.EDICION_TIPO_ITEM_IMAGEN_MULTIPLE,
                obligatorio: true,
                id: 'imagen'
            },
        ],
        multiple: false       
};
// SECCION EDICION CUMPLEAÑOS
export const DEMO_SECCION_EDITABLE_CUMPLEANOS_CUMPLEANOS: IEdicionSeccionGrupo = 

    {
        id: '4',
        nombre: 'Cumpleaños',
        items: [
            {
                logintud : 255,
                tipo: EdicionTipoEnum.EDICION_TIPO_ITEM_INPUT,
                obligatorio: true,
                id: 'titulo'
            },
            {
                logintud : 255,
                tipo: EdicionTipoEnum.EDICION_TIPO_ITEM_TINYMCE_COMPLETO,
                obligatorio: true,
                id: 'texto'
            },
            {
                logintud : 255,
                tipo: EdicionTipoEnum.EDICION_TIPO_ITEM_INPUT,
                obligatorio: true,
                id: 'enlace'
            },
            {
                logintud : 0,
                tipo: EdicionTipoEnum.EDICION_TIPO_ITEM_IMAGEN_MULTIPLE,
                obligatorio: true,
                id: 'imagen',
            },
        ],
        multiple: true      
};

// SECCION EDICION COMUNIONES
export const DEMO_SECCION_EDITABLE_COMUNIONES_COMUNIONES: IEdicionSeccionGrupo = 

    {
        id: '5',
        nombre: 'Comuniones',
        items: [
            {
                logintud : 255,
                tipo: EdicionTipoEnum.EDICION_TIPO_ITEM_INPUT,
                obligatorio: false,
                id: 'titulo'
            },
            {
                logintud : 255,
                tipo: EdicionTipoEnum.EDICION_TIPO_ITEM_TINYMCE_COMPLETO,
                obligatorio: true,
                id: 'texto'
            },
            {
                logintud : 255,
                tipo: EdicionTipoEnum.EDICION_TIPO_ITEM_INPUT,
                obligatorio: false,
                id: 'enlace'
            },
            {
                logintud : 0,
                tipo: EdicionTipoEnum.EDICION_TIPO_ITEM_IMAGEN_UNICA,
                obligatorio: true,
                id: 'imagen',
            },
        ],
        multiple: false      
};
export const DEMO_SECCION_EDITABLE_COMUNIONES_SERVICIOS_EXTRA: IEdicionSeccionGrupo = 

    {
        id: '6',
        nombre: 'Servicios extras',
        items: [
            {
                logintud : 0,
                tipo: EdicionTipoEnum.EDICION_TIPO_ITEM_IMAGEN_UNICA,
                obligatorio: true,
                id: 'imagen'
            },
            {
                logintud : 50,
                tipo: EdicionTipoEnum.EDICION_TIPO_ITEM_INPUT,
                obligatorio: true,
                id: 'titulo'
            }            
        ],
        multiple: true,
        minimo: 1      
};



export const DEMO_SECCIONES : IEdicionSeccion [] = [
    {
        nombre:  'Inicio',
        id: '1',
        grupos: [
            DEMO_SECCION_EDITABLE_INICIO_ZONA_FAN,
            DEMO_SECCION_EDITABLE_INICIO_TIPOS_EVENTOS
        ]
    },
    {
        nombre:  'Zona Fan',
        id: '2',
        grupos: [DEMO_SECCION_EDITABLE_ZONAFAN_NUESTRA_HISTORIA]
    },
    {
        nombre:  'Cumpleaños',
        id: '3',
        grupos: [DEMO_SECCION_EDITABLE_CUMPLEANOS_CUMPLEANOS]
    },
    {
        nombre:  'Comuniones',
        id: '4',
        grupos: [DEMO_SECCION_EDITABLE_COMUNIONES_COMUNIONES,
            DEMO_SECCION_EDITABLE_COMUNIONES_SERVICIOS_EXTRA
        ]
    }

];



export const DEMOARCHIVOS_EDICION: IFicheroExtendido[] = [
    {
      id: '1',
      iconDrag: {nombre: 'Icono Arrastre Drag', class: 'icon-20 icon-ibm--draggable text-grey500'},
      nombre: 'imagen1.jpg',
      extension: '.jpg',
      peso: 1500,
      posicion: 1,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: 'https://picsum.photos/536/354'
      },
      acciones: [
        {
          idAccion: AccionesTablaEnum.Editar,
          tooltip: 'Editar',
          icono: true,
          iconClass: 'icon-20 icon-ibm--edit',
          disabled: false,
        },
        {
          idAccion: AccionesTablaEnum.Eliminar,
          tooltip: 'Eliminar',
          icono: true,
          iconClass: 'icon-20 icon-ibm--trash-can text-danger',
          disabled: false,
        },
      ],
    },
    {
      id: '2',
      iconDrag: {nombre: 'Icono Arrastre Drag', class: 'icon-20 icon-ibm--draggable text-grey500'},
      nombre: 'imagen2.jpg',
      extension: '.jpg',
      peso: 200,
      posicion: 3,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: 'https://www.cleverfiles.com/howto/wp-content/uploads/2018/03/minion.jpg'
      },
      acciones: [
        {
          idAccion: AccionesTablaEnum.Editar,
          tooltip: 'Editar',
          icono: true,
          iconClass: 'icon-20 icon-ibm--edit',
          disabled: false,
        },
        {
          idAccion: AccionesTablaEnum.Eliminar,
          tooltip: 'Eliminar',
          icono: true,
          iconClass: 'icon-20 icon-ibm--trash-can text-danger',
          disabled: false,
        },
      ],
    },
    {
      id: '3',
      iconDrag: {nombre: 'Icono Arrastre Drag', class: 'icon-20 icon-ibm--draggable text-grey500'},
      nombre: 'imagen3.pdf',
      extension: '.pdf',
      peso: 1300,
      posicion: 2,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: ''
      },
      acciones: [
        {
          idAccion: AccionesTablaEnum.Editar,
          tooltip: 'Editar',
          icono: true,
          iconClass: 'icon-20 icon-ibm--edit',
          disabled: false,
        },
        {
          idAccion: AccionesTablaEnum.Eliminar,
          tooltip: 'Eliminar',
          icono: true,
          iconClass: 'icon-20 icon-ibm--trash-can text-danger',
          disabled: false,
        },
      ],
    },
    {
      id: '4',
      iconDrag: {nombre: 'Icono Arrastre Drag', class: 'icon-20 icon-ibm--draggable text-grey500'},
      nombre: 'imagen4.jpg',
      extension: '.jpg',
      peso: 2300,
      posicion: 0,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: 'https://s03.s3c.es/imag/_v0/770x420/1/4/f/El-plnaeta-Tierra-de-cristal-iStock.jpg'
      },
      acciones: [
        {
          idAccion: AccionesTablaEnum.Editar,
          tooltip: 'Editar',
          icono: true,
          iconClass: 'icon-20 icon-ibm--edit',
          disabled: false,
        },
        {
          idAccion: AccionesTablaEnum.Eliminar,
          tooltip: 'Eliminar',
          icono: true,
          iconClass: 'icon-20 icon-ibm--trash-can text-danger',
          disabled: false,
        },
      ],
    },
    {
      id: '5',
      iconDrag: {nombre: 'Icono Arrastre Drag', class: 'icon-20 icon-ibm--draggable text-grey500'},
      nombre: 'imagen5.jpg',
      extension: '.jpg',
      peso: 3300,
      posicion: 4,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: 'https://kinsta.com/es/wp-content/uploads/sites/8/2019/09/jpg-vs-jpeg.jpg'
      },
      acciones: [
        {
          idAccion: AccionesTablaEnum.Editar,
          tooltip: 'Editar',
          icono: true,
          iconClass: 'icon-20 icon-ibm--edit',
          disabled: false,
        },
        {
          idAccion: AccionesTablaEnum.Eliminar,
          tooltip: 'Eliminar',
          icono: true,
          iconClass: 'icon-20 icon-ibm--trash-can text-danger',
          disabled: false,
        },
      ],
    }
  ];


//  DATOS REALES
export const DEMO_SECCIONES_MENU: any[] = [
    {
        "id": "15",
        "nombre": "faq",
        "idiomas": {
            "es": {
                "label": "Preguntas frecuentes"
            }
        }
    },
    {
        "id": "14",
        "nombre": "cookies",
        "idiomas": {
            "es": {
                "label": "Política de cookies"
            }
        }
    },
    {
        "id": "13",
        "nombre": "legal",
        "idiomas": {
            "es": {
                "label": "Aviso legal"
            }
        }
    },
    {
        "id": "12",
        "nombre": "contacto",
        "idiomas": {
            "es": {
                "label": "Contacto"
            }
        }
    },
    // {
    //     "id": "7",
    //     "nombre": "inicio",
    //     "idiomas": {
    //         "es": {
    //             "label": "Inicio"
    //         }
    //     }
    // }
];

export const DEMO_SECCIONES_COMPLETO: any[] = [
    {
        "id": "15",
        "nombre": "faq",
        "filas": null,
        "idiomas": {
            "es": {
                "label": "Preguntas frecuentes"
            }
        },
        "grupos": [
            {
                "id": "37",
                "nombre": "faq-meta",
                "multiple": false,
                "cantidadElementosBloque": "2",
                "minimo": "1",
                "maximo": null,
                "idiomas": {
                    "es": {
                        "label": "Metadatos (para buscadores y similares)"
                    }
                },
                "items": [
                    {
                        "id": "603",
                        "longitud": "255",
                        "obligatorio": false,
                        "temporal": "0",
                        "tipo": "Input",
                        "idTipoItem": "1",
                        "nombre": "meta-tit",
                        "nombreInicial": "",
                        "idiomas": {
                            "es": {
                                "label": "Título (meta title)",
                                "texto": "Preguntas frecuentes"
                            }
                        }
                    },
                    {
                        "id": "604",
                        "longitud": "255",
                        "obligatorio": false,
                        "temporal": "0",
                        "tipo": "Input",
                        "idTipoItem": "1",
                        "nombre": "meta-des",
                        "nombreInicial": "",
                        "idiomas": {
                            "es": {
                                "label": "Descripción (meta description)",
                                "texto": "Preguntas frecuentes"
                            }
                        }
                    }
                ]
            },
            {
                "id": "38",
                "nombre": "faq-txt",
                "multiple": false,
                "cantidadElementosBloque": "1",
                "minimo": "1",
                "maximo": null,
                "idiomas": {
                    "es": {
                        "label": "Textos"
                    }
                },
                "items": [
                    {
                        "id": "605",
                        "longitud": "255",
                        "obligatorio": false,
                        "temporal": "0",
                        "tipo": "Input",
                        "idTipoItem": "1",
                        "nombre": "txt-tit",
                        "nombreInicial": "",
                        "idiomas": {
                            "es": {
                                "label": "Título principal",
                                "texto": "Preguntas frecuentes"
                            }
                        }
                    }
                ]
            },
            {
                "id": "39",
                "nombre": "faq-faq",
                "multiple": true,
                "cantidadElementosBloque": "2",
                "minimo": "1",
                "maximo": null,
                "idiomas": {
                    "es": {
                        "label": "Listado de preguntas"
                    }
                },
                "items": [
                    {
                        "id": "606",
                        "longitud": "255",
                        "obligatorio": false,
                        "temporal": "0",
                        "tipo": "Input",
                        "idTipoItem": "1",
                        "nombre": "faq-tit_1",
                        "nombreInicial": "faq-tit",
                        "idiomas": {
                            "es": {
                                "label": "Pregunta 1",
                                "texto": "¿Cómo reservar un evento?"
                            }
                        }
                    },
                    {
                        "id": "607",
                        "longitud": "20000",
                        "obligatorio": false,
                        "temporal": "0",
                        "tipo": "Tinymce minimo",
                        "idTipoItem": "4",
                        "nombre": "faq-txt_1",
                        "nombreInicial": "faq-txt",
                        "idiomas": {
                            "es": {
                                "label": "Respuesta 1",
                                "texto": "<p>Para reservar un evento en nuestra finca debes entrar en nuestra web, en el apartado RESERVAS e ir cubriendo todos los datos del día que quieres celebrar tu fiesta: fecha, horario, pack elegido… A través de nuestra pasarela de pago de la web bloquearás la fecha y los servicios escogidos una vez pagada la señal de reserva (50€)</p><p>Para comuniones y otros eventos en el apartado reservas podrás dejarnos tus datos y nos pondremos en contacto contigo lo antes posible para concretar todos los detalles</p>"
                            }
                        }
                    },
                    {
                        "id": "616",
                        "longitud": "255",
                        "obligatorio": false,
                        "temporal": "0",
                        "tipo": "Input",
                        "idTipoItem": "1",
                        "nombre": "faq-tit_2",
                        "nombreInicial": "faq-tit",
                        "idiomas": {
                            "es": {
                                "label": "Pregunta 2",
                                "texto": "Una vez hecha la reserva, ¿puedo modificar la fecha/hora de mi cumpleaños?"
                            }
                        }
                    },
                    {
                        "id": "617",
                        "longitud": "20000",
                        "obligatorio": false,
                        "temporal": "0",
                        "tipo": "Tinymce minimo",
                        "idTipoItem": "4",
                        "nombre": "faq-txt_2",
                        "nombreInicial": "faq-txt",
                        "idiomas": {
                            "es": {
                                "label": "Respuesta 2",
                                "texto": "<p>SÍ. Es posible cambiar la fecha/hora de tu reserva contactando con nosotros en el mail reservas@zonafan.es, al menos con 48 horas de antelación. El cambio será efectivo una vez usted reciba la confirmación por nuestra parte mediante e-mail.</p>"
                            }
                        }
                    },
                    {
                        "id": "628",
                        "longitud": "255",
                        "obligatorio": false,
                        "temporal": "0",
                        "tipo": "Input",
                        "idTipoItem": "1",
                        "nombre": "faq-tit_3",
                        "nombreInicial": "faq-tit",
                        "idiomas": {
                            "es": {
                                "label": "Pregunta 3",
                                "texto": "¿Qué pasa con mi reserva si mi hijo se pone enfermo?"
                            }
                        }
                    },
                    {
                        "id": "629",
                        "longitud": "20000",
                        "obligatorio": false,
                        "temporal": "0",
                        "tipo": "Tinymce minimo",
                        "idTipoItem": "4",
                        "nombre": "faq-txt_3",
                        "nombreInicial": "faq-txt",
                        "idiomas": {
                            "es": {
                                "label": "Respuesta 3",
                                "texto": "<p>NO TENDRAS NINGUN PROBLEMA. Acordaremos contigo un cambio de fecha y mantendremos la señal entregada.</p>"
                            }
                        }
                    },
                    {
                        "id": "630",
                        "longitud": "255",
                        "obligatorio": false,
                        "temporal": "0",
                        "tipo": "Input",
                        "idTipoItem": "1",
                        "nombre": "faq-tit_4",
                        "nombreInicial": "faq-tit",
                        "idiomas": {
                            "es": {
                                "label": "Pregunta 4",
                                "texto": "¿Cuándo tengo que confirmar los asistentes al evento?"
                            }
                        }
                    },
                    {
                        "id": "631",
                        "longitud": "20000",
                        "obligatorio": false,
                        "temporal": "0",
                        "tipo": "Tinymce minimo",
                        "idTipoItem": "4",
                        "nombre": "faq-txt_4",
                        "nombreInicial": "faq-txt",
                        "idiomas": {
                            "es": {
                                "label": "Respuesta 4",
                                "texto": "<p>Una vez que reservas tu fiesta en la web tendrás un código de usuario que te permitirá entrar en la ficha de tu fiesta y modificar el número de asistentes hasta 24 horas antes del evento A partir de ese momento podrá llamarnos o escribirnos al mail reservas@zonafan.es para cualquier cambio.</p>"
                            }
                        }
                    },
                    {
                        "id": "632",
                        "longitud": "255",
                        "obligatorio": false,
                        "temporal": "0",
                        "tipo": "Input",
                        "idTipoItem": "1",
                        "nombre": "faq-tit_5",
                        "nombreInicial": "faq-tit",
                        "idiomas": {
                            "es": {
                                "label": "Pregunta 5",
                                "texto": "¿Las instalaciones son exclusivas para mi evento?"
                            }
                        }
                    },
                    {
                        "id": "633",
                        "longitud": "20000",
                        "obligatorio": false,
                        "temporal": "0",
                        "tipo": "Tinymce minimo",
                        "idTipoItem": "4",
                        "nombre": "faq-txt_5",
                        "nombreInicial": "faq-txt",
                        "idiomas": {
                            "es": {
                                "label": "Respuesta 5",
                                "texto": "<p>Existe la posibilidad de reservar en exclusiva las instalaciones para tu evento. Habitualmente, en la celebración de cumpleaños, celebramos varios eventos a la vez ya que contamos con más de 3000m2 de aire libre y 1000m2 cubiertos lo que nos permite poder compartir las instalaciones sin que ello suponga un inconveniente para los asistentes.</p>"
                            }
                        }
                    },
                    {
                        "id": "634",
                        "longitud": "255",
                        "obligatorio": false,
                        "temporal": "0",
                        "tipo": "Input",
                        "idTipoItem": "1",
                        "nombre": "faq-tit_6",
                        "nombreInicial": "faq-tit",
                        "idiomas": {
                            "es": {
                                "label": "Pregunta 6",
                                "texto": "¿Cuál es la duración de un cumpleaños?"
                            }
                        }
                    },
                    {
                        "id": "635",
                        "longitud": "20000",
                        "obligatorio": false,
                        "temporal": "0",
                        "tipo": "Tinymce minimo",
                        "idTipoItem": "4",
                        "nombre": "faq-txt_6",
                        "nombreInicial": "faq-txt",
                        "idiomas": {
                            "es": {
                                "label": "Respuesta 6",
                                "texto": "<p>La duración del cumpleaños dependerá de la fiesta elegida. Puedes ver las fiestas disponibles en nuestra web en el apartado Cumpleaños.</p>"
                            }
                        }
                    },
                    {
                        "id": "638",
                        "longitud": "255",
                        "obligatorio": false,
                        "temporal": "0",
                        "tipo": "Input",
                        "idTipoItem": "1",
                        "nombre": "faq-tit_7",
                        "nombreInicial": "faq-tit",
                        "idiomas": {
                            "es": {
                                "label": "Pregunta 7",
                                "texto": "¿Qué opciones de menús para niños hay en Zona Fan?"
                            }
                        }
                    },
                    {
                        "id": "639",
                        "longitud": "20000",
                        "obligatorio": false,
                        "temporal": "0",
                        "tipo": "Tinymce minimo",
                        "idTipoItem": "4",
                        "nombre": "faq-txt_7",
                        "nombreInicial": "faq-txt",
                        "idiomas": {
                            "es": {
                                "label": "Respuesta 7",
                                "texto": "<p><strong>Menú Fan</strong>: Pizza jamón y queso, Hot Dog, sándwiches nocilla, chocolatina, bolsa snack, tarta chocolate y helados. Agua y refrescos.<br><br><strong>Menú choco-Fan</strong>: Chocolate bonilla, churros, minicroissant, bolsa snack, tarta y helados. Agua y refrescos.<br><br><strong>Menú Bio-Fan</strong>: Brochetas fruta, mini pulquitas de queso, pavo o atún, bolsa snack saludable, bizcocho casero y helados. Agua, zumos y refrescos.</p>"
                            }
                        }
                    },
                    {
                        "id": "640",
                        "longitud": "255",
                        "obligatorio": false,
                        "temporal": "0",
                        "tipo": "Input",
                        "idTipoItem": "1",
                        "nombre": "faq-tit_8",
                        "nombreInicial": "faq-tit",
                        "idiomas": {
                            "es": {
                                "label": "Pregunta 8",
                                "texto": "En las fiestas Teens y Tik Tok & Party Night, ¿qué incluye el buffet ?"
                            }
                        }
                    },
                    {
                        "id": "641",
                        "longitud": "20000",
                        "obligatorio": false,
                        "temporal": "0",
                        "tipo": "Tinymce minimo",
                        "idTipoItem": "4",
                        "nombre": "faq-txt_8",
                        "nombreInicial": "faq-txt",
                        "idiomas": {
                            "es": {
                                "label": "Respuesta 8",
                                "texto": "<p>Prepararemos un buffet dulce y salado para los asistentes que incluye: Pizza Jamón y queso, Hot dogs, sandwiches, snacks, chocolates y gominolas variadas y mini bolitas de donuts, tarta chocolate y helados. Agua y refrescos.</p>"
                            }
                        }
                    },
                    {
                        "id": "642",
                        "longitud": "255",
                        "obligatorio": false,
                        "temporal": "0",
                        "tipo": "Input",
                        "idTipoItem": "1",
                        "nombre": "faq-tit_9",
                        "nombreInicial": "faq-tit",
                        "idiomas": {
                            "es": {
                                "label": "Pregunta 9",
                                "texto": "¿Qué opciones de menús para adultos hay en Zona Fan?"
                            }
                        }
                    },
                    {
                        "id": "643",
                        "longitud": "20000",
                        "obligatorio": false,
                        "temporal": "0",
                        "tipo": "Tinymce minimo",
                        "idTipoItem": "4",
                        "nombre": "faq-txt_9",
                        "nombreInicial": "faq-txt",
                        "idiomas": {
                            "es": {
                                "label": "Respuesta 9",
                                "texto": "<p><strong>Menú Fan</strong>: 25€?Café y agua ilimitada. 4l de refrescos. Snack dulce y salado.&nbsp;<br><br><strong>Menú Extra-Fan</strong>: 60€?Café y agua ilimitada. 4l de refrescos. Snack dulce y salado, tortilla y empanada de bonito.<br><br><strong>Extras</strong>: Consultar precios?Tortilla, empanada, bandeja de embutido y queso, mini saladitos dulces o salados , tarta chocolate, cervezas, vino..</p>"
                            }
                        }
                    },
                    {
                        "id": "644",
                        "longitud": "255",
                        "obligatorio": false,
                        "temporal": "0",
                        "tipo": "Input",
                        "idTipoItem": "1",
                        "nombre": "faq-tit_10",
                        "nombreInicial": "faq-tit",
                        "idiomas": {
                            "es": {
                                "label": "Pregunta 10",
                                "texto": "¿Tenéis menús/ meriendas para alérgicos/ intolerantes?"
                            }
                        }
                    },
                    {
                        "id": "645",
                        "longitud": "20000",
                        "obligatorio": false,
                        "temporal": "0",
                        "tipo": "Tinymce minimo",
                        "idTipoItem": "4",
                        "nombre": "faq-txt_10",
                        "nombreInicial": "faq-txt",
                        "idiomas": {
                            "es": {
                                "label": "Respuesta 10",
                                "texto": "<p>SI. Queremos que la experiencia en nuestros cumpleaños sea excelente para todos los participantes, de manera que tendremos en cuenta todas las medidas sanitarias necesarias para que cualquier alergia/intolerancia no sea un impedimento para asistir a cualquier celebración en Zona Fan.<br>En el proceso de reserva podrás indicarnos los niños y el tipo de alergia/intolerancia.</p>"
                            }
                        }
                    },
                    {
                        "id": "646",
                        "longitud": "255",
                        "obligatorio": false,
                        "temporal": "0",
                        "tipo": "Input",
                        "idTipoItem": "1",
                        "nombre": "faq-tit_11",
                        "nombreInicial": "faq-tit",
                        "idiomas": {
                            "es": {
                                "label": "Pregunta 11",
                                "texto": "¿Los padres pueden estar en la celebración?"
                            }
                        }
                    },
                    {
                        "id": "647",
                        "longitud": "20000",
                        "obligatorio": false,
                        "temporal": "0",
                        "tipo": "Tinymce minimo",
                        "idTipoItem": "4",
                        "nombre": "faq-txt_11",
                        "nombreInicial": "faq-txt",
                        "idiomas": {
                            "es": {
                                "label": "Respuesta 11",
                                "texto": "<p>SI. En zona fan contamos con un espacio interior de más de 200m2 para que los padres puedan disfrutar también de la celebración tomando un café o un refresco y charlando con sus invitados.<br>Consulta nuestros MENÚS para PADRES.</p>"
                            }
                        }
                    },
                    {
                        "id": "648",
                        "longitud": "255",
                        "obligatorio": false,
                        "temporal": "0",
                        "tipo": "Input",
                        "idTipoItem": "1",
                        "nombre": "faq-tit_12",
                        "nombreInicial": "faq-tit",
                        "idiomas": {
                            "es": {
                                "label": "Pregunta 12",
                                "texto": "¿Puedo llevar comida y/o bebida para niños o padres?"
                            }
                        }
                    },
                    {
                        "id": "649",
                        "longitud": "20000",
                        "obligatorio": false,
                        "temporal": "0",
                        "tipo": "Tinymce minimo",
                        "idTipoItem": "4",
                        "nombre": "faq-txt_12",
                        "nombreInicial": "faq-txt",
                        "idiomas": {
                            "es": {
                                "label": "Respuesta 12",
                                "texto": "<p>Según el Reglamento 852/2004 somos responsables de garantizar la salubridad y trazabilidad de los productos consumidos en Zona Fan por tanto NO se permite el acceso con comida o bebida del exterior.</p>"
                            }
                        }
                    },
                    {
                        "id": "650",
                        "longitud": "255",
                        "obligatorio": false,
                        "temporal": "0",
                        "tipo": "Input",
                        "idTipoItem": "1",
                        "nombre": "faq-tit_13",
                        "nombreInicial": "faq-tit",
                        "idiomas": {
                            "es": {
                                "label": "Pregunta 13",
                                "texto": "¿Cuáles son las formas de pago? "
                            }
                        }
                    },
                    {
                        "id": "651",
                        "longitud": "20000",
                        "obligatorio": false,
                        "temporal": "0",
                        "tipo": "Tinymce minimo",
                        "idTipoItem": "4",
                        "nombre": "faq-txt_13",
                        "nombreInicial": "faq-txt",
                        "idiomas": {
                            "es": {
                                "label": "Respuesta 13",
                                "texto": "<p>Efectivo, Tarjetas de Débito y Crédito.</p>"
                            }
                        }
                    }
                ]
            }
        ]
    },
    {
        "id": "14",
        "nombre": "cookies",
        "filas": null,
        "idiomas": {
            "es": {
                "label": "Política de cookies"
            }
        },
        "grupos": [
            {
                "id": "36",
                "nombre": "cookies-textos",
                "multiple": false,
                "cantidadElementosBloque": "1",
                "minimo": "1",
                "maximo": null,
                "idiomas": {
                    "es": {
                        "label": "Contenidos"
                    }
                },
                "items": [
                    {
                        "id": "584",
                        "longitud": "20000",
                        "obligatorio": false,
                        "temporal": "0",
                        "tipo": "Tinymce Completo",
                        "idTipoItem": "3",
                        "nombre": "txt",
                        "nombreInicial": "",
                        "idiomas": {
                            "es": {
                                "label": "Texto",
                                "texto": null
                            }
                        }
                    }
                ]
            }
        ]
    },
    {
        "id": "13",
        "nombre": "legal",
        "filas": null,
        "idiomas": {
            "es": {
                "label": "Aviso legal"
            }
        },
        "grupos": [
            {
                "id": "35",
                "nombre": "legal-textos",
                "multiple": false,
                "cantidadElementosBloque": "1",
                "minimo": "1",
                "maximo": null,
                "idiomas": {
                    "es": {
                        "label": "Contenidos"
                    }
                },
                "items": [
                    {
                        "id": "583",
                        "longitud": "20000",
                        "obligatorio": false,
                        "temporal": "0",
                        "tipo": "Tinymce Completo",
                        "idTipoItem": "3",
                        "nombre": "txt",
                        "nombreInicial": "",
                        "idiomas": {
                            "es": {
                                "label": "Texto",
                                "texto": null
                            }
                        }
                    }
                ]
            }
        ]
    },
    {
        "id": "12",
        "nombre": "contacto",
        "filas": null,
        "idiomas": {
            "es": {
                "label": "Contacto"
            }
        },
        "grupos": [
            {
                "id": "32",
                "nombre": "contacto-hq",
                "multiple": false,
                "cantidadElementosBloque": "5",
                "minimo": "1",
                "maximo": null,
                "idiomas": {
                    "es": {
                        "label": "Sede central"
                    }
                },
                "items": [
                    {
                        "id": "573",
                        "longitud": "255",
                        "obligatorio": false,
                        "temporal": "0",
                        "tipo": "Input",
                        "idTipoItem": "1",
                        "nombre": "hq-dir-tit",
                        "nombreInicial": "",
                        "idiomas": {
                            "es": {
                                "label": "Nombre",
                                "texto": "Dónde estamos"
                            }
                        }
                    },
                    {
                        "id": "574",
                        "longitud": "255",
                        "obligatorio": false,
                        "temporal": "0",
                        "tipo": "Textarea",
                        "idTipoItem": "2",
                        "nombre": "hq-dir-txt",
                        "nombreInicial": "",
                        "idiomas": {
                            "es": {
                                "label": "Dirección",
                                "texto": "Travesía alto do Castro, 8\n15175 - Carral - A Coruña"
                            }
                        }
                    },
                    {
                        "id": "575",
                        "longitud": "255",
                        "obligatorio": false,
                        "temporal": "0",
                        "tipo": "Input",
                        "idTipoItem": "1",
                        "nombre": "hq-dir-lnk",
                        "nombreInicial": "",
                        "idiomas": {
                            "es": {
                                "label": "Enlace Google Maps",
                                "texto": ""
                            }
                        }
                    },
                    {
                        "id": "576",
                        "longitud": "255",
                        "obligatorio": false,
                        "temporal": "0",
                        "tipo": "Input",
                        "idTipoItem": "1",
                        "nombre": "hq-tel1",
                        "nombreInicial": "",
                        "idiomas": {
                            "es": {
                                "label": "Teléfono #1",
                                "texto": "0034900000000"
                            }
                        }
                    },
                    {
                        "id": "577",
                        "longitud": "255",
                        "obligatorio": false,
                        "temporal": "0",
                        "tipo": "Input",
                        "idTipoItem": "1",
                        "nombre": "hq-mail1",
                        "nombreInicial": "",
                        "idiomas": {
                            "es": {
                                "label": "Email #1",
                                "texto": "info@zonafan.es"
                            }
                        }
                    },
                    {
                        "id": "618",
                        "longitud": "255",
                        "obligatorio": false,
                        "temporal": "0",
                        "tipo": "Input",
                        "idTipoItem": "1",
                        "nombre": "hq-info-tit",
                        "nombreInicial": "",
                        "idiomas": {
                            "es": {
                                "label": "Cabercera info adicional",
                                "texto": "Horario"
                            }
                        }
                    },
                    {
                        "id": "619",
                        "longitud": "255",
                        "obligatorio": false,
                        "temporal": "0",
                        "tipo": "Textarea",
                        "idTipoItem": "2",
                        "nombre": "hq-info-txt",
                        "nombreInicial": "",
                        "idiomas": {
                            "es": {
                                "label": "Texto info adicional (horario...)",
                                "texto": "Lunes - Jueves: 00:00-00:00 \nViernes, sabados y domingos: 00:00-00:00"
                            }
                        }
                    },
                    {
                        "id": "620",
                        "longitud": "255",
                        "obligatorio": false,
                        "temporal": "0",
                        "tipo": "Input",
                        "idTipoItem": "1",
                        "nombre": "hq-tel2",
                        "nombreInicial": "",
                        "idiomas": {
                            "es": {
                                "label": "Teléfono #2",
                                "texto": ""
                            }
                        }
                    },
                    {
                        "id": "621",
                        "longitud": "255",
                        "obligatorio": false,
                        "temporal": "0",
                        "tipo": "Input",
                        "idTipoItem": "1",
                        "nombre": "hq-mail2",
                        "nombreInicial": "",
                        "idiomas": {
                            "es": {
                                "label": "Email #2",
                                "texto": ""
                            }
                        }
                    },
                    {
                        "id": "625",
                        "longitud": "255",
                        "obligatorio": false,
                        "temporal": "0",
                        "tipo": "Input",
                        "idTipoItem": "1",
                        "nombre": "hq-dir-lat",
                        "nombreInicial": "",
                        "idiomas": {
                            "es": {
                                "label": "edicion-contacto-hq-dir-lat-tit",
                                "texto": "43.223631704847115"
                            }
                        }
                    },
                    {
                        "id": "626",
                        "longitud": "255",
                        "obligatorio": false,
                        "temporal": "0",
                        "tipo": "Input",
                        "idTipoItem": "1",
                        "nombre": "hq-dir-lon",
                        "nombreInicial": "",
                        "idiomas": {
                            "es": {
                                "label": "edicion-contacto-hq-dir-lon-tit",
                                "texto": "-8.35608020156753"
                            }
                        }
                    }
                ]
            },
            {
                "id": "34",
                "nombre": "contacto-rrss",
                "multiple": true,
                "cantidadElementosBloque": "2",
                "minimo": "1",
                "maximo": null,
                "idiomas": {
                    "es": {
                        "label": "Redes sociales"
                    }
                },
                "items": []
            },
            {
                "id": "40",
                "nombre": "contacto-meta",
                "multiple": false,
                "cantidadElementosBloque": "2",
                "minimo": "1",
                "maximo": null,
                "idiomas": {
                    "es": {
                        "label": "Metadatos (para buscadores y similares)"
                    }
                },
                "items": [
                    {
                        "id": "622",
                        "longitud": "255",
                        "obligatorio": false,
                        "temporal": "0",
                        "tipo": "Input",
                        "idTipoItem": "1",
                        "nombre": "meta-tit",
                        "nombreInicial": "",
                        "idiomas": {
                            "es": {
                                "label": "Título (meta title)",
                                "texto": "Contacto"
                            }
                        }
                    },
                    {
                        "id": "623",
                        "longitud": "255",
                        "obligatorio": false,
                        "temporal": "0",
                        "tipo": "Input",
                        "idTipoItem": "1",
                        "nombre": "meta-des",
                        "nombreInicial": "",
                        "idiomas": {
                            "es": {
                                "label": "Descripción (meta description)",
                                "texto": "Contacto"
                            }
                        }
                    }
                ]
            },
            {
                "id": "41",
                "nombre": "contacto-txt",
                "multiple": false,
                "cantidadElementosBloque": "1",
                "minimo": "1",
                "maximo": null,
                "idiomas": {
                    "es": {
                        "label": "Textos"
                    }
                },
                "items": [
                    {
                        "id": "624",
                        "longitud": "255",
                        "obligatorio": false,
                        "temporal": "0",
                        "tipo": "Input",
                        "idTipoItem": "1",
                        "nombre": "txt-tit",
                        "nombreInicial": "",
                        "idiomas": {
                            "es": {
                                "label": "Título principal",
                                "texto": "¿Cómo podemos ayudarte?"
                            }
                        }
                    }
                ]
            }
        ]
    },
    // {
    //     "id": "7",
    //     "nombre": "inicio",
    //     "filas": null,
    //     "idiomas": {
    //         "es": {
    //             "label": "Inicio"
    //         }
    //     },
    //     "grupos": [
    //         {
    //             "id": "10",
    //             "nombre": "inicio-meta",
    //             "multiple": false,
    //             "cantidadElementosBloque": "2",
    //             "minimo": "1",
    //             "maximo": null,
    //             "idiomas": {
    //                 "es": {
    //                     "label": "Metadatos (para buscadores y similares)"
    //                 }
    //             },
    //             "items": [
    //                 {
    //                     "id": "477",
    //                     "longitud": "255",
    //                     "obligatorio": false,
    //                     "temporal": "0",
    //                     "tipo": "Input",
    //                     "idTipoItem": "1",
    //                     "nombre": "meta-tit",
    //                     "nombreInicial": "",
    //                     "idiomas": {
    //                         "es": {
    //                             "label": "Título (meta title)",
    //                             "texto": "Inicio"
    //                         }
    //                     }
    //                 },
    //                 {
    //                     "id": "478",
    //                     "longitud": "255",
    //                     "obligatorio": false,
    //                     "temporal": "0",
    //                     "tipo": "Input",
    //                     "idTipoItem": "1",
    //                     "nombre": "meta-des",
    //                     "nombreInicial": "",
    //                     "idiomas": {
    //                         "es": {
    //                             "label": "Descripción (meta description)",
    //                             "texto": "Zona fan gran espacio de ocio infantil y familiar "
    //                         }
    //                     }
    //                 }
    //             ]
    //         },
    //         {
    //             "id": "11",
    //             "nombre": "inicio-hero",
    //             "multiple": true,
    //             "cantidadElementosBloque": "6",
    //             "minimo": "1",
    //             "maximo": null,
    //             "idiomas": {
    //                 "es": {
    //                     "label": "Hero"
    //                 }
    //             },
    //             "items": [
    //                 {
    //                     "id": "479",
    //                     "longitud": "255",
    //                     "obligatorio": false,
    //                     "temporal": "0",
    //                     "tipo": "Imagen individual",
    //                     "idTipoItem": "6",
    //                     "nombre": "hero-img-landscape_1",
    //                     "nombreInicial": "hero-img-landscape",
    //                     "idiomas": {
    //                         "es": {
    //                             "label": "Imagen de fondo (formato horizontal) 1",
    //                             "texto": null
    //                         }
    //                     }
    //                 },
    //                 {
    //                     "id": "480",
    //                     "longitud": "255",
    //                     "obligatorio": false,
    //                     "temporal": "0",
    //                     "tipo": "Imagen individual",
    //                     "idTipoItem": "6",
    //                     "nombre": "hero-img-portrait_1",
    //                     "nombreInicial": "hero-img-portrait",
    //                     "idiomas": {
    //                         "es": {
    //                             "label": "Imagen de fondo (formato vertical) 1",
    //                             "texto": null
    //                         }
    //                     }
    //                 },
    //                 {
    //                     "id": "481",
    //                     "longitud": "255",
    //                     "obligatorio": false,
    //                     "temporal": "0",
    //                     "tipo": "Input",
    //                     "idTipoItem": "1",
    //                     "nombre": "hero-tit_1",
    //                     "nombreInicial": "hero-tit",
    //                     "idiomas": {
    //                         "es": {
    //                             "label": "Título 1",
    //                             "texto": "Bienvenido a la mejor fiesta de tu vida"
    //                         }
    //                     }
    //                 },
    //                 {
    //                     "id": "482",
    //                     "longitud": "255",
    //                     "obligatorio": false,
    //                     "temporal": "0",
    //                     "tipo": "Input",
    //                     "idTipoItem": "1",
    //                     "nombre": "hero-txt_1",
    //                     "nombreInicial": "hero-txt",
    //                     "idiomas": {
    //                         "es": {
    //                             "label": "Texto 1",
    //                             "texto": ""
    //                         }
    //                     }
    //                 },
    //                 {
    //                     "id": "483",
    //                     "longitud": "255",
    //                     "obligatorio": false,
    //                     "temporal": "0",
    //                     "tipo": "Input",
    //                     "idTipoItem": "1",
    //                     "nombre": "hero-btn_1",
    //                     "nombreInicial": "hero-btn",
    //                     "idiomas": {
    //                         "es": {
    //                             "label": "Texto botón 1",
    //                             "texto": ""
    //                         }
    //                     }
    //                 },
    //                 {
    //                     "id": "484",
    //                     "longitud": "255",
    //                     "obligatorio": false,
    //                     "temporal": "0",
    //                     "tipo": "Input",
    //                     "idTipoItem": "1",
    //                     "nombre": "hero-lnk_1",
    //                     "nombreInicial": "hero-lnk",
    //                     "idiomas": {
    //                         "es": {
    //                             "label": "Enlace botón 1",
    //                             "texto": ""
    //                         }
    //                     }
    //                 }
    //             ]
    //         },
    //         {
    //             "id": "12",
    //             "nombre": "inicio-zona-fan",
    //             "multiple": false,
    //             "cantidadElementosBloque": "4",
    //             "minimo": "1",
    //             "maximo": null,
    //             "idiomas": {
    //                 "es": {
    //                     "label": "Zona Fan"
    //                 }
    //             },
    //             "items": [
    //                 {
    //                     "id": "485",
    //                     "longitud": "2000",
    //                     "obligatorio": false,
    //                     "temporal": "0",
    //                     "tipo": "Textarea",
    //                     "idTipoItem": "2",
    //                     "nombre": "zona-fan-tit",
    //                     "nombreInicial": "",
    //                     "idiomas": {
    //                         "es": {
    //                             "label": "Título",
    //                             "texto": "3000m² dirigidos a la diversion\nde pequeños y adultos"
    //                         }
    //                     }
    //                 },
    //                 {
    //                     "id": "486",
    //                     "longitud": "255",
    //                     "obligatorio": false,
    //                     "temporal": "0",
    //                     "tipo": "Imagen individual",
    //                     "idTipoItem": "6",
    //                     "nombre": "zona-fan-img",
    //                     "nombreInicial": "",
    //                     "idiomas": {
    //                         "es": {
    //                             "label": "Imagen",
    //                             "texto": null
    //                         }
    //                     }
    //                 },
    //                 {
    //                     "id": "487",
    //                     "longitud": "255",
    //                     "obligatorio": false,
    //                     "temporal": "0",
    //                     "tipo": "Input",
    //                     "idTipoItem": "1",
    //                     "nombre": "zona-fan-sub",
    //                     "nombreInicial": "",
    //                     "idiomas": {
    //                         "es": {
    //                             "label": "Subtítulo",
    //                             "texto": "Zona Fan"
    //                         }
    //                     }
    //                 },
    //                 {
    //                     "id": "488",
    //                     "longitud": "2000",
    //                     "obligatorio": false,
    //                     "temporal": "0",
    //                     "tipo": "Tinymce minimo",
    //                     "idTipoItem": "4",
    //                     "nombre": "zona-fan-txt",
    //                     "nombreInicial": "",
    //                     "idiomas": {
    //                         "es": {
    //                             "label": "Texto",
    //                             "texto": "<p>Un gran espacio de ocio infantil y familiar abre sus puertas en Galicia, una propuesta lúdica única, dotada con todos los servicios para hacer realidad fiestas, celebraciones y toda clase de eventos. Más de 3000m² al aire libre y un espacio cubierto de 1000m² donde disfrutar de numerosas actividades.</p>"
    //                         }
    //                     }
    //                 }
    //             ]
    //         },
    //         {
    //             "id": "13",
    //             "nombre": "inicio-espacios",
    //             "multiple": false,
    //             "cantidadElementosBloque": "2",
    //             "minimo": "1",
    //             "maximo": null,
    //             "idiomas": {
    //                 "es": {
    //                     "label": "Espacios"
    //                 }
    //             },
    //             "items": [
    //                 {
    //                     "id": "489",
    //                     "longitud": "2000",
    //                     "obligatorio": false,
    //                     "temporal": "0",
    //                     "tipo": "Textarea",
    //                     "idTipoItem": "2",
    //                     "nombre": "espacios-tit",
    //                     "nombreInicial": "",
    //                     "idiomas": {
    //                         "es": {
    //                             "label": "Título",
    //                             "texto": "Un vistazo por\nlos diferentes espacios"
    //                         }
    //                     }
    //                 },
    //                 {
    //                     "id": "490",
    //                     "longitud": "255",
    //                     "obligatorio": false,
    //                     "temporal": "0",
    //                     "tipo": "Imagen múltiple",
    //                     "idTipoItem": "8",
    //                     "nombre": "espacios-img",
    //                     "nombreInicial": "",
    //                     "idiomas": {
    //                         "es": {
    //                             "label": "Imagen",
    //                             "texto": null
    //                         }
    //                     }
    //                 }
    //             ]
    //         },
    //         {
    //             "id": "14",
    //             "nombre": "inicio-celebraciones",
    //             "multiple": false,
    //             "cantidadElementosBloque": "10",
    //             "minimo": "1",
    //             "maximo": null,
    //             "idiomas": {
    //                 "es": {
    //                     "label": "Celebraciones"
    //                 }
    //             },
    //             "items": [
    //                 {
    //                     "id": "491",
    //                     "longitud": "255",
    //                     "obligatorio": false,
    //                     "temporal": "0",
    //                     "tipo": "Textarea",
    //                     "idTipoItem": "2",
    //                     "nombre": "celebraciones-tit",
    //                     "nombreInicial": "",
    //                     "idiomas": {
    //                         "es": {
    //                             "label": "Título",
    //                             "texto": "La celebración que quieres,\ntotalmente personalizada"
    //                         }
    //                     }
    //                 },
    //                 {
    //                     "id": "492",
    //                     "longitud": "255",
    //                     "obligatorio": false,
    //                     "temporal": "0",
    //                     "tipo": "Imagen individual",
    //                     "idTipoItem": "6",
    //                     "nombre": "celebraciones-img1",
    //                     "nombreInicial": "",
    //                     "idiomas": {
    //                         "es": {
    //                             "label": "Imagen 1",
    //                             "texto": null
    //                         }
    //                     }
    //                 },
    //                 {
    //                     "id": "493",
    //                     "longitud": "255",
    //                     "obligatorio": false,
    //                     "temporal": "0",
    //                     "tipo": "Input",
    //                     "idTipoItem": "1",
    //                     "nombre": "celebraciones-sub1",
    //                     "nombreInicial": "",
    //                     "idiomas": {
    //                         "es": {
    //                             "label": "Subítulo 1",
    //                             "texto": "Cumpleaños"
    //                         }
    //                     }
    //                 },
    //                 {
    //                     "id": "494",
    //                     "longitud": "2000",
    //                     "obligatorio": false,
    //                     "temporal": "0",
    //                     "tipo": "Tinymce minimo",
    //                     "idTipoItem": "4",
    //                     "nombre": "celebraciones-txt1",
    //                     "nombreInicial": "",
    //                     "idiomas": {
    //                         "es": {
    //                             "label": "Texto 1",
    //                             "texto": "<p>Celebramos tus fiestas de cumpleaños a lo grande, en un entorno único, con todas las actividades y atracciones para hacer de ese día una fecha irrepetible. ¡Sorprenderás a tus amigos y familiares en una celebración única!</p>"
    //                         }
    //                     }
    //                 },
    //                 {
    //                     "id": "495",
    //                     "longitud": "255",
    //                     "obligatorio": false,
    //                     "temporal": "0",
    //                     "tipo": "Input",
    //                     "idTipoItem": "1",
    //                     "nombre": "celebraciones-sub2",
    //                     "nombreInicial": "",
    //                     "idiomas": {
    //                         "es": {
    //                             "label": "Subtítulo 2",
    //                             "texto": "Comuniones"
    //                         }
    //                     }
    //                 },
    //                 {
    //                     "id": "496",
    //                     "longitud": "2000",
    //                     "obligatorio": false,
    //                     "temporal": "0",
    //                     "tipo": "Tinymce minimo",
    //                     "idTipoItem": "4",
    //                     "nombre": "celebraciones-txt2",
    //                     "nombreInicial": "",
    //                     "idiomas": {
    //                         "es": {
    //                             "label": "Texto 2",
    //                             "texto": "<p>Reserva en exclusiva nuestra finca y ¡haz de tu comunión un evento inolvidable!<br>Disfruta de todas las actividades de Zona Fan y personaliza tu evento con photocall, mesas dulces, neones, fotomatón…<br>Consulta nuestros menús y nuestro dossier de comuniones.</p>"
    //                         }
    //                     }
    //                 },
    //                 {
    //                     "id": "497",
    //                     "longitud": "255",
    //                     "obligatorio": false,
    //                     "temporal": "0",
    //                     "tipo": "Imagen individual",
    //                     "idTipoItem": "6",
    //                     "nombre": "celebraciones-img2",
    //                     "nombreInicial": "",
    //                     "idiomas": {
    //                         "es": {
    //                             "label": "Imagen 2",
    //                             "texto": null
    //                         }
    //                     }
    //                 },
    //                 {
    //                     "id": "498",
    //                     "longitud": "255",
    //                     "obligatorio": false,
    //                     "temporal": "0",
    //                     "tipo": "Imagen individual",
    //                     "idTipoItem": "6",
    //                     "nombre": "celebraciones-img3",
    //                     "nombreInicial": "",
    //                     "idiomas": {
    //                         "es": {
    //                             "label": "Imagen 3",
    //                             "texto": null
    //                         }
    //                     }
    //                 },
    //                 {
    //                     "id": "499",
    //                     "longitud": "255",
    //                     "obligatorio": false,
    //                     "temporal": "0",
    //                     "tipo": "Input",
    //                     "idTipoItem": "1",
    //                     "nombre": "celebraciones-sub3",
    //                     "nombreInicial": "",
    //                     "idiomas": {
    //                         "es": {
    //                             "label": "Subtítulo 3",
    //                             "texto": "Otros eventos"
    //                         }
    //                     }
    //                 },
    //                 {
    //                     "id": "500",
    //                     "longitud": "2000",
    //                     "obligatorio": false,
    //                     "temporal": "0",
    //                     "tipo": "Tinymce minimo",
    //                     "idTipoItem": "4",
    //                     "nombre": "celebraciones-txt3",
    //                     "nombreInicial": "",
    //                     "idiomas": {
    //                         "es": {
    //                             "label": "Texto 3",
    //                             "texto": "<p>Team buildings, fiestas de empresa, reuniones familiares, excursiones colegios…<br>Zona Fan es un recinto de ocio multidisciplinar dotado de un amplio abanico de servicios para la celebración de todo tipo de eventos.<br>Pregúntanos sin compromiso y diseñaremos de forma individualizada tu actividad.</p>"
    //                         }
    //                     }
    //                 }
    //             ]
    //         }
    //     ]
    // }
];