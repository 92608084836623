<!-- LAYOUT CAMPOS PORCENTAJE -->
<div *ngIf="!layout || layout === _LayoutFormularioEnum.layoutCamposPorcentaje"
	class="form-container form-container-campos-porcentaje">
  	<form #formElement 
	  	novalidate
		[formGroup]="formValidators">
    	<div [class]="formularioAncho">
			<div 
				[ngClass]="{'form-flex':aplicarFlex}" 
				class="listado-campos">
				<ng-container *ngFor="let campo of campos; let i = index">
					<ng-container *ngIf="!campo.oculto">
						<ng-container *ngTemplateOutlet="templateCampos;context:{campo:campo,i:i, clasedefecto: clasesCampoDefectoLayoutCamposPorcentaje[campo.tag]?.join(' '), clasepersonalizada: campo?.clasePersonalizadaLayoutCamposPorcentaje?.join(' ')}">
						</ng-container>
					</ng-container>
				</ng-container>
			</div>
		</div>
	</form>
</div>

<!-- LAYOUT CAMPOS ANCHO FIJO -->
<div *ngIf="layout === _LayoutFormularioEnum.layoutCamposAnchoFijo" 
	class="form-container form-container-campos-fijo">
	<form #formElement 
		novalidate
		[formGroup]="formValidators">
		<div [class]="formularioAncho">
			<div 
				[ngClass]="{'form-flex':aplicarFlex}" 
				class="listado-campos">
				<ng-container *ngFor="let campo of campos; let i = index">
					<ng-container *ngIf="!campo.oculto">
						<ng-container *ngTemplateOutlet="templateCampos;context:{campo:campo,i:i, clasedefecto: clasesCampoDefectoLayoutCamposAnchoFijo[campo.tag]?.join(' '), clasepersonalizada: campo?.clasePersonalizadaLayoutCamposAnchoFijo?.join(' ')}">
						</ng-container>
					</ng-container>
				</ng-container>
			</div>
		</div>
	</form>
</div>



<!-- codigo común para layouts. Campos-->
<ng-template #templateCampos 
	let-campo="campo" 
	let-i="i" 
	let-clasepersonalizada='clasepersonalizada'
  	let-clasedefecto='clasedefecto'>

	<ng-container [ngSwitch]="campo.tag">
		
		<div *ngSwitchCase="_FormTagEnum.boolean" 
			[ngClass]="clasepersonalizada ? clasepersonalizada : clasedefecto" 
			class="campo">
			<neo-campo-boolean-palanca 
				class="form-campo-palanca" 
				[label]="campo.label" 
				[texto]="campo.textoAyuda"
				[color]="campo?.color" 
				[controlCampo]="formValidators.get(campo.formControlName)">
			</neo-campo-boolean-palanca>
		</div>

		<div *ngSwitchCase="_FormTagEnum.colorPicker" 
			[ngClass]="clasepersonalizada ? clasepersonalizada : clasedefecto"
			class="campo">
			<neo-campo-color-picker
				class="form-campo-color-picker"
				[label]="campo.label"
				[controlCampo]="formValidators.get(campo.formControlName)">
			</neo-campo-color-picker>
		</div>

		<div *ngSwitchCase="_FormTagEnum.video" 
		[ngClass]="clasepersonalizada ? clasepersonalizada : clasedefecto"
		class="campo">
			<neo-campo-video
				[labelPrincipal]="campo.label"
				[plataformas]="campo.datos"
				[validadores]="formValidators"
				[campo]="campo">
			</neo-campo-video>
		</div>

		<div *ngSwitchCase="_FormTagEnum.checkbox" 
			[ngClass]="clasepersonalizada ? clasepersonalizada : clasedefecto"
			class="campo">
			<neo-campo-checkbox 
				class="form-campo-checkbox" 
				[clase]="'mat-checkbox-lg'"
				[controlCampo]=formValidators.get(campo.formControlName)
				[color]="campo.color"
				[label]="campo.label"
				[labelLink]="campo.labelLink"
				(labelLinkClick)="campoCheckboxLinkClick.emit(campo)">
			</neo-campo-checkbox>
		</div>

		<div *ngSwitchCase="_FormTagEnum.radio" 
			[ngClass]="clasepersonalizada ? clasepersonalizada : clasedefecto"
			class="campo">
			<neo-campo-radio 
				class="form-campo-radio" 
				[controlCampo]=formValidators.get(campo.formControlName)
				[label]="campo.label" 
				[datos]="campo.datos" 
				[color]="campo.color">
			</neo-campo-radio>
		</div>

		<div *ngSwitchCase="_FormTagEnum.contrasena" 
			[ngClass]="clasepersonalizada ? clasepersonalizada : clasedefecto"
			class="campo">
			<neo-input-contrasena 
				[controlCampo]="formValidators.controls[campo.formControlName]"
				[minCaracteres]="campo.minLength"
				[maxCaracteres]="campo.maxLength"
				[barraFortaleza]="campo?.activarExtensiones?.includes('barraFortaleza')"
				[criterioValidez]="campo?.activarExtensiones?.includes('criteriosValidez')" 
				[label]="campo.label">
			</neo-input-contrasena>
		</div>

		<div *ngSwitchCase="_FormTagEnum.input" 
			[ngClass]="clasepersonalizada ? clasepersonalizada : clasedefecto"
			class="campo">
			<div class="form-field">
				<neo-input 
					[label]="campo.label" 
					[controlCampo]="formValidators.controls[campo.formControlName]" 
					[tipo]="campo.type"
					[caracteresMaximo]="campo.maxLength" 
					[caracteresMinimo]="campo.minLength" 
					[textoAyuda]="campo.textoAyuda"
					[placeholder]="campo.placeholder">
				</neo-input>
			</div>
		</div>

		<div *ngSwitchCase="_FormTagEnum.inputFilter" 
			[ngClass]="clasepersonalizada ? clasepersonalizada : clasedefecto"
			class="campo">
			<div class="form-field">
				<neo-input-filter 
					[label]="campo.label" 
					[controlCampo]="formValidators.controls[campo.formControlName]"
					[textoAyuda]="campo.textoAyuda" 
					[placeholder]="campo.placeholder" 
					[opciones]="campo?.datos"
					(nuevasOpciones)="nuevoItemInputFilter($event)" 
					(error)="saltarValidador($event, campo.formControlName)"
					(reiniciarValidador)="reiniciarValidador($event, campo.formControlName)">
				</neo-input-filter>
			</div>
		</div>

		<div *ngSwitchCase="_FormTagEnum.clipboard" 
			[ngClass]="clasepersonalizada ? clasepersonalizada : clasedefecto"
			class="campo">
			<div class="form-field">
				<neo-input-clipboard 
					[label]="campo.label" 
					[controlCampo]="formValidators.controls[campo.formControlName]"
					[textoAyuda]="campo.textoAyuda" 
					[placeholder]="campo.placeholder">
				</neo-input-clipboard>
			</div>
		</div>

		<div *ngSwitchCase="_FormTagEnum.select" 
			[ngClass]="clasepersonalizada ? clasepersonalizada : clasedefecto"
			class="campo">
			<div class="form-field">
				<neo-campo-select 
					[label]="campo.label" 
					[controlCampo]="formValidators.controls[campo.formControlName]"
					[opcionGeneral]="campo.opcionGeneral" 
					[opcionGeneralNombre]="campo.opcionGeneralNombre"
					[textoAyuda]="campo.textoAyuda" 
					[multiple]="campo.multiple" 
					[datos]="campo.datos">
				</neo-campo-select>
			</div>
		</div>

		<div *ngSwitchCase="_FormTagEnum.selectBuscador" 
			[ngClass]="clasepersonalizada ? clasepersonalizada : clasedefecto"
			class="campo">
			<div class="form-field">
				<neo-select-ngx-single-selection 
					[label]="campo.label" 
					[controlCampo]="formValidators.controls[campo.formControlName]"
					[textoAyuda]="campo.textoAyuda" 
					[multiple]="campo.multiple" 
					[datos]="campo.datos">
				</neo-select-ngx-single-selection>
			</div>
		</div>
		
		<div *ngSwitchCase="_FormTagEnum.estado" 
			[ngClass]="clasepersonalizada ? clasepersonalizada : clasedefecto"
			class="campo">
			<div class="form-field">
				<neo-campo-select 
					[label]="campo.label" 
					[controlCampo]="formValidators.controls[campo.formControlName]"
					[opcionGeneral]="campo.opcionGeneral" 
					[opcionGeneralNombre]="campo.opcionGeneralNombre"
					[textoAyuda]="campo.textoAyuda" 
					[multiple]="campo.multiple" 
					[datos]="campo.datos">
				</neo-campo-select>
			</div>
		</div>
		
		<div *ngSwitchCase="_FormTagEnum.chipsAutocomplete" 
			[ngClass]="clasepersonalizada ? clasepersonalizada : clasedefecto"
			class="campo">
			<neo-chips-autocomplete 
				[responsivo]="campo?.responsivo" 
				[itemControl]="formValidators.controls[campo.formControlName]" 
				[fuenteItems]="campo.datos"
				[chipItemsSeleccionados]="campo.datosPreestablecidos" 
				[chipItemsSeleccionadosBorrable]=true
				[label]="campo.label" 
				[textoAyuda]="campo.textoAyuda" 
				[placeholder]="campo.placeholder">
			</neo-chips-autocomplete>
		</div>
		
		<div *ngSwitchCase="_FormTagEnum.date" 
			[ngClass]="clasepersonalizada ? clasepersonalizada : clasedefecto"
			class="campo">
			<neo-campo-datatimepicker  
				[label]="campo.label" 
				[tipo]="campo.tipoDatePicker"
				[controlCampo]="formValidators.controls[campo.formControlName]" 
				[textoAyuda]="campo.textoAyuda"
				[placeholder]="campo.placeholder" 
				[min]="campo.min" 
				[max]="campo.max" 
				[horaMax]="campo.horaMax"
				[horaMin]="campo.horaMin" 
				[minutoMax]="campo.minutoMax" 
				[minutoMin]="campo.minutoMin"
				[configuracionDia]="campo.datos">
			</neo-campo-datatimepicker>
		</div>
		
		<div *ngSwitchCase="_FormTagEnum.importe" 
			[ngClass]="clasepersonalizada ? clasepersonalizada : clasedefecto"
			class="campo">
			<neo-input-importe 
				[textoAyuda]="campo?.textoAyuda" 
				[controlCampo]="formValidators.get(campo.formControlName)"
				[tipo]="campo.type"
				[label]="campo.label">
			</neo-input-importe>
		</div>
		
		<div *ngSwitchCase="_FormTagEnum.cantidad" 
			[ngClass]="clasepersonalizada ? clasepersonalizada : clasedefecto"
			class="campo">
			<neo-input-cantidad 
				[textoAyuda]="campo?.textoAyuda" 
				[controlCampo]="formValidators.get(campo.formControlName)"
				[tipo]="campo.type"
				[label]="campo.label" 
				[min]="campo.min" 
				[max]="campo.max">
			</neo-input-cantidad>
		</div>
		
		<div *ngSwitchCase="_FormTagEnum.telefono" 
			[ngClass]="clasepersonalizada ? clasepersonalizada : clasedefecto"
			class="campo">
			<neo-campo-telefono 
				[controlCampo]="formValidators.get(campo.formControlName)" 
				[label]="campo.label"
				[controlName]="campo.formControlName"
				[textoAyuda]="campo.textoAyuda">
			</neo-campo-telefono>
		</div>
		
		<div *ngSwitchCase="_FormTagEnum.textarea" 
			[ngClass]="clasepersonalizada ? clasepersonalizada : clasedefecto"
			class="campo">
			<div class="form-field">
				<neo-textarea-contador 
					[label]="campo.label" 
					[controlCampo]="formValidators.controls[campo.formControlName]"
					[caracteresMaximo]="campo.maxLength" 
					[caracteresMinimo]="campo.minLength" 
					[textoAyuda]="campo.textoAyuda"
					[placeholder]="campo.placeholder">
				</neo-textarea-contador>
			</div>
		</div>

		<div *ngSwitchCase="_FormTagEnum.captcha" 
			[ngClass]="clasepersonalizada ? clasepersonalizada : clasedefecto"
			class="campo">
			<neo-captcha [controlCampo]="formValidators.controls[campo.formControlName]"></neo-captcha>
		</div>

		<div *ngSwitchCase="_FormTagEnum.fichero" 
			[ngClass]="clasepersonalizada ? clasepersonalizada : clasedefecto"
			class="subida-fichero campo">
			<neo-campo-fichero 
				[controlCampo]="formValidators.controls[campo.formControlName]"
				[nombreCampo]="campo.formControlName" 
				[label]="campo.label" 
				[placeholder]="campo.placeholder" 
				[formatosPermitidos]="campo.formatosPermitidos">
			</neo-campo-fichero>
		</div>

		<div *ngSwitchCase="_FormTagEnum.ficheroZonaArrastre" 
			[ngClass]="clasepersonalizada ? clasepersonalizada : clasedefecto"
			class="campo subida-fichero">
			{{campo.label}}
			<neo-fichero-subida #appFicheroSubida 
				[identificador]="campo.formControlName" 
				[botonHacerFotoResponsivo]=false
				[iconoBotonSubirFicheroResponsivo]="'icon-60 icon-ibm--document--add'"
				[textoBotonSubirFicheroResponsivo]="'Busca tu archivo'"
				[presubidaFichero]="campo?.presubidaFichero"
				[desactivado]="formValidators.controls[campo.formControlName].disabled"
				[formatosPermitidos]="campo.formatosPermitidos" 
				[subidaMultiple]="campo.subidaMultiple"
				[maximoElementosASubir]="campo.maximoElementosASubir" 
				[tamanoMaximoTotal]="campo.tamanoMaximoTotal"
				[tamanoMaximoFichero]="campo.tamanoMaximoFichero" 
				(preSubirArchivos)="preSubirArchivos($event)"
				(submitFicheroSubida)="submitFicheroSubida($event)" 
				(borrarFichero)="borrarFichero($event)">
			</neo-fichero-subida>
		</div>

		<div *ngSwitchCase="_FormTagEnum.editor" 
			[ngClass]="clasepersonalizada ? clasepersonalizada : clasedefecto"
			class="campo">
			<neo-editor 
				[label]="campo.label" 
				[controlCampo]="formValidators.controls[campo.formControlName]">
			</neo-editor>
		</div>

		<div *ngSwitchCase="_FormTagEnum.contenidoHtml" 
			[ngClass]="clasepersonalizada ? clasepersonalizada : clasedefecto"
			class="campo contenidoHtml">
			<div class="card border border-primary">
				<div class="card-body collapse show">
					<span class="card-title">{{ campo.label }}</span>
					<p class="card-text">
						<neo-span-inner-html [contenido]="formValidators.controls[campo.formControlName].value + 
														((campo?.unidades) ? campo?.unidades : '')">
						</neo-span-inner-html>
					</p>
				</div>
			</div>
		</div>

		<div class="campo" *ngSwitchDefault>
			<div class="card border border-primary">
				<div class="card-body collapse show">
					<h4 class="card-title">Tipo de campo {{ campo.tag }} aún sin implementar</h4>
					<p class="card-text">Campo {{ campo.label }} [{{ campo.formControlName }}]</p>
				</div>
			</div>
		</div>
	<!-- Fin switch -->
	</ng-container>
<!--Fin template campos -->
</ng-template>
