import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { COLUMNAS_VER_MAS } from './dialogo-ver-mas-data';
import { IConfiguracionLpeListado } from '../layouts/layout-lpe-listado/layout-lpe-listado.component';

@Component({
  selector: 'neo-dialogo-ver-mas',
  templateUrl: './dialogo-ver-mas.component.html',
  styleUrls: ['./dialogo-ver-mas.component.scss'],
})
export class DialogoVerMasComponent implements OnInit {

  tituloDialogo: string = 'Información extra';
  
  @Input() customProyeccion: TemplateRef<any>;
  @Input() data: any;
  @Input() configuracionTabla: IConfiguracionLpeListado;
  @Input() botonCerrarDialogoCabecera: boolean = true;
  
  @Output() eventoClick: EventEmitter<any> = new EventEmitter();
  
  @ViewChild('dialogoVerMas') dialogoVerMas: TemplateRef<any>;
  
  constructor(public dialog: MatDialog) {}
  
  ngOnInit(): void {
    // Configuración tabla por defecto
    this.configuracionTabla = (this.configuracionTabla) ? this.configuracionTabla : {
      item: 'verMas',
      columnas: COLUMNAS_VER_MAS,
      paginas_routes_admin: '',
      identificadorFiltro: '',
      paginacion: false,
    };
  }
  
  onClick() {   
    this.dialog.open(this.dialogoVerMas, {disableClose: false});
    this.eventoClick.emit('Abrir modal extras');
  }

  cerrar() {
    this.dialog.closeAll();
  }

}