<div class="dialogo-verMas">
    <a class="link" (click)="onClick()">Ver más..</a>
</div>

<ng-template #dialogoVerMas>
    <mat-dialog-content>
        <div class="header-dialog flex" *ngIf="tituloDialogo || botonCerrarDialogoCabecera">
            <div 
            class="flex header-dialog-left" *ngIf="tituloDialogo">
                <h1 mat-dialog-title>{{tituloDialogo}}</h1>
            </div>
            <mat-dialog-actions align="end" *ngIf="botonCerrarDialogoCabecera">
                <neo-button mat-dialog-close 
                class="btn-close"
                [miniFab]="true"
                [iconClass]="'icon-ibm--close icon-24'"
                (accion)="cerrar()"
                ></neo-button>
            </mat-dialog-actions>
        </div>
        <ng-template [ngTemplateOutlet]="customProyeccion || defaultTemplate"></ng-template>
    </mat-dialog-content>
</ng-template>

<ng-template #defaultTemplate>
    <neo-tabla-basica-offline [data]="data" [configuracionTabla]="configuracionTabla"></neo-tabla-basica-offline>
</ng-template>
