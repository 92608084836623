import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {environment} from '@environments/environment';

@Component({
  selector: 'neo-leyenda-mapa',
  templateUrl: './leyenda-mapa.component.html',
  styleUrls: ['./leyenda-mapa.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LeyendaMapaComponent implements OnInit {
  
  base_url_iconos = environment.base_url_iconos;
  
  constructor() { }

  ngOnInit(): void {
  }

}
