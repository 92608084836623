import {
	AfterViewChecked,
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ComponentFactoryResolver,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	QueryList,
	TemplateRef,
	ViewChild,
	ViewChildren,
	ViewContainerRef
  } from '@angular/core';
  import {MatTable, MatTableDataSource} from '@angular/material/table';
  import {MatSort, Sort} from '@angular/material/sort';
  import {MatDialog, MatDialogRef} from '@angular/material/dialog';
  import {Router} from '@angular/router';
  import {BusquedaComponent} from '@appNeo/neoShared/components/busqueda/busqueda.component';
  import {
	AccionesTablaEnum,
	AnchoColumnaTablaEnum,
	IAccionRowTabla, IActualizacionCeldaEditable, IBotonAccionCabeceraTabla,
	IColumnaTabla,
	TablaService,
	TipoColumnaTablaEnum
  } from '@appNeo/neoShared/services/tabla/tabla.service';
  import {environment} from '@environments/environment';
  import {MatPaginator, MatPaginatorIntl} from '@angular/material/paginator';
  import {Paginador} from '@appNeo/neoShared/models/Paginador/Paginador';
  import {FiltrosTablaComponent} from '@appNeo/neoShared/components/tabla/filtros-tabla/filtros-tabla.component';
  import {SelectionModel} from '@angular/cdk/collections';
  import {IFormInput} from '@appNeo/neoShared/helpers/interfaces/IForm-input';
  import {FormularioService} from '@appNeo/neoShared/services/formulario/formulario.service';
  import {FiltrosService} from '@appNeo/neoCore/services/filtros/filtros.service';
  import {AuxiliarService} from '@appNeo/neoShared/services/auxiliar/auxiliar.service';
  import {CdkTextareaAutosize} from '@node_modules/@angular/cdk/text-field';
  import {Subscription} from 'rxjs';
  import {MediaChange, MediaObserver} from '@angular/flex-layout';
  import {BotonDesplegableService, IAccionesBotonDesplegable} from '@appNeo/neoShared/services/boton-desplegable/boton-desplegable.service';
  import {IPerfilPropiedad} from '@appNeo/neoShared/helpers/interfaces/IPerfilPropiedad';
  import {IconoDespliegueComponent} from '@appNeo/neoShared/components/icono-despliegue/icono-despliegue.component';
  import {PerfilPropiedadTagEnum} from '@appNeo/neoShared/helpers/enums/perfil-propiedad-tag.enum';
  import {animate, state, style, transition, trigger} from '@angular/animations';
  import {MatPaginatorIntlEs} from '@appNeo/neoShared/services/tabla/tabla-custom.service';
  import {TotalesComponent} from '@appNeo/neoShared/components/totales/totales.component';
  import  clonedeep from '@node_modules/lodash.clonedeep';
  import { TagCampoEnum } from '@appNeo/neoShared/helpers/enums/TagCampo.enum';
  import {MatInput} from '@node_modules/@angular/material/input';
  import { ModoBotonEnum } from '../../button/button.component';
  
  export enum ModoTablaEnum {
	MODO_HORIZONTAL = 'horizontal',  // contenedor filtros ocupando el ancho y a continuacion el contendor de la tabla ocupando el ancho
	MODO_VERTICAL = 'vertica' // filtros en una primera columna con los campos ocupando el 100% y tabla en segunda columna
  }
  
  
  
  @Component({
	selector: 'neo-tabla',
	templateUrl: './tabla.component.html',
	styleUrls: ['./tabla.component.scss'],
	providers: [FormularioService, FiltrosService, BotonDesplegableService, { provide: MatPaginatorIntl, useClass: MatPaginatorIntlEs}],
	animations: [
	  trigger('detailExpand', [
		state('collapsed', style({height: '0px', minHeight: '0px'})),
		state('expanded', style({height: '*'})),
		transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
	  ]),
	],
  })
  
  export class TablaComponent implements OnInit, AfterViewInit, AfterViewChecked {
  
	// Template columna componente VerMas
	@Input() customTemplateVerMas: TemplateRef<any>;
  
	public modoTablaEnum = ModoTablaEnum;

	// Tabla Online => Paginación, filtrado y ordenación delegado en api
	@Input() tablaOnline = true;
	@Input() modoVista: ModoTablaEnum = ModoTablaEnum.MODO_HORIZONTAL;
	@Input() identificador = 'tabla-gen-1';
  
	//FILTRADO
	//Visualizar filtrado
	@Input() textoBotonAplicarFiltros: string = 'Aplicar filtros';
	@Input() filtrado: boolean = false;
	@Input() filtrado_por_termino_busqueda: boolean = true; // ojo no controlado que filtrado activo y sin mas filtros que el termino busqueda, opcion desactivada solo si filtros activo y mas numero de filtros
	@Input() guardarValorBuscadorEnSesion = false;
	//Filtrado en caliente
	@Input() filtradoSinBotonSubmit = false;
	@Input() keyFiltrado: string;
	@Input() valoresInicialesFiltrado: object;
	@Input() numeroFiltros = 0;
	// TODO verle el sentido
	//Inputs formulario filtrado
	@Input() inputsFormularioFiltrado: IFormInput[];
  
	// cabecera tabla
	@Input() mostrarTituloCabecera = true;
	@Input() titulo = '';
	@Input() tituloFiltros = '';
	@Input() mostrarAccionesCabecera = true;
	@Input() mostrarTotalElementos = true;
	@Input() btnAccionesLote = false;
  
  
	// CUSTOMIZADO OCULTAR LA TABLA SI MOSTRAR TABLA Y CON VISTA PERSONALIZADA
	@Input() ocultarTabla = false;
	@Input() customTablaOculta: TemplateRef<any>;
	@Input() customBotoneraTablaVertical: TemplateRef<any>;
  
	modoBotonEnum = ModoBotonEnum;
	@Input() tipoBotonDescarga: ModoBotonEnum = ModoBotonEnum.basic; // basic o stroked
	@Input() textoDescargarPdfCabeceraIcono = '';
	@Input() btnDescargarPdfCabecera = false;
	@Input() btnDescargarPdfCabeceraIcono = 'icon-20 icon-ibm--document--pdf';
	@Input() accionesMultiplesDesplegable: IAccionesBotonDesplegable [] = [];
	@Input() aplicarClasesPersonalizadaDefecto: boolean = false;
	@Input() botoneraAccionesCabecera: IBotonAccionCabeceraTabla [] = [
	  {
		id: AccionesTablaEnum.Crear,
		color: 'primary',
		bloqueClass: 'btn-add',
		mostrarBtnLg: true,
		neoButtonClassLg: 'btn-crear-lg',
		iconClassLg: 'icon-16 icon-ibm--add',
		neoButtonInputClassLg: 'btn-sm',
		mostrarBtnXs: true,
		textoLg: 'Nueva Entrada',
		neoButtonClassXs: 'btn-crear-xs',
		iconClassXs: 'icon-16 icon-ibm--add',
		neoButtonInputClassXs: 'btn-sm',
		textoXs: '',
	  }
	];
  
	// paginación
	@Input() paginacion: boolean;
  
	//Componente totales
	@Input() visualizarTotales = false;
  
  
	//SOLO APLICA A TABLA OFFLINE
	@Input() columnasFiltro: string[];
	@Input() ordenacionColumnas = false;
  
	// RESPONSIVO
	// por defecto, columna icono despligue contenido en subfila
	@Input() extraerMostrarColumnasOcultas = false;
	// accion en botonera fila
	@Input() mostrarColumnasOcultasEnDialogo = false;
	@Input() responsiveDinamico = false;
	@Input() ocultarBotoneraAccionesRowResponsive = false;
  
	//ACCIONES TABLA
	//Cabecera
	@Output() accionDescargarPdf = new EventEmitter<boolean>();
	@Output() accionClickRow = new EventEmitter<object>();
	@Output() clickAccionRowTabla = new EventEmitter<[object, IAccionRowTabla]>();
	@Output() accionCabeceraClick = new EventEmitter<[IBotonAccionCabeceraTabla]>();
	@Output() accionMultiple = new EventEmitter<any>();
	@Output() accionVerInformacionRow = new EventEmitter<object>();
	@Output() filtrarBusqueda = new EventEmitter<string>();
	@Output() ordenarColumnas = new EventEmitter<string>();
  
	//SOLO APLICA A TABLA ONLINE
	@Output() aplicarFiltros = new EventEmitter<object>();
	@Output() cambioPaginador = new EventEmitter<object>();
	@Output() actualizacionCampoEditable = new EventEmitter<IActualizacionCeldaEditable>();
  
	valoresFiltrado: object;
	cambioValoresFiltrado = false;
	cambioValorBuscador = false;
	selection = new SelectionModel<object>(true, []);
	columnas: IColumnaTabla[];
	dataSource: MatTableDataSource<any>;
	totalResultados = 0;
	paginador = new Paginador();
	rowSeleccionadaBotonDesplegable: object;
  
	//Componente Totales
	dataTotales = [];
	opcionSelectTotales = '1';
  
	//VALORES POR DEFECTO CONTENIDO TABLA
	imgDefault = 'https://dummyimage.com/50/50';
	iconOcultarDefault = 'visibility_off';
	iconEditarDefault = 'edit';
	iconBorradoDefault = 'delete';
  
	@ViewChild(MatPaginator) matPaginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;
	@ViewChild('window') window: TemplateRef<any>;
	@ViewChild('tabla') tabla: MatTable<any>;
	@ViewChildren('textAreaColumnaEditable') textAreaColumnaEditable: QueryList<CdkTextareaAutosize>;
	@ViewChildren('inputTextAreaEditable') inputTextAreaEditable: QueryList<MatInput>;
  
	// basico termino busqueda
	@ViewChild('filtrosBusqueda') filtrosBusqueda: BusquedaComponent;
	@ViewChildren(FiltrosTablaComponent) filtrosTabla: QueryList<FiltrosTablaComponent>;
  
	@ViewChild('totales') totales: TotalesComponent;
  
	// Campos editables
	campoEditableFoco = null;
	posicionCampoEditableFoco = null;
  
	textAreaOpen = [];
  
	// responsivo
	currentScreenWidth: string = ''; // xs, md...
	flexMediaWatcher: Subscription; // susbscripcion resize dispositivo
	columnasTotales: IColumnaTabla[] = []; // maximo columnas a mostrar, backup columnas
	columnasOcultas: IColumnaTabla[]; // cajon de columnas oculstas  mostrar en Ver mas info
	accionesBotonDesplegable: IAccionesBotonDesplegable [];
	subMenuDesplegableAccionesFila: Subscription;
	subMenuDespegableAccionesMultiples: Subscription;
	@ViewChild('dialogoMasInfo') dialogoMasInfo: TemplateRef<any>;
	dialogoMasInfoRef: MatDialogRef<any, any>;
	propiedadesMasInfo;
	indiceFilaExpandida: number;
	filaExpandida: any | null;
	@ViewChildren('tableRow', { read: ViewContainerRef }) rowContainers;
	@ViewChildren(IconoDespliegueComponent) listadoIconoDespliegueComponent: QueryList<IconoDespliegueComponent>;
	@ViewChild('dialogFiltrosResponsivos') dialogFiltrosResponsivos: TemplateRef<any>;
	botoneraDialogFiltrosResponsivos = [{
		label: 'Limpiar filtros',
		id: 'btn-limpiar',
		tipoAccion: 'accion',
		type: 'button',
		color: 'primary',
		disabled: this.deshabilitarLimpiarFiltros,
		activo: true,
		basic: true,
		iconoClase:'icon-20 icon-ibm--clean',
		matprefix: true
	  },
	  {
		label: this.textoBotonAplicarFiltros,
		id: 'btn-filtrar',
		tipoAccion: 'accion',
		type: 'button',
		color: 'primary',
		disabled: false,
		activo: true,
		flat: true
	  }];
	// columna automatica para el despliegue en caso de columnas ocultas
	columnaDespligueMasInfoKey = TipoColumnaTablaEnum.DespliegueMasInfo;
	columnaDespligueMasInfo = {key: this.columnaDespligueMasInfoKey, activa:false, nombre: '', tipo: TipoColumnaTablaEnum.DespliegueMasInfo, ordenacion: false, ancho: AnchoColumnaTablaEnum.xs};
  
	constructor(
	  public dialog: MatDialog,
	  public tablaService: TablaService,
	  private router: Router,
	  private formularioService: FormularioService,
	  private filtrosService: FiltrosService,
	  public traduccionPaginador: MatPaginatorIntl,
	  private auxiliarService: AuxiliarService,
	  private cdr: ChangeDetectorRef,
	  private mediaObserver: MediaObserver,
	  private botonDesplegableService: BotonDesplegableService,
	  private resolver: ComponentFactoryResolver,
	  private element: ElementRef
	) {
	  this.traduccionPaginador.itemsPerPageLabel = environment.paginador_label_elementos;
	  this.traduccionPaginador.previousPageLabel = environment.paginador_label_anterior;
	  this.traduccionPaginador.nextPageLabel = environment.paginador_label_siguiente;
	  this.traduccionPaginador.lastPageLabel = environment.paginador_label_ultima;
	  this.traduccionPaginador.firstPageLabel = environment.paginador_label_primera;
	}
  
	ngAfterViewChecked(): void {
		this.cdr.detectChanges();
	}
  
	ngOnInit() {
	  if (this.tipoBotonDescarga && (this.tipoBotonDescarga != ModoBotonEnum.basic && this.tipoBotonDescarga != ModoBotonEnum.stroked)) {
		this.tipoBotonDescarga = ModoBotonEnum.basic;
	  }
	  if (this.filtrado) this.setInputsValidadoresFiltrado();
	  this.numeroFiltros = (this.filtrado) ? this.numeroFiltros + 1 : 0;
	  this.valoresFiltrado = Object.assign({}, this.getValoresFiltrado());
	  if (JSON.stringify(this.valoresFiltrado) !== JSON.stringify(this.valoresInicialesFiltrado)) this.cambioValoresFiltrado = true;
	  // console.log('DEBUG VALORES FILTRADO: ', this.valoresFiltrado);
	  // console.log('DEBUG VALORES INICIALES FILTRADO: ', this.valoresInicialesFiltrado);
	  // console.log('DEBUG CAMBIO FILTRADO: ', JSON.stringify(this.valoresFiltrado) !== JSON.stringify(this.valoresInicialesFiltrado));
	  this.subscripcioncolumnasTabla();
	  this.subscripcionDataSourceTabla();
	  this.determinarTotalElementos();
	  this.subscripcionSeleccionarTodo();
	  this.subscripcionSeleccionarItem();
	  this.subscripcionMenuDesplegableAccionesFila();
	  this.botonDesplegableService.acciones = this.accionesMultiplesDesplegable;
	  this.subscripcionMenuDesplegableAccionesMultiples();
	  this.columnas = this.columnasTotales;
	  if (!this.responsiveDinamico) {
		// Responsivo por configuracion prioridades segun classResponsive en conf tabla
		// this.flexMediaWatcher = this.mediaObserver.media$.subscribe((change: MediaChange) => {
		//   if (change.mqAlias !== this.currentScreenWidth) {
		//     this.currentScreenWidth = change.mqAlias;
		//     this.columnasOcultas = [];
  
		//     this.columnas = this.columnasTotales?.filter( columna =>
		//     {
		//       let mostrar = this.esColumnaVisible(columna.classResponsive) || (['select', 'iconDrag', 'acciones'].indexOf(columna.key)>=0 );
		//       if (!mostrar) {
		//         this.columnasOcultas.push(columna);
		//       }
		//       return mostrar;
		//     });
		//     // columna auto con icono despligue que se mete en total columnas ya filtradas si es que existe columnas ocultas
		//     if (!this.mostrarColumnasOcultasEnDialogo) {
		//       this.columnas = this.establecerVisibilidadColumnaDespligue(this.columnasOcultas.length > 0, this.columnas);
		//     }
		//   }
		// });
	  }
	}
  
  
  
	ngAfterViewInit(): void {
	  if (!this.tablaOnline && this.totalResultados > 0) {
		this.inicializarPaginador();
	  }
	  this.columnas = this.columnasTotales;
	  if (this.determinarSeleccionMultiple() && this.accionesMultiplesDesplegable) this.btnAccionesLote = true;
	  if(this.responsiveDinamico) {
		this.flexMediaWatcher = this.mediaObserver.media$.subscribe((change: MediaChange) => {
		  if (change.mqAlias !== this.currentScreenWidth) {
			// // console.log(' *** Dispositivo ', this.currentScreenWidth);
			this.currentScreenWidth = change.mqAlias;
			// console.warn('CAMBIO MEDIA ... ', this.keysColumnasTotales);
			setTimeout( () => {
			  this.aplicarCompotamientoResponsivoDinamico();
			}, 500)
		  }
	   });
	  }
	}
  
	determinarSeleccionMultiple() {
	  return this.columnasTotales?.filter(columna => columna.tipo === TipoColumnaTablaEnum.Selector).length > 0;
	}
  
  
	determinarTotalElementos() {
	  if (this.tablaOnline) {
		this.subscripcionTotalDataSourceTabla();
	  } else if (this.dataSource?.data) {
		this.totalResultados = this.dataSource.filteredData.length;
	  }
	}
  
	subscripcionSeleccionarTodo(): void {
	  this.tablaService.seleccionarTodo$.subscribe(seleccionarTodo => {
		seleccionarTodo ? this.seleccionarTodo() : this.deseleccionarTodo();
	  });
	}
  
	subscripcionSeleccionarItem(): void {
	  this.tablaService.seleccionarItems$.subscribe(items => {
		if (items) {
		  items.forEach(item => {
			this.dataSource.data.forEach(row => {
			  if (row.id && row.id === item['id']) {
				item['seleccionado'] ? this.selection.select(row) : this.selection.deselect(row);
			  }
			});
		  });
		}
	  });
	}
  
	seleccionarTodo(): void {
	  this.selection.select(...this.dataSource.data);
	}
  
	deseleccionarTodo(): void {
	  if (this.dataSource?.data.length > 0) {
		this.selection.deselect(...this.dataSource.data);
	  }
	}
  
	esColumnaVisible(puntoCorteVisibleColumna: string) {
	  let permitido = true;
	  if (puntoCorteVisibleColumna) {
		// // console.log(puntoCorteVisibleColumna, this.currentScreenWidth);
		switch(this.currentScreenWidth) {
		  case 'lg':
			permitido = puntoCorteVisibleColumna.includes('lg') || puntoCorteVisibleColumna.includes('md') || puntoCorteVisibleColumna.includes('sm') || puntoCorteVisibleColumna.includes('xs');
			break;
		  case 'md':
			permitido =  puntoCorteVisibleColumna.includes('md') || puntoCorteVisibleColumna.includes('sm') || puntoCorteVisibleColumna.includes('xs');
			break;
		  case 'sm':
			permitido =  puntoCorteVisibleColumna.includes('sm') || puntoCorteVisibleColumna.includes('xs');
			break;
		  case 'xs':
			permitido =  puntoCorteVisibleColumna.includes('xs');
			break;
		  default:
			permitido = true;
		}
	  }
	  return permitido;
	}
  
	get deshabilitarLimpiarFiltros() {
	  if (!this.cambioValoresFiltrado && !this.cambioValorBuscador) {
		if (this.botoneraDialogFiltrosResponsivos) this.botoneraDialogFiltrosResponsivos[0].disabled = true;
		return true;
	  }
	  if (this.botoneraDialogFiltrosResponsivos) this.botoneraDialogFiltrosResponsivos[0].disabled = false;
	  return false;
	}
  
	// responsiveDinamico
	calcularAnchoColumnas(arrKeyColumnas: string[]) {
	  // // console.log('Estas key cogen ', arrKeyColumnas);
	  // let widthRow =  this.rowTest.nativeElement.clientWidth;
	  let anchoTotalColumna = 0;
	  let celdas = document.querySelectorAll('[id="' + this.identificador + '"] [role="columnheader"]') as NodeList;
	  console.log('CEldas ', celdas.length);
	  var arrayNodes = [].slice.call(celdas, 0);
	  arrayNodes.forEach((valor, indice, array) => {
		// // console.log(celdas[indice]);
		let identificador = (array[indice] as HTMLElement).getAttribute('key');
		// console.log((array[indice] as HTMLElement).offsetWidth, (array[indice] as HTMLElement).hasAttribute('key'), (array[indice] as HTMLElement).getAttribute('key'));
		if (arrKeyColumnas.indexOf(identificador)>=0){
		  anchoTotalColumna += (array[indice] as HTMLElement).offsetWidth;
		}
	  });
  
	  const anchoDisponible = this.element.nativeElement.querySelector('.table-container');
	  let ancho = anchoDisponible.offsetWidth;
  
  
	  let anchoTabla = (document.getElementsByClassName('mat-paginator')[0] as HTMLElement)?.offsetWidth;
	  // console.log(`Ancho disponible ${ancho} opcion B ${anchoTabla} necesario ${anchoTotalColumna}`);
	  //TODO: CUANTO MIDE FILA, COLUMNA, COGEN?, SI NO COGE LA ÚLTIMA COLUMNA ANTES DE ACCIONES LA QUITO
	  // // console.log('ANCHO COLUMNAS: ', widthColumnas);
	  // // console.log('ANCHO ROW: ', anchoTabla);
	  // console.log('TODO OK: ', anchoTotalColumna, anchoTotalColumna<=anchoTabla);
	  // // console.log("****************************************");
	  // // console.log("****************************************");
	  return anchoTotalColumna<=ancho;
	}
  
  
	aplicarCompotamientoResponsivoDinamico() {
	  if (this.responsiveDinamico) {
		this.columnasOcultas = [];
		let columnasReverse = [];
		this.columnas = this.columnasTotales;
		setTimeout(() => {
		  let columnaskey = this.keysColumnasTotales;
		  let fuenteColumnas = Object.assign([], this.columnasTotales);
		  this.columnasOcultas = [];
		  columnasReverse = (fuenteColumnas.reverse()).filter(columna => {
			let keyActual = columna.key;
			let mostrar = true;
			// console.log('-->', keyActual);
			if (['select', 'iconDrag', 'acciones'].indexOf(columna.key) < 0 && (!columna?.fija)) {
			  // // console.log('-->', 'No fijo');
			  if (!this.calcularAnchoColumnas(columnaskey)) {
				// console.log(`[RESPONSIVO] columna ${keyActual} ocultar`);
				mostrar = false;
				columnaskey = removeItemFromArr(columnaskey, keyActual);
				this.columnasOcultas.push(columna);
			  }
			}
			return mostrar;
		  });
		  this.columnasOcultas = this.columnasOcultas.reverse();
  
		  this.columnas = columnasReverse.reverse();
  
  
		  // columna auto con icono despligue que se mete en total columnas ya filtradas si es que existe columnas ocultas
		  if (!this.mostrarColumnasOcultasEnDialogo) {
			// console.log('Columnas ocultas son ', this.columnasOcultas.length);
			this.columnas = this.establecerVisibilidadColumnaDespligue(this.columnasOcultas.length > 0, this.columnas);
		  }
		}, 500)
	  }
	}
  
	get keysColumnas(): string[] {
	  if (!this.columnas) {
		return [];
	  }
	  return this.columnas.map((column: object) => column['key']);
	}
  
	get keysColumnasTotales(): string[] {
	  if (!this.columnasTotales) {
		return [];
	  }
	  return this.columnasTotales.map((column: object) => column['key']);
	}
  
	get opcionesPaginacion() {
	  return environment.paginador_opciones_numero_elementos;
	}
  
	inicializarPaginador() {
	  if (this.tablaOnline) {
		this.matPaginator.pageSize = environment.numero_elementos_paginador;
		this.dataSource.paginator = this.matPaginator;
	  } else {
		this.paginador._inicio = 0;
		this.paginador._totalFilas = environment.numero_elementos_paginador;
		this.dataSource.paginator = this.matPaginator;
	  }
	}
  
	setInputsValidadoresFiltrado() {
	  this.formularioService.inputs = this.inputsFormularioFiltrado;
	}
  
	getValoresFiltrado() {
	  return this.filtrosService.getFiltros(this.keyFiltrado, this.valoresInicialesFiltrado);
	}
  
	subscripcioncolumnasTabla(): void {
	  this.tablaService.columnas$.subscribe((columnas: IColumnaTabla[]) => {
		// añadimos automaticamente la columna para despligue por defecto con estado no activo, se activara si existen columnas ocultas
		this.columnasTotales = columnas;
		this.columnas = columnas;
  
	  });
	}
  
	subscripcionDataSourceTabla(): void {
	  this.tablaService.data$.subscribe( data => {
		this.dataSource = data;
		this.determinarCalculoTotalesBySeleccion();
		this.determinarTotalElementos();
		let _data = (this.dataSource && this.dataSource?.data ) ? data : null;
		this.columnas = this.tablaService.determinarVisibilidadColumnas(_data, this.columnas);
		// TODO: LLEVAR ChangeDetectorRef AL RESTO DE TABLAS DONDE NEO-BUSQUEDA RECIBE UN VALOR POR DEFECTO
		//  PARA EVITAR ExpressionChangedAfterItHasBeenCheckedError
		if (!this.tablaOnline) {
		  this.filtrar(false);
		}
	  });
	}
  
	cambioSelectTotales(opcionSeleccionada: object) {
	  if (opcionSeleccionada['event']) {
		this.opcionSelectTotales = opcionSeleccionada['event']['selectorTotales'];
	  }
	  this.determinarCalculoTotalesBySeleccion();
	}
  
	determinarCalculoTotalesBySeleccion() {
	  this.dataTotales = [];
	  if (!this.tablaOnline && this.dataSource) {
		switch (this.opcionSelectTotales) {
		  case '1':
			this.calcularTotales(this.dataSource.filteredData);
			break;
		  case '2':
			this.calcularTotales(this.dataSource['_renderData'].value);
			break;
		  case '3':
			// if (this.selection.selected.length > 0)
			this.calcularTotales(this.selection.selected);
		}
	  }
	  // Todo: Implementar para tabla online
	}
  
	// Todo: Implementar cálculo totales en neo-contenedor-mas-info (Responsive)
	calcularTotales(data: any) {
	  // if (data.length > 0) {
		this.columnas.forEach(columna => {
		  if (columna.key === TipoColumnaTablaEnum.Importe
			|| (this.dataSource.filteredData && this.dataSource.filteredData[0] && this.dataSource.filteredData[0][columna.key] && this.dataSource.filteredData[0][columna.key]?.tipo === this.tablaService._TipoColumnaEditable.Cantidad)
			|| (this.dataSource.filteredData && this.dataSource.filteredData[0] && this.dataSource.filteredData[0][columna.key] && this.dataSource.filteredData[0][columna.key]?.tipo === this.tablaService._TipoColumnaEditable.Importe)
		  ) {
			this.dataTotales.push({id: columna.key, titulo: columna.nombre});
		  }
	  });
		// this.columnas.forEach(columna => {
		//     if (columna.key === TipoColumnaTablaEnum.Importe || data[0][columna.key]?.tipo === this.tablaService._TipoColumnaEditable.Cantidad || data[0][columna.key]?.tipo === this.tablaService._TipoColumnaEditable.Importe) {
		//     this.dataTotales.push({id: columna.key, titulo: columna.nombre});
		//     }
		// });
		if (this.dataTotales) {
		  if (data.length > 0) {
			this.dataTotales.forEach((seccion, index) => {
			  let total = 0;
			  data.forEach(row => {
				if (parseFloat(row[seccion.id]?.nombre)) {
				  // // console.log('Valor editable: ', parseFloat(row[seccion.id]['nombre']));
				  total += parseFloat(row[seccion.id]['nombre']);
				} else if (parseFloat(row[seccion.id])){
				  total += parseFloat(row[seccion.id]);
				}
			  });
			  this.dataTotales[index].resultado = total;
			});
		  }
		} else {
		  this.dataTotales = [];
		}
	  // } else {
	  //   this.dataTotales = [];
	  // }
	}
  
	getValorAnteriorCampoBoolean(row, columnakey) {
	  let value;
	  row[columnakey]?.nombre ? value = row[columnakey].nombre : value = row[columnakey];
	  return value;
	}
  
	actualizarDataSource(actualizacion: IActualizacionCeldaEditable) {
	  this.actualizacionCampoEditable.emit(actualizacion);
	}
  
  
	subscripcionTotalDataSourceTabla() {
	  this.tablaService.total$.subscribe(totalResultados => {
		this.totalResultados = totalResultados;
	  });
	}
  
	filtrar(resetPaginador = true) {
	  setTimeout(() => {
		this.filtrosTabla.forEach(filtroTabla => {
		  filtroTabla.validar();
		  if (!filtroTabla.determinarErroresValidacion()) {
			if (this.tablaOnline) {
			  if (this.matPaginator && this.matPaginator?.pageIndex) this.matPaginator.pageIndex = 0;
			  this.aplicarFiltros.emit(this.valoresFiltrado);
			} else {
			  this.aplicarFiltrosOffline();
			  this.determinarCalculoTotalesBySeleccion();
			  this.determinarTotalElementos();
			  if (this.matPaginator && resetPaginador) this.matPaginator.pageIndex = 0;
			}
		  }
		});
		this.aplicarCompotamientoResponsivoDinamico();
	  });
	}
  
	actualizarTablaOffline() {
	  if ( this.dataSource ) {
		if ( this.paginacion ) {
		  if (!this.tablaOnline) {
			this.dataSource.paginator = this.matPaginator;
		  }
		}
		if (this.ordenacionColumnas) {
		  this.dataSource.sort = this.sort ? this.sort : null;
		}
		if (this.filtrado && this.columnasFiltro) {
		  this.dataSource.filterPredicate = this.buscarCoincidenciasRowTablaOffline.bind(this);
		}
		this.determinarCalculoTotalesBySeleccion();
		this.determinarTotalElementos();
	  }
	}
  
	buscarCoincidenciasRowTablaOffline(row, filtroBusqueda): boolean {
	  if (filtroBusqueda) {
		let coincidencias = [];
		for (let i = 0; i < this.columnasFiltro.length; i++) {
		  let columnaFiltro = this.columnasFiltro[i];
		  let valorFiltro = filtroBusqueda.trim().toLowerCase();
		  let valorTabla = '';
		  if (Date.parse(row[columnaFiltro])) {
			valorTabla = row[columnaFiltro];
		  } else if (typeof row[columnaFiltro] === 'object') {
			valorTabla = row[columnaFiltro].nombre;
		  } else {
			valorTabla = row[columnaFiltro];
		  }
  
  
		  if (valorTabla) {
			let coincidencia;
			if (Number(valorTabla) && Number(valorFiltro)) {
			  Number(valorTabla) === Number(valorFiltro) ? coincidencia = true : coincidencia = false;
			} else {
			  valorTabla = String(valorTabla).trim().toLowerCase();
			  coincidencia = valorTabla.includes(valorFiltro);
			}
			coincidencias.push(coincidencia);
		  }
		}
  
		if (coincidencias.includes(true)) {
		  return true;
		}  else {
		  return  false;
		}
	  }
  
	  return true;
	}
  
	aplicarFiltrosOffline() {
	  this.actualizarTablaOffline();
	  const inputsFiltro = this.valoresFiltrado['busqueda'];
	  if (this.dataSource) {
		this.dataSource.filter = inputsFiltro;
		this.tablaService.total = this.dataSource.filteredData.length;
	  }
	}
  
	eventoCambioBuscador(filterValue: string) {
	  filterValue ? this.cambioValorBuscador = true : this.cambioValorBuscador = false;
	  this.valoresFiltrado['busqueda'] = filterValue;
	  this.guardarFiltrosStorage(this.valoresFiltrado);
	  this.determinarCambioValoresFiltradoIniciales(this.valoresFiltrado);
	  this.filtrar();
	}
  
	eventoCambioFormularioFiltros(entidadFormulario: object) {
	  // console.log('ENTIDAD FORM CAMBIO FILTROS TABLA: ', entidadFormulario);
	  this.valoresFiltrado = entidadFormulario;
	  if (this.filtrado_por_termino_busqueda) {
		this.filtrosBusqueda.termino ? this.valoresFiltrado['busqueda'] = this.filtrosBusqueda.termino.trim().toLowerCase() : this.valoresFiltrado['busqueda'] = '';
	  }
	  // console.log('AÑADIENDO VALOR BÚSQUEDA ACTUAL CAMBIO FORM', this.valoresFiltrado['busqueda']);
	  this.guardarFiltrosStorage(this.valoresFiltrado);
	  this.determinarCambioValoresFiltradoIniciales(this.valoresFiltrado);
	  if (this.filtradoSinBotonSubmit && this.filtrado_por_termino_busqueda) {
		if (this.filtrosBusqueda.termino) this.valoresFiltrado['busqueda'] = this.filtrosBusqueda.termino.trim().toLowerCase();
		this.filtrar();
	  }
	}
  
	determinarCambioValoresFiltradoIniciales(entidadFormulario) {
	  JSON.stringify(entidadFormulario) !== JSON.stringify(this.valoresInicialesFiltrado) ? this.cambioValoresFiltrado = true : this.cambioValoresFiltrado = false;
	}
  
  
	submitFiltrado() {
	  if (this.filtrado_por_termino_busqueda) {
		this.filtrosBusqueda.termino ? this.valoresFiltrado['busqueda'] = this.filtrosBusqueda.termino.trim().toLowerCase() : this.valoresFiltrado['busqueda'] = '';
	  }
	  this.guardarFiltrosStorage(this.valoresFiltrado);
	  this.filtrar();
	}
  
	//TODO: AÑADIR FLAG PARA GUARDAR INPUT BÚSQUEDA
	guardarFiltrosStorage(valoresFiltrado: object) {
	  this.filtrosService.setFiltros(this.keyFiltrado, this.valoresFiltrado);
	}
  
  
  
	clearFilter() {
	  this.filtrosService.clearFiltros(this.keyFiltrado);
	  if (this.filtrado_por_termino_busqueda) {
		this.filtrosBusqueda.clearBuscador();
	  }
	  this.filtrosTabla.forEach(filtroTabla => {
		filtroTabla.clearFilter();
	  });
	  this.cambioValoresFiltrado = false;
	}
  
	clearBuscador() {
	  if (this.filtrado_por_termino_busqueda) {
		this.filtrosBusqueda.clearBuscador();
	  }
	}
  
  
	allTableRowsSelected(): boolean {
	  // TODO: ANALIZAR FUNCIONAMIENTO
	  return this.dataSource.data.length === this.selection.selected.length;
	}
  
	filasSeleccionadas(): object[] {
	  let rows;
	  this.tablaOnline ? rows = this.dataSource.data : rows = this.dataSource.filteredData;
	  const rowsSeleccionadas = [];
	  for (let row of rows) {
		rowsSeleccionadas.push(row);
	  }
	  return rowsSeleccionadas;
	}
  
	toogleMasterChecked(): boolean {
	  if (this.dataSource?.data || this.dataSource?.filteredData) {
		return this.todasSeleccionadas();
	  }
	  return false;
	}
  
	todasSeleccionadas(): boolean {
	  let rows;
	  let count = 0;
	  this.tablaOnline ? rows = this.dataSource.data : rows = this.dataSource.filteredData;
	  if (rows.length > 0) {
		for (let item of rows) {
		  if (item['seleccionado']) count++;
		}
	  } else {
		return false;
	  }
  
	  return rows.length === count;
	}
  
	toogleMasterIndeterminate(): boolean {
	  if (this.dataSource?.data || this.dataSource?.filteredData) {
		return !this.todasSeleccionadas() && this.totalItemSeleccionados() > 0;
	  }
	  return false;
  
	}
  
	totalItemSeleccionados(): number {
	  let rows;
	  this.tablaOnline ? rows = this.dataSource.data : rows = this.dataSource.filteredData;
	  let count = 0;
	  for (let item of rows) {
		if (item['seleccionado']) count++;
	  }
	  return count;
	}
  
	toogleMasterClick($event) {
	  this.actualizarTablaOffline();
	  $event.preventDefault();
	  $event.stopPropagation();
  
	  if (this.todasSeleccionadas()) {
		// this.selection.deselect(...rowsVisibles);
		this.dataSource.data.forEach(item => {
		  item['seleccionado'] = false;
		  console.log('DESELECCIONAR ITEM: ', item['seleccionado']);
		});
		this.deseleccionarTodo();
		this.tablaService.seleccionarItems = [];
	  } else {
		// this.selection.select(...rowsVisibles);
		this.dataSource.data.forEach(item => {
		  item['seleccionado'] = true;
		});
		this.tablaService.seleccionarItems = this.dataSource.data;
	  }
  
	  this.determinarCalculoTotalesBySeleccion();
	}
  
	seleccionarRow(row: object) {
	  const seleccionadas = this.filasSeleccionadas();
	  seleccionadas.forEach(item => {
		if (item['id'] === row['id']) {
		  item['seleccionado'] === true ? item['seleccionado'] = false : item['seleccionado'] = true;
		}
	  });
	  this.tablaService.seleccionarItems = seleccionadas;
	  this.determinarCalculoTotalesBySeleccion();
	}
  
  
  
  
  
	changeCheckboxRow(row) {
	  this.selection.toggle(row);
	  this.determinarCalculoTotalesBySeleccion();
	}
  
	calcularRowsVisibles(): any[] {
	  let rowsVisibles = [];
	  let posicionPaginador = 0;
	  let tamañoPaginador = 0;
	  let primeraPosicionRow = 0;
	  let ultimaPosicionRow = 0;
	  const rowsFiltradas = this.dataSource.filteredData;
  
	  if (!this.tablaOnline) {
		posicionPaginador = this.dataSource.paginator.pageIndex;
		tamañoPaginador = this.dataSource.paginator.pageSize;
		primeraPosicionRow = tamañoPaginador * posicionPaginador;
		ultimaPosicionRow = rowsFiltradas.length < primeraPosicionRow + tamañoPaginador
		  ? rowsFiltradas.length
		  : primeraPosicionRow + tamañoPaginador;
  
		for (let i = primeraPosicionRow; i < ultimaPosicionRow; i++) {
		  rowsVisibles.push(rowsFiltradas[i]);
		}
	  } else {
		posicionPaginador = this.dataSource.paginator.pageIndex;
		tamañoPaginador = this.dataSource.paginator.pageSize;
		primeraPosicionRow = tamañoPaginador * posicionPaginador;
		ultimaPosicionRow = rowsFiltradas.length < primeraPosicionRow + tamañoPaginador
		  ? rowsFiltradas.length
		  : primeraPosicionRow + tamañoPaginador;
		rowsFiltradas.forEach(filterRow => rowsVisibles.push(filterRow));
	  }
	  return rowsVisibles;
	}
  
	totalRowsSeleccionadas(rows: any[]): boolean {
	  let count = 0;
	  for (let row of rows) {
		this.selection.isSelected(row) ? count++ : null;
	  }
	  return rows.length === count;
	}
  
	ordenarDataSourceByColumna(sort) {
	  if (this.tablaOnline) {
		this.ordenarColumnas.emit(sort);
		// // console.log('Sort: ', sort);
	  } else {
		this.ordenarColumnaTablaOffline( sort );
	  }
	  if (this.matPaginator) this.matPaginator.pageIndex = 0;
	}
  
	ordenarColumnaTablaOffline( sort: Sort ){
	  const data = this.dataSource.data.slice();
	  if (!sort.active || sort.direction === '') {
		this.dataSource.data = data;
		return;
	  }
  
	  const keyColumna = sort.active;
	  this.dataSource.data =  data.sort((rowA, rowB) => {
		const isAsc = sort.direction === 'asc';
		return this.compararColumnas(rowA[keyColumna], rowB[keyColumna], isAsc);
	  });
	}
  
	compararColumnas(rowA: any, rowB: any, isAsc: boolean) {
		// Split the strings into components
		const pattern = /(\d+|\D+)/g;
		const patternIsNumber = /^\d+$/;
		let componenteA;
		let componenteB;
	  
		switch (typeof rowA) {
		  case 'string':
			componenteA = rowA.match(pattern);
			componenteB = rowB.match(pattern);
			break; 
      case 'boolean':
      componenteA = Number(rowA).toString();
      componenteB = Number(rowB).toString();
      break;
		  case 'object':
			componenteA = rowA.nombre.match(pattern);
			componenteB = rowB.nombre.match(pattern);
			break;
		}
		  
		// Compare the components
		for (let i = 0; i < Math.min(componenteA.length, componenteB.length); i++) {
		  if (patternIsNumber.test(componenteA[i]) && patternIsNumber.test(componenteB[i])) {
			// Compare as numbers
			const num1 = parseInt(componenteA[i], 10);
			const num2 = parseInt(componenteB[i], 10);
			if (num1 < num2) {
			  return isAsc ? -1 : 1;
			} else if (num1 > num2) {
			  return isAsc ? 1 : -1;
			}
		  } else {
			// Compare as strings
			if (componenteA[i] < componenteB[i]) {
			  return isAsc ? -1 : 1;
			} else if (componenteA[i] > componenteB[i]) {
			  return isAsc ? 1 : -1;
			}
		  }
		}
	  
		// If all components are equal, the shorter string comes first
		if (componenteA.length < componenteB.length) {
		  return isAsc ? -1 : 1;
		} else if (componenteA.length > componenteB.length) {
		  return isAsc ? 1 : -1;
		} else {
		  return 0;
		}
	}
  
  
	//ACCIONES CABECERA TABLA
	descargarPdf() {
	  this.accionDescargarPdf.emit();
	}
  
	// ACCIONES TABLA
	get deshabilitarAcciones() {
	  if (this.selection.selected.length > 1) {
		return true;
	  }
	}
  
	eventoCambioPaginador(event) {
	  // // console.log('EVENT: ', event);
	  if (this.tablaOnline) {
		this.paginador._inicio = event.pageSize;
		this.paginador._totalFilas = event.pageIndex;
		this.cambioPaginador.emit(event);
	  } else {
		this.actualizarTablaOffline();
	  }
	}
  
	gestionarEnlace(data?: any) {
	  if (data?.url) {
		this.clickLinkExterno(data?.url);
	  } else {
		this.obtenerRouterLink(data);
	  }
	}
  
	obtenerRouterLink(data?: any) {
	  if (data?.pag) {
		if (data?.id) {
		  return ['/' + data?.pag, data?.id];
		} else {
		  return ['/' + data?.pag];
		}
	  }
	  return undefined;
	}
  
	clickLinkExterno(url) {
	  window.open(url, "_blank");
	}
  
	deshabilitarAccionRow(accion) {
	  return accion.disabled || this.deshabilitarAcciones;
	}
  
	clickRow( event , indiceFila: number) {
	  // // console.log('Click row', event, indiceFila);
	  this.accionClickRow.emit(event);
	  if (!this.extraerMostrarColumnasOcultas) {
		this.incrustarComponente(event, indiceFila);
	  }
	}
  
	focusInputMascaraTextareaEdicion(row, posicionRow: number) {
  
	  if (this.posicionCampoEditableFoco >= 0) {
		this.focusOutTextAreaEdicion(this.posicionCampoEditableFoco);
	  }
	  this.campoEditableFoco = row;
	  this.textAreaColumnaEditable['_results'][posicionRow];
	  setTimeout(()=>{
		this.focusTextAreaEdicion(posicionRow);
	  },100)
	}
  
	focusTextAreaEdicion(posicionRow: number) {
	  this.posicionCampoEditableFoco = posicionRow;
	  this.textAreaOpen[posicionRow] = true;
	  if (this.textAreaColumnaEditable['_results'][posicionRow] && this.textAreaColumnaEditable['_results'][posicionRow].maxRows ) {
		this.textAreaColumnaEditable['_results'][posicionRow].maxRows = this.numeroRowsCeldaEditableFocus;
	  }
	}
  
	valorTextAreaEdicion(valorTextArea: string): string {
	  return valorTextArea;
	}
  
	focusOutTextAreaEdicion(posicionRow: number) {
  
	  this.posicionCampoEditableFoco = null;
	  this.textAreaOpen[posicionRow] = false;
	  if (this.textAreaColumnaEditable['_results'][posicionRow] && this.textAreaColumnaEditable['_results'][posicionRow].maxRows ) {
  
		this.textAreaColumnaEditable['_results'][posicionRow].maxRows = this.numeroRowsCeldaEditableInicial;
	  }
	  this.campoEditableFoco = undefined;
	}
  
	get numeroRowsCeldaEditableFocus(): number {
	  return environment.numero_rows_celda_editable_focus;
	}
  
  
	get numeroRowsCeldaEditableInicial(): number {
	  return environment.numero_rows_celda_editable_inicial;
	}
  
	obtenerAccionesBotonDesplegable (fila) {
	  let accionesFila = [];
	  fila.acciones.forEach(accionFila => {
		accionesFila.push({id: accionFila.idAccion, iconoClase: accionFila.iconClass + ' mr-10 ', texto: accionFila.tooltip});
	  });
	  return accionesFila;
	}
  
	subscripcionMenuDesplegableAccionesFila() {
	  this.subMenuDesplegableAccionesFila = this.botonDesplegableService.accionItemSeleccionados$.subscribe(accionItemSeleccionada => {
		this.clickAccionRowTabla.emit([this.rowSeleccionadaBotonDesplegable, accionItemSeleccionada]);
	  });
	}
  
	subscripcionMenuDesplegableAccionesMultiples() {
	  if (this.subMenuDespegableAccionesMultiples) {
		this.subMenuDespegableAccionesMultiples.unsubscribe();
	  }
	  this.subMenuDespegableAccionesMultiples = this.botonDesplegableService.accionSeleccionada$.subscribe(accionSeleccionada => {
		this.accionMultiple.emit({accion: accionSeleccionada, seleccion: this.selection.selected});
	  });
	}
  
  
	obtenerContenidoVerInformacion(event){
	  let propiedades:  IPerfilPropiedad[] = [];
	  this.columnasOcultas.forEach( columna =>
		{
		  // console.log('COLUMNA CONTENEDOR VER INFO: ', columna);
		  let tipo = <unknown>columna.tipo; //conversion de tipoColumna a tipoPerfilPropiedad
		  let tagCampo = <unknown>columna?.tagCampo; //conversion de tipoColumna a tipoPerfilPropiedad
		  let valor = event[columna.key];
		  if(tipo == PerfilPropiedadTagEnum.Imagen) {
			valor = (event[columna.key].src) ? event[columna.key].src : this.imgDefault;
		  }
		  return propiedades.push({label: columna.nombre, tag: <PerfilPropiedadTagEnum>tipo,tagCampo: <TagCampoEnum>tagCampo, identificador: columna.key, valor, unidad: columna.unidad});
  
		  // return propiedades.push({label: columna.nombre, tag: <PerfilPropiedadTagEnum>tipo, identificador: columna.key, valor: event[columna.key], unidad: columna.unidad});
		}
	  );
	  this.propiedadesMasInfo = propiedades;
	}
  
	verInformacionRowClick(event) {
	  this.obtenerContenidoVerInformacion(event);
  
	  if ( this.extraerMostrarColumnasOcultas ) {
		this.accionVerInformacionRow.emit(this.propiedadesMasInfo);
	  } else {
		if (this.mostrarColumnasOcultasEnDialogo) {
		  this.abrirDialogoVerInformacion();
		}
	  }
	}
  
	abrirDialogoVerInformacion() {
	  this.dialogoMasInfoRef = this.dialog.open(this.dialogoMasInfo);
	}
  
	establecerVisibilidadColumnaDespligue(visible: boolean, columnasMapeadasVisibles) {
	  if (visible) {
		let indice = columnasMapeadasVisibles[0]?.tipo === TipoColumnaTablaEnum.Selector ? 1 : 0;
		let cabecera = columnasMapeadasVisibles.slice(0,indice);
		let cola = columnasMapeadasVisibles.slice(indice,columnasMapeadasVisibles.length);
		return cabecera.concat([this.columnaDespligueMasInfo]).concat(cola);
	  }
	  return columnasMapeadasVisibles;
	}
  
	incrustarComponente(row, indiceFila: number) {
	  if ( !this.extraerMostrarColumnasOcultas && !this.mostrarColumnasOcultasEnDialogo ) {
		let identificadorIconoDespliegueFila = 'fila_' + indiceFila + '_';
		let iconoDespligueFila = this.listadoIconoDespliegueComponent.find((iconoDespligueComponent: IconoDespliegueComponent, index: number, array) =>
		{
		 iconoDespligueComponent.colapsar();
		 return iconoDespligueComponent.identificador === identificadorIconoDespliegueFila;
		});
  
  
  
		if (this.indiceFilaExpandida != null) {
		  // clear old content
		  this.rowContainers.toArray()[this.indiceFilaExpandida].clear();
		}
		if (this.columnasOcultas.length) {
		  this.filaExpandida = this.filaExpandida === row ? null : row;
		  this.obtenerContenidoVerInformacion(row);
		  if (this.indiceFilaExpandida === indiceFila) {
			this.indiceFilaExpandida = null;
			// this.filaExpandida = null;
		  } else {
			if (iconoDespligueFila) {
			  iconoDespligueFila.expandir();
			}
			// const container = this.rowContainers.toArray()[indiceFila];
			// const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(ContenedorMasinfoTablaComponent);
			// const inlineComponent = container.createComponent(factory);
  
			// inlineComponent.instance.propiedades = this.propiedadesMasInfo;
			this.indiceFilaExpandida = indiceFila;
			// this.filaExpandida =row;
		  }
		}
	  }
  
	}
  
	// FILTROS RESPONSIVOS
	abrirFiltros(){
	  this.dialog.open(this.dialogFiltrosResponsivos, {disableClose: true});
	}
  
	esString(valor) {
	  return typeof valor === 'string' || valor instanceof String
	}
	submitAccionDialogFiltrosResponsivos(accionSeleccionada){
	  switch(accionSeleccionada.id) {
		case 'btn-filtrar':
		  this.filtrar();
		  // this.dialogoMasInfoRef.close();
		  if (JSON.stringify(this.filtrosTabla.first.valoresFiltrado) !== JSON.stringify(this.filtrosTabla.last.valoresFiltrado)) {
			this.filtrosTabla.first.setValores(this.filtrosTabla.last.valoresFiltrado);
		  }
		  if (!this.filtrosTabla.last.determinarErroresValidacion()) {
			this.dialog.closeAll();
			this.submitFiltrado();
		  };
		  break;
		case 'btn-limpiar':
		  this.clearFilter();
		  break;
	  }
	}
  }
  
  function removeItemFromArr( arr, item ) {
	return arr.filter( function( e ) {
		return e !== item;
	} );
  }
  