<div mat-dialog-title class="dialog-title">
  <h2>Leyenda informativa</h2>
  <button mat-icon-button aria-label="close dialog" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content class="mat-typography">
    <div class="leyenda">
        <div class="item">
            <img src="{{base_url_iconos + '/assets/icons/markers/marker_1_0.png'}}">
            Almacenamiento estacional de hidrógeno
        </div>
        <div class="item">
            <img src="{{base_url_iconos + '/assets/icons/markers/marker_2_0.png'}}">
            Distribución
        </div>
        <div class="item">
            <img src="{{base_url_iconos + '/assets/icons/markers/marker_3_0.png'}}">
            Integrado
        </div>
        <div class="item">
            <img src="{{base_url_iconos + '/assets/icons/markers/marker_4_0.png'}}">
            Producción
        </div>
        <div class="item">
            <img src="{{base_url_iconos + '/assets/icons/markers/marker_5_0.png'}}">
            Uso en el ámbito residencial
        </div>
        <div class="item">
            <img src="{{base_url_iconos + '/assets/icons/markers/marker_6_0.png'}}">
            Uso en movilidad
        </div>
        <div class="item">
            <img src="{{base_url_iconos + '/assets/icons/markers/marker_7_0.png'}}">
            Usos industriales como materia prima agente reductor
        </div>
        <div class="item">
            <img src="{{base_url_iconos + '/assets/icons/markers/marker_8_0.png'}}">
            Usos industriales como vector energético
        </div>
        <div class="item">
            <img src="{{base_url_iconos + '/assets/icons/markers/marker_9_0.png'}}">
            Valle
        </div>
    </div>
    <div class="leyenda">
        <div class="item">
            <img src="{{base_url_iconos + '/assets/icons/markers/estado_1.png'}}">
            En construcción / Aprobado
        </div>
        <div class="item">
            <img src="{{base_url_iconos + '/assets/icons/markers/estado_2.png'}}">
            En puesta en marcha / En operación
        </div>
        <div class="item">
            <img src="{{base_url_iconos + '/assets/icons/markers/estado_3.png'}}">
            Planificado
        </div>
    </div>
</mat-dialog-content>