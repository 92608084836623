import { Component, Input } from '@angular/core';
import { LayoutLpeListadoComponent } from '../../layouts/layout-lpe-listado/layout-lpe-listado.component';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthQuery } from '@app/neoCore/authState/state/auth.query';
import { FiltrosService } from '@app/neoCore/services/filtros/filtros.service';
import { AuxiliarService } from '@app/neoShared/services/auxiliar/auxiliar.service';
import { BotonDesplegableService } from '@app/neoShared/services/boton-desplegable/boton-desplegable.service';
import { FormularioFiltrosTablaService } from '@app/neoShared/services/formulario-filtros-tabla/formulario-filtros-tabla.service';
import { FormularioService } from '@app/neoShared/services/formulario/formulario.service';
import { TablaService } from '@app/neoShared/services/tabla/tabla.service';
import { environment } from '@environments/environment';
import { GenericoService } from '@app/neoShared/services/services/generico.service';

@Component({
  selector: 'neo-tabla-basica-offline',
  templateUrl: './tabla-basica-offline.component.html',
  styleUrls: ['./tabla-basica-offline.component.scss'],
  providers: [
    FormularioService,
    TablaService,
    FormularioFiltrosTablaService,
    FiltrosService,
    BotonDesplegableService
  ]
})

export class TablaBasicaOfflineComponent extends LayoutLpeListadoComponent {

  @Input() data: any;
  @Input() configuracionTabla;
  @Input() item: string = 'entidad';

  constructor(
    dialog: MatDialog,
    tablaService: TablaService,
    filtrosService: FiltrosService,
    filtrosTablaService: FormularioFiltrosTablaService,
    authQuery: AuthQuery,
    router: Router,
    route: ActivatedRoute,
    auxiliarService: AuxiliarService,
    entidadService: GenericoService,
  ) {
    super(
      dialog,
      tablaService,
      filtrosService,
      filtrosTablaService,
      authQuery,
      router,
      route,
      auxiliarService,
      entidadService
    );
  }
  
  ngOnInit(): void {
    this.objetoConfiguracion = this.configuracionTabla;
    super.ngOnInit();    
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();    
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  // TABLA CONFIGURACION Y carga tabla
  cargarTabla() {
    setTimeout(() => {
      let filtros = this.obtenerFiltros();
      if (this.paginator) {
        this.paginator._numeroElementosPagina = (this.paginator._numeroElementosPagina && this.paginator._numeroElementosPagina !== null)
          ? this.paginator._numeroElementosPagina
          : environment.numero_elementos_paginador;
      }
      this.tablaService.data = new MatTableDataSource(this.mapearDataTabla(this.data));
    });
  }

}