import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { delay } from "rxjs/operators";
import { USUARIOS_DEMO, USUARIO_SESSION_PERMISOS, USUARIO_SESSION_ROL } from '@appNeo/neoShared/pages/layoutUsuarios/usuarios/layout-usuario-data';
import { PRODUCTOS_DEMO } from '@appNeo/neoShared/pages/layoutProductos/productos/layout-productos-data';
import { TICKETS_DEMO } from '@appNeo/neoShared/pages/layoutTikects/layout-tickets-data';
import { environment } from '@environments/environment';
import { DEMO_IDIOMAS_ACTIVOS } from '@appNeo/neoShared/services/idiomas/idiomas-data';
import { DEMO_SECCIONES, DEMO_SECCIONES_COMPLETO, DEMO_SECCIONES_MENU } from '@appNeo/neoShared/modules/edicion-secciones/demo-edicion-secciones-data';
import { DEMO_FILMS, EVENTOS_DEMO, EVENTOS_DEMO_FILTRADO } from '@appNeo/neoShared/pages/demo-calendario/demo-calendario-data';
import { LoaderService } from '@appNeo/neoCore/services/loader/loader.service';



export interface IRespApiBackend {
  codigoError?: number;
  error?: number;
  mensaje?: string;
  version?: string;
  total?: number;
  token?: string;
  data?: any;
  request?: string;
  url: string;
  params?: any;
  response: any;

}

const data: IRespApiBackend[] = [
  {
    url: `${environment.api_base_url_backoffice}/auth/login`,
    request: 'POST',
    params: {
      usuario: 'aaron',
      password: 1234
    },
    response:
    {
      // z-revisar: Guardar usuario (dar valor a resp.data) para comprobar que tenemos usuario
      data: {
        "id": "14",
        "login": "contactoMuyLargoCorreoMuyLargo@duacode.com",
        "nombre": "Martina",
        "apellidos": "Gómez Pérez",
        "email": "contactoMuyLargoCorreoMuyLargo@duacode.com",
        "direccion": "Calle Alfonso Molina 23 4º-A",
        "municipio": "A Coruña",
        "pais": "España",
        "cp": "15789",
        "teletrabajo": true,
        "telefono": "+34987454545",
        "idFacebook": null,
        "idTwitter": null,
        "idGoogle": null,
        "emailVerificado": true,
        "aceptaPublicidad": true,
        "rutaFoto": null,
        "fechaAlta": "2021-09-01 00:00:00",
        "fechaNacimiento": "1985-09-01 00:00:00",
        "fechaModificacion": "2021-09-01 00:00:00",
        "idsDepartamentos": ['1', '2'],
        // "idsDepartamentos": "'1', '2'",
        "observaciones": 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur',
        // "imagenListado": "http://htdocs.local/demo-neo-api-matrix/images/dc_modulos/admin/usuarios/_mini/listado/default.png?t=20211027172126",
        // "imagenPerfil": "http://htdocs.local/demo-neo-api-matrix/images/dc_modulos/admin/usuarios/_mini/perfil/default.png?t=20211027172126",
        // "imagen": "http://htdocs.local/demo-neo-api-matrix/images/dc_modulos/admin/usuarios/default.png?t=20211027172126",
        "rutaImagenPerfil": "assets/images/avatardemo.png",
        "avatar": {
          "id": "1",
          "nombre": "avatardemo.jpg",
          "extension": ".jpg",
          "peso": 2300,
          "imagen": {
            "src": "assets/images/avatardemo.png"
          }
        },
        "idEstado": '1',
        "usuarioEstadoActual": {
          "id": "1",
          "nombre": "Activo",
          "icono": "build",
          "color": "14AD66"
        },
        "roles": USUARIO_SESSION_ROL,
        "permisos": USUARIO_SESSION_PERMISOS,
        "token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MzUzNDgwODYsImV4cCI6MTYzNTQzNDQ4NiwiZGF0YSI6eyJjb2RpZ28iOiJhMWQyMTJkYWE0NDM1NTA0MjVmNGQwZjE3OTdhYWMwNyJ9fQ.YySLhzbYz2tX6fQTib9dF0vVGju5aqMCUKKAaE3-Fx0"
      },
      token: 'TESTTOKEEEEEEN'
    }
  },
  {
    url: `${environment.api_base_url}/auth/login`,
    request: 'POST',
    params: {
      usuario: 'aaron',
      password: 1234
    },
    response:
    {
      "codigoError": 422,
      "error": true,
      "mensaje": "Parámetros no válidos",
      "version": "0.0",
      "total": 3,
      "data": [
        {
          "id": "usuario",
          "mensaje": "El usuario no es correcto."
        },
        {
          "id": "password",
          "mensaje": "La contraseña no es correcta."
        }
      ]
    }
  },
  {
    url: `${environment.api_base_url}/auth/registro`,
    request: 'POST',
    params: {
      usuario: 'aaron',
      password: 1234
    },
    response:
    {
      error: 0,
      usuario: {
        nombre: 'Aarón',
        id: 1
      }
    }
  },
  {
    url: `${environment.api_base_url}/perfil/editar`,
    request: 'POST',
    params: {
      usuario: 'aaron',
      password: 1234
    },
    response:
    {
      data: {
        id: 1,
        login: 'aaron.martine@duacode.com',
        nombre: 'Aarón editado',
        email: 'aaron.martine@duacode.com',
        apellidos: 'Martínez Dasilva',
        rutaFoto: 'https://www.google.com/maps/uv?pb=!1s0xd2e7c88b31e218b:0xede967cd590ff102!3m1!7e115!4shttps://lh5.googleusercontent.com/p/AF1QipPb5P-EdTCwME0ZC6httYY_V2y_KwK2IPCr6rdc%3Dw285-h160-k-no!5sduacode+-+Buscar+con+Google!15zQ2dJZ0FRPT0&imagekey=!1e10!2sAF1QipPb5P-EdTCwME0ZC6httYY_V2y_KwK2IPCr6rdc&hl=es&sa=X&ved=2ahUKEwjc56uHvv7yAhUtgf0HHdQ7AJIQoip6BAhYEAM',
      },
      token: 'TESTTOKEEEEEEN'
    }
  },
  {
    url: `${environment.api_base_url}/perfil/eliminarFoto`,
    request: 'DELETE',
    response:
    {
      data: true
    }
  },
  {
    url: `${environment.api_base_url}/perfil/cambiarPassword`,
    request: 'POST',
    params: {
      usuario: 'aaron',
      password: 1234
    },
    response:
    {
      data: {
        test: 1,
      },
    }
  },
  {
    url: `${environment.api_base_url}/perfil`,
    request: 'GET',
    params: {
      usuario: 'aaron',
      password: 1234
    },
    response:
    {
      error: 0,
      //z-revidar: Cambiar y adaptar a Iusuario
      usuario: {
        id: 1,
        nombre: 'Contacto',
        nombreComercial: 'Duacode SL',
        cuentaEstado: 'Activo',
        esProspecto: true,
        idResponsableComercial: 4,
        idTipoMetodoPago: 2,
        idVencimiento: 3,
        idProcedencia: 3
      }
    }
  },
  {
    url: `${environment.api_base_url}/usuarios/rolesUsuario`,
    request: 'GET',
    params: {},
    response:
    {
      "codigoError": 200,
      "error": false,
      "mensaje": "Operación correcta",
      "version": "0.0",
      "total": 1,
      "data": USUARIO_SESSION_ROL
    }
  },
    {
    url: `${environment.api_base_url}/usuarios/permisosUsuario`,
    request: 'GET',
    params: {},
    response:
    {
      "codigoError": 200,
      "error": false,
      "mensaje": "Operación correcta",
      "version": "0.0",
      "total": 1,
      "data": USUARIO_SESSION_PERMISOS
    }
  },
  {
    url: `${environment.api_base_url}/imagenesEjemplo`,
    request: 'GET',
    response:
    {
      error: 0,
      //z-revidar: Cambiar y adaptar a Iusuario
      data: [
        {
          id: 1,
          nombre: 'imagen1',
          extension: '.jpg',
          tamano: '1 mb',
          fechaModificacion: '11-02-2021',
          posicion: 1,
          img: 'http://www.codeproject.com/KB/GDI-plus/ImageProcessing2/img.jpg'
        },
        {
          id: 2,
          nombre: 'imagen2',
          extension: '.jpg',
          tamano: '0.2 mb',
          fechaModificacion: '11-02-2021',
          posicion: 3,
          img: 'https://www.cleverfiles.com/howto/wp-content/uploads/2018/03/minion.jpg'
        },
        {
          id: 3,
          nombre: 'imagen3',
          extension: '.jpg',
          tamano: '1.3 mb',
          fechaModificacion: '11-02-2021',
          posicion: 2,
          img: 'https://tinypng.com/images/social/website.jpg'
        },
        {
          id: 4,
          nombre: 'imagen4',
          extension: '.jpg',
          tamano: '2.3 mb',
          fechaModificacion: '11-02-2021',
          posicion: 0,
          img: 'https://s03.s3c.es/imag/_v0/770x420/1/4/f/El-plnaeta-Tierra-de-cristal-iStock.jpg'
        }
      ]
    }
  },
  // DEMO LAYOUTS USUARIOS
  {
    url: `${environment.api_base_url}/usuarios?numeroElementosPaginador=&orden=nombre&tipoOrden=asc`,
    request: 'GET',
    response: {
      "codigoError": 200,
      "error": false,
      "mensaje": "Operación correcta",
      "version": "0.0",
      "total": 1,
      "data": USUARIOS_DEMO
    }
  },
  {
    url: `${environment.api_base_url}/usuarios`,
    request: 'POST',
    params: {},
    response: {
      "codigoError": 200,
      "error": false,
      "mensaje": "Operación correcta",
      "version": "0.0",
      "total": 1,
      "data": 300
    }
  },
  {
    url: `${environment.api_base_url}/usuarios/1`,
    request: 'PATCH',
    params: {},
    response: {
      "codigoError": 200,
      "error": false,
      "mensaje": "Operación correcta",
      "version": "0.0",
      "total": 1,
      "data": 300
    }
  },
  {
    url: `${environment.api_base_url}/usuarios/1`,
    request: 'GET',
    response: {
      "codigoError": 200,
      "error": false,
      "mensaje": "Operación correcta",
      "version": "0.0",
      "total": 1,
      "data": USUARIOS_DEMO[0]
    }
  },
  {
    url: `${environment.api_base_url}/cuentas/1/ficheros`,
    request: 'POST',
    response: {
      "codigoError": 200,
      "error": false,
      "mensaje": "Operación correcta",
      "version": "0.0",
      "total": 1,
      "data": []
    }
  },
  // DEMO LAYOUTS PRODUCTOS
  {
    url: `${environment.api_base_url}/productos?numeroElementosPaginador=&orden=nombre&tipoOrden=asc`,
    request: 'GET',
    response: {
      "codigoError": 200,
      "error": false,
      "mensaje": "Operación correcta",
      "version": "0.0",
      "total": 1,
      "data": PRODUCTOS_DEMO
    }
  },
  {
    url: `${environment.api_base_url}/productos`,
    request: 'POST',
    params: {},
    response: {
      "codigoError": 200,
      "error": false,
      "mensaje": "Operación correcta",
      "version": "0.0",
      "total": 1,
      "data": 300
    }
  },
  {
    url: `${environment.api_base_url}/productos/1`,
    request: 'PATCH',
    params: {},
    response: {
      "codigoError": 200,
      "error": false,
      "mensaje": "Operación correcta",
      "version": "0.0",
      "total": 1,
      "data": 300
    }
  },
  {
    url: `${environment.api_base_url}/productos/1`,
    request: 'DELETE',
    response:
    {
      data: true
    }
  },
  {
    url: `${environment.api_base_url}/productos/1`,
    request: 'GET',
    response: {
      "codigoError": 200,
      "error": false,
      "mensaje": "Operación correcta",
      "version": "0.0",
      "total": 1,
      "data": PRODUCTOS_DEMO[0]
    }
  },
  {
    url: `${environment.api_base_url}/productos/2`,
    request: 'GET',
    response: {
      "codigoError": 200,
      "error": false,
      "mensaje": "Operación correcta",
      "version": "0.0",
      "total": 1,
      "data": PRODUCTOS_DEMO[1]
    }
  },
  {
    url: `${environment.api_base_url}/productos/1/ficheros`,
    request: 'POST',
    response: {
      "codigoError": 200,
      "error": false,
      "mensaje": "Operación correcta",
      "version": "0.0",
      "total": 1,
      "data": []
    }
  },

  // DEMO LAYOUTS TiCKETS
  {
    url: `${environment.api_base_url}/tickets?numeroElementosPaginador=&orden=nombre&tipoOrden=asc`,
    request: 'GET',
    response: {
      "codigoError": 200,
      "error": false,
      "mensaje": "Operación correcta",
      "version": "0.0",
      "total": 1,
      "data": TICKETS_DEMO
    }
  },
  {
    url: `${environment.api_base_url}/tickets`,
    request: 'POST',
    params: {},
    response: {
      "codigoError": 200,
      "error": false,
      "mensaje": "Operación correcta",
      "version": "0.0",
      "total": 1,
      "data": 300
    }
  },
  {
    url: `${environment.api_base_url}/tickets/1`,
    request: 'PATCH',
    params: {},
    response: {
      "codigoError": 200,
      "error": false,
      "mensaje": "Operación correcta",
      "version": "0.0",
      "total": 1,
      "data": 300
    }
  },
  {
    url: `${environment.api_base_url}/tickets/1`,
    request: 'GET',
    response: {
      "codigoError": 200,
      "error": false,
      "mensaje": "Operación correcta",
      "version": "0.0",
      "total": 1,
      "data": TICKETS_DEMO[0]
    }
  },
  {
    url: `${environment.api_base_url}/tickets/1/ficheros`,
    request: 'POST',
    response: {
      "codigoError": 200,
      "error": false,
      "mensaje": "Operación correcta",
      "version": "0.0",
      "total": 1,
      "data": []
    }
  }, 
  // IDIOMAS ACTIVOS
  {
    url: `${environment.api_base_url}/idiomasActivos`,
    request: 'GET',
    response: {
      "codigoError": 200,
      "error": false,
      "mensaje": "Operación correcta",
      "version": "0.0",
      "total": 1,
      "data": DEMO_IDIOMAS_ACTIVOS
    }
  },
  // HELPERSSERVICE
  {
    url: `${environment.api_base_url_backoffice}/hayModulo?numeroElementosPaginador=`,
    request: 'GET',
    response: {
      "codigoError": 200,
      "error": false,
      "mensaje": "Operación correcta",
      "version": "0.0",
      "total": "10",
      "data": [
        {
          "nombre": "admin",
          "valor": true
        },
        {
          "nombre": "edicion",
          "valor": true
        },
        {
          "nombre": "tpv",
          "valor": true
        }
      ]
    }
  },
    // SECCIONES EDITABLES
  //edicionSeccionMenu
  {
    url: `${environment.api_base_url}/edicionSeccionMenu?numeroElementosPaginador=&orden=id&tipoOrden=asc`,
    request: 'GET',
    response: {
      "codigoError": 200,
      "error": false,
      "mensaje": "Operación correcta",
      "version": "0.0",
      "total": "10",
      "data": DEMO_SECCIONES_MENU
    }
  },
  {
    url: `${environment.api_base_url}/edicionSeccion/configuracionFicheros?&idEdicionTipo=6`,
    request: 'GET',
    response: {
      "codigoError": 200,
      "error": false,
      "mensaje": "Operación correcta",
      "version": "0.0",
      "total": "10",
      "data": {
        "codigoError": 200,
        "error": false,
        "mensaje": "Operación correcta",
        "version": "0.0",
        "total": 3,
        "data": [
          {
            "clave": "ficheros_maxFicheros",
            "valor": 1
          },
          {
            "clave": "ficheros_formatos",
            "valor": "png,jpg,jpeg"
          },
          {
            "clave": "ficheros_maxTamanoFichero",
            "valor": 5
          }
        ]
      }
    }
  },
  {
    url: `${environment.api_base_url}/edicionSeccion/configuracionFicheros?&idEdicionTipo=7`,
    request: 'GET',
    response: {
      "codigoError": 200,
      "error": false,
      "mensaje": "Operación correcta",
      "version": "0.0",
      "total": "10",
      "data": {
        "codigoError": 200,
        "error": false,
        "mensaje": "Operación correcta",
        "version": "0.0",
        "total": 3,
        "data": [
          {
            "clave": "ficheros_maxFicheros",
            "valor": 1
          },
          {
            "clave": "ficheros_formatos",
            "valor": "pdf,doc,docx,zip"
          },
          {
            "clave": "ficheros_maxTamanoFichero",
            "valor": 5
          }
        ]
      }
    }
  },
  {
    url: `${environment.api_base_url}/edicionSeccion/configuracionFicheros?&idEdicionTipo=8`,
    request: 'GET',
    response: {
      "codigoError": 200,
      "error": false,
      "mensaje": "Operación correcta",
      "version": "0.0",
      "total": "10",
      "data": {
        "codigoError": 200,
        "error": false,
        "mensaje": "Operación correcta",
        "version": "0.0",
        "total": 3,
        "data": [
          {
            "clave": "ficheros_maxFicheros",
            "valor": 10
          },
          {
            "clave": "ficheros_formatos",
            "valor": "jpg,jpeg,png"
          },
          {
            "clave": "ficheros_maxTamanoFichero",
            "valor": 1
          }
        ]
      }
    }
  },
  {
    url: `${environment.api_base_url}/edicionSeccion/configuracionFicheros?&idEdicionTipo=9`,
    request: 'GET',
    response: {
      "codigoError": 200,
      "error": false,
      "mensaje": "Operación correcta",
      "version": "0.0",
      "total": "10",
      "data": {
        "codigoError": 200,
        "error": false,
        "mensaje": "Operación correcta",
        "version": "0.0",
        "total": 3,
        "data": [
          {
            "clave": "ficheros_maxFicheros",
            "valor": 10
          },
          {
            "clave": "ficheros_formatos",
            "valor": "doc,docx,pdf,xls,xlsx,zip"
          },
          {
            "clave": "ficheros_maxTamanoFichero",
            "valor": 5
          }
        ]
      }
    }
  },
  {
    url: `${environment.api_base_url}/edicionSeccion/imagenDefecto`,
    request: 'GET',
    response: {
      "codigoError": 200,
      "error": false,
      "mensaje": "Operación correcta",
      "version": "0.0",
      "total": "10",
      "data": {
        "codigoError": 200,
        "error": false,
        "mensaje": "Operación correcta",
        "version": "0.0",
        "total": 1,
        "data": {
          "nombre": "default.png",
          "alt": "default.png",
          "title": "default.png",
          "ruta": "https://preproduccion.duacode.org/ocio-marineda/web-zona-fan/images/dc_modulos/edicion/default.png?t=20220712142642",
          "miniaturas": {
            "_mini/grid": "https://preproduccion.duacode.org/ocio-marineda/web-zona-fan/images/dc_modulos/edicion/_mini/grid/default.png?t=20220712142642",
            "_mini/listado": "https://preproduccion.duacode.org/ocio-marineda/web-zona-fan/images/dc_modulos/edicion/_mini/listado/default.png?t=20220712142642",
            "_mini/perfil": "https://preproduccion.duacode.org/ocio-marineda/web-zona-fan/images/dc_modulos/edicion/_mini/perfil/default.png?t=20220712142642",
            "_mini/galeria": "https://preproduccion.duacode.org/ocio-marineda/web-zona-fan/images/dc_modulos/edicion/_mini/galeria/default.png?t=20220712142642",
            "_mini/private": "https://preproduccion.duacode.org/ocio-marineda/web-zona-fan/images/dc_modulos/edicion/_mini/private/default.png?t=20220712142642",
            "_mini/EDICION_ITEM": "https://preproduccion.duacode.org/ocio-marineda/web-zona-fan/images/dc_modulos/edicion/_mini/EDICION_ITEM/default.png?t=20220712142642"
          }
        }
      }
      }
    },
  {
    url: `${environment.api_base_url}/edicionSeccion?numeroElementosPaginador=&orden=nombre&tipoOrden=asc`,
      request: 'GET',
      response: {
        "codigoError": 200,
        "error": false,
        "mensaje": "Operación correcta",
        "version": "0.0",
        "total": 1,
        "data": DEMO_SECCIONES_COMPLETO
      }
    },
    {
    url: `${environment.api_base_url}/edicionSeccion/15`,
    request: 'GET',
    response: {
      "codigoError": 200,
      "error": false,
      "mensaje": "Operación correcta",
      "version": "0.0",
      "total": 1,
      "data": DEMO_SECCIONES_COMPLETO[0]
    }
  },
  {
    url: `${environment.api_base_url}/edicionSeccion/14`,
    request: 'GET',
    response: {
      "codigoError": 200,
      "error": false,
      "mensaje": "Operación correcta",
      "version": "0.0",
      "total": 1,
      "data": DEMO_SECCIONES_COMPLETO[1]
    }
  },

  {
    url: `${environment.api_base_url}/edicionSeccion/13`,
    request: 'GET',
    response: {
      "codigoError": 200,
      "error": false,
      "mensaje": "Operación correcta",
      "version": "0.0",
      "total": 1,
      "data": DEMO_SECCIONES_COMPLETO[2]
    }
  },
  {
    url: `${environment.api_base_url}/edicionSeccion/12`,
    request: 'GET',
    response: {
      "codigoError": 200,
      "error": false,
      "mensaje": "Operación correcta",
      "version": "0.0",
      "total": 1,
      "data": DEMO_SECCIONES_COMPLETO[3]
    }
  },
  {
    url: `${environment.api_base_url}/edicionSeccion/7`,
    request: 'GET',
    response: {
      "codigoError": 200,
      "error": false,
      "mensaje": "Operación correcta",
      "version": "0.0",
      "total": 1,
      "data": DEMO_SECCIONES_COMPLETO[4]
    }
  },
  {
    url: `${environment.api_base_url}/edicionSeccionGrupo/[X]/imagenes?`,
    request: 'GET',
    response: {
      "codigoError": 200,
      "error": false,
      "mensaje": "Operación correcta",
      "version": "0.0",
      "total": 0,
      "data": []
    }
  },
  {
    url: `${environment.api_base_url}/edicionSeccionGrupo/[X]/adjuntos?`,
    request: 'GET',
    response: {
      "codigoError": 200,
      "error": false,
      "mensaje": "Operación correcta",
      "version": "0.0",
      "total": 0,
      "data": []
    }
  },
  {
    url: `${environment.api_base_url}/edicionSeccionGrupo/34/itemsIniciales?numeroElementosPaginador=&orden=id&tipoOrden=asc`,
    request: 'GET',
    response: {
      "codigoError": 200,
      "error": false,
      "mensaje": "Operación correcta",
      "version": "0.0",
      "total": 0,
      "data": {
        "codigoError": 200,
        "error": false,
        "mensaje": "Operación correcta",
        "version": "0.0",
        "total": 1,
        "data": {
          "id": "12",
          "nombre": "contacto",
          "idiomas": {
            "es": {
              "label": "Contacto"
            }
          },
          "grupo": {
            "id": "34",
            "nombre": "contacto-rrss",
            "multiple": true,
            "cantidadElementosBloque": "2",
            "minimo": "1",
            "maximo": null,
            "idiomas": {
              "es": {
                "label": "Redes sociales"
              }
            },
            "items": [
              {
                "nombre": "rrss-tit",
                "obligatorio": false,
                "longitud": "255",
                "nombreTipoItem": "Input",
                "idTipoItem": "1",
                "idiomas": {
                  "es": {
                    "label": "Nombre 1",
                    "texto": null
                  }
                }
              },
              {
                "nombre": "rrss-url",
                "obligatorio": false,
                "longitud": "255",
                "nombreTipoItem": "Input",
                "idTipoItem": "1",
                "idiomas": {
                  "es": {
                    "label": "Enlace 1",
                    "texto": null
                  }
                }
              }
            ]
          }
        }
      }
    }
  },
  {
    url: `${environment.api_base_url}/edicionSeccionGrupo/39/itemsIniciales?numeroElementosPaginador=&orden=id&tipoOrden=asc`,
    request: 'GET',
    response: {
      "codigoError": 200,
      "error": false,
      "mensaje": "Operación correcta",
      "version": "0.0",
      "total": 0,
      "data": {
        "codigoError": 200,
        "error": false,
        "mensaje": "Operación correcta",
        "version": "0.0",
        "total": 1,
        "data": {
          "id": "15",
          "nombre": "faq",
          "idiomas": {
            "es": {
              "label": "faq"
            }
          },
          "grupo": {
            "id": "39",
            "nombre": "faq-faq",
            "multiple": true,
            "cantidadElementosBloque": "2",
            "minimo": "1",
            "maximo": null,
            "idiomas": {
              "es": {
                "label": "Listado de preguntas"
              }
            },
            "items": [
              {
                "nombre": "faq-tit",
                "obligatorio": false,
                "longitud": "255",
                "nombreTipoItem": "Input",
                "idTipoItem": "1",
                "idiomas": {
                  "es": {
                    "label": "Pregunta 1",
                    "texto": null
                  }
                }
              },
              {
                "nombre": "faq-txt",
                "obligatorio": false,
                "longitud": "20000",
                "nombreTipoItem": "Tinymce minimo",
                "idTipoItem": "4",
                "idiomas": {
                  "es": {
                    "label": "Respuesta 1",
                    "texto": null
                  }
                }
              }
            ]
          }
        }
      }
    }
  },
  // EVENTOS API EXTERNA
  {
    // url: 'http://htdocs.local/demo-neo-api-matrix/api/eventos?desde=2022-03-01&hasta=2022-03-02',
    url: `${environment.api_base_url}/eventos?desde=2022-03-01&hasta=2022-03-25`,
    request: 'GET',
    response: {
      "codigoError": 200,
      "error": false,
      "mensaje": "Operación correcta",
      "version": "0.0",
      "total": 1,
      "data": {
        "page": 1,
        "results": DEMO_FILMS,
        "total_pages": 2,
        "total_results": 39
        }
    }
  },
  // EVENTOS API PROPIA
  {
    url: `${environment.api_base_url}/eventos?numeroElementosPaginador=&orden=nombre&tipoOrden=asc`,
    request: 'GET',
    response: {
      "codigoError": 200,
      "error": false,
      "mensaje": "Operación correcta",
      "version": "0.0",
      "total": 1,
      "data": EVENTOS_DEMO
    }
  },
  // BUSQUEDA
  {
    url: `${environment.api_base_url}/eventos?numeroElementosPaginador=&orden=nombre&tipoOrden=asc&[x]`,
    request: 'GET',
    response: {
      "codigoError": 200,
      "error": false,
      "mensaje": "Operación correcta",
      "version": "0.0",
      "total": 1,
      // "data": EVENTOS_DEMO_FILTRADO
      "data":EVENTOS_DEMO
    }
  },
  {
    url: `${environment.api_base_url}/eventos`,
    request: 'POST',
    params: {},
    response: {
      "codigoError": 200,
      "error": false,
      "mensaje": "Operación correcta",
      "version": "0.0",
      "total": EVENTOS_DEMO.length,
      "data": EVENTOS_DEMO[EVENTOS_DEMO.length-1].id
    }
  },
  {
    url: `${environment.api_base_url}/eventos/[id]`,
    request: 'PATCH',
    params: {},
    response: {
      "codigoError": 200,
      "error": false,
      "mensaje": "Operación correcta",
      "version": "0.0",
      "total": EVENTOS_DEMO.length,
      "data": EVENTOS_DEMO[EVENTOS_DEMO.length-1].id
    }
  },
  {
    url: `${environment.api_base_url}/eventos/[id]`,
    request: 'DELETE',
    response:
    {
      data: true
    }
  }
]



@Injectable()
export class BackendInterceptor implements HttpInterceptor {


  constructor(
    private injector: Injector,
    private _loading: LoaderService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log(request);
    let body = data.find(x => x.url == request.url && x.request == request.method);

    // capturamos patch [id] 
    if (request.method == 'PATCH' && !body) {
      body = data.find( x => {
        return request.url.includes(x.url.replace('[id]', '')) && x.request == request.method;
      });
    }
    // capturamos GET [con parametros filtros dinamicos] []
    if (request.method == 'GET' && !body) {
      body = data.find( x => {
        return x.url.includes('[x]') && request.url.includes(x.url.replace('[x]', '')) && x.request == request.method;
      });
    }

    // capturamos GET edicionSeccionGrupo/idcampo/imagenes? edicionSeccionGrupo/idcampo/adjuntos? 
    if (request.method == 'GET' && !body && request.url.includes('edicionSeccionGrupo/') && request.url.includes('/imagenes')) {
      body = data.find(x => {
        return x.url.includes('edicionSeccionGrupo/[X]/imagenes?') && x.request == request.method;
      });
    }
    if (request.method == 'GET' && !body && request.url.includes('edicionSeccionGrupo/') && request.url.includes('/adjuntos')) {
      body = data.find(x => {
        return x.url.includes('edicionSeccionGrupo/[X]/adjuntos?') && x.request == request.method;
      });
    }


    if (request.method == 'DELETE' && !body) {
      body = data.find( x => {
        return x.url.includes('[id]') && request.url.includes(x.url.replace('[id]', '')) && x.request == request.method;
      });
    }
    if (body) {
      console.log('[[[ Peticion capturada para respuesta simulada ]]]', body);
      this.finalizacionPeticiones();
      // si simulacion respuesta
      return of(new HttpResponse({
        status: (body?.response && body?.response?.error && body?.response?.codigoError) ? body?.response?.codigoError : 200,
        body: body.response
      })).pipe(
        delay(400)
      );
    } else {
      return next.handle(request);
    }

  }

  finalizacionPeticiones() {
    this._loading.setLoading(false);
  }
}
