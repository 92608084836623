<link rel="stylesheet" href="https://unpkg.com/leaflet@1.9.4/dist/leaflet.css"
    integrity="sha256-p4NxAoJBhIIN+hmNHrzRCf9tD/miZyoHS5obTRR9BMY=" crossorigin="" />
<script src="https://unpkg.com/leaflet@1.9.4/dist/leaflet.js"
    integrity="sha256-20nQCchB9co0qIjJZRGuk2/Z9VM+kNiyxNV1lvTlZBo=" crossorigin=""></script>
<div class="ubicacion" *ngIf="proyectos">
    <div class="mapa">
        <div class="action-leyenda">
            <neo-button *ngIf="true" [miniFab]="true" [color]="'primary'"
                [iconClass]="'icon-60 icon-ibm--information'" [clase]="'minifab-flotante'"
                (accion)="abrirInfoLeyenda($event)"></neo-button>
        </div>
        <div *ngIf="!mostrarErrorSinMapa" class="mapContainer" id="{{mapContainerId}}"></div>
    </div>

    <div class="sin-mapa" *ngIf="mostrarErrorSinMapa">
        <ion-img class="icon-40" src="assets/imgs/iconos/icon-lost-location.svg"></ion-img>
        <h2>No se ha podido localizar la ubicación</h2>
    </div>
</div>

<!-- <ng-template #dialogLeyenda>
    <neo-acciones-dialog #accionDialogo [dialogTittle]="'Leyenda mapa'" [botonera]="[]"
        (accionBoton)="onSubmitAccionDialogLeyenda($event)">
        <table>
            <tr>
                <td> <img [src]="getIconoActividadPrinicipalProyectoEstado(4, 3)"></td>
                <td> ICONO</td>
                <td> <img [src]="getIconoActividadPrinicipalProyectoEstado(4, 3)"></td>
                <td> ICONO</td>
            </tr>
            <tr>
                <td> <img [src]="getIconoActividadPrinicipalProyectoEstado(4, 3)"></td>
                <td> ICONO</td>
                <td> <img [src]="getIconoActividadPrinicipalProyectoEstado(4, 3)"></td>
                <td> ICONO</td>
            </tr>
            <tr>
                <td> <img [src]="getIconoActividadPrinicipalProyectoEstado(4, 3)"></td>
                <td> ICONO</td>
                <td> <img [src]="getIconoActividadPrinicipalProyectoEstado(4, 3)"></td>
                <td> ICONO</td>
            </tr>
        </table>
    </neo-acciones-dialog>
</ng-template> -->