<div class="content-margin">
    <div class="section-bottom">
        <neo-notificacion #notificacion></neo-notificacion>
        <neo-tabla #tablaEntidad 
            [id]="'tabla-' + item" 
            [identificador]="'tablas-'+ item" 
            [tablaOnline]="false"
            [responsiveDinamico]=false 
            [botoneraAccionesCabecera]="[]" 
            [filtrado]="false"
            [mostrarTotalElementos]="false"
            [keyFiltrado]="this.identificadorFiltro" 
            [valoresInicialesFiltrado]="this.valoresInicialesFiltradoTabla"
            [accionesMultiplesDesplegable]="this.accionesMultiplesDesplegableTabla"
            [paginacion]="objetoConfiguracion?.paginacion" 
            [ordenacionColumnas]="true"
            [columnasFiltro]="this.columnasFiltro" 
            [numeroFiltros]="this.inputsFiltros.length"
            [btnDescargarPdfCabecera]="false" 
            [mostrarColumnasOcultasEnDialogo]="true"
            (accionCabeceraClick)="this.aplicarAccionCabeceraClick($event)"
            (aplicarFiltros)="this.aplicarFiltrosTablaOnline($event)"
            (accionMultiple)="this.aplicarAccionMultipleSeleccion($event)"
            (ordenarColumnas)="this.aplicarOrdenarColumnas($event)"
            (cambioPaginador)="this.aplicarCambiarPaginador($event)"
        ></neo-tabla>
    </div>
</div>
