import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class FormularioService {
  public inputs$: BehaviorSubject<[]> = new BehaviorSubject<[]>(null) ;

  // [{
  //   tag: FormTag.input,
  //   type: 'text',
  //   placeholder: 'Nombre',
  //   formControlName: 'name',
  //   error: 'required',
  //   errorMessage: 'Introducir nombre',
  //   model: 'nombre'
  // }        ]

  constructor() {}

  set inputs(inputs) {
    console.log('Inputs   ', inputs);
    this.inputs$.next(inputs);
  }


}
