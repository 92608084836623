import {CdkDrag, CdkDragDrop, CdkDragMove, CdkDropList, CdkDropListGroup, moveItemInArray} from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IFicheroExtendido } from '@appNeo/neoShared/helpers/interfaces/IFichero';
import { AuxiliarService } from '@appNeo/neoShared/services/auxiliar/auxiliar.service';
import { FicheroSubidaComponent } from '@appNeo/neoShared/components/fichero-subida/fichero-subida.component';
import {
  AccionesTablaEnum,
  IAccionRowTabla,
  IColumnaTablaImagen, TablaService,
} from '@appNeo/neoShared/services/tabla/tabla.service';
import { columnasTablaDraggableFicheros, columnasTablaGenericaFicheros, FORM_INPUT_EDICION } from '@appNeo/neoShared/components/fichero/fichero-data';
import {MatTableDataSource} from '@angular/material/table';
import {TablaDraggableComponent} from '@appNeo/neoShared/components/tabla/tabla-draggable/tabla-draggable.component';
import { debounceTime, distinctUntilChanged, Subject, Subscription } from 'rxjs';
import { IBotonAccionesDialog } from '@appNeo/neoShared/components/acciones-dialog/acciones-dialog.component';
import { IFormInput } from '@appNeo/neoShared/helpers/interfaces/IForm-input';
import { FormularioService } from '@appNeo/neoShared/services/formulario/formulario.service';
import { FormularioComponent } from '@appNeo/neoShared/components/formulario/formulario.component';
import { BotonDesplegableService } from '@appNeo/neoShared/services/boton-desplegable/boton-desplegable.service';
import { AliasFicherosEnum, AliasPluralFicherosEnum, IconoTipoFicherosEnum } from '@appNeo/neoShared/helpers/enums/AliasFIcheros.enum';
import { environment } from '@environments/environment';
import { BusquedaComponent } from '@appNeo/neoShared/components/busqueda/busqueda.component';
import { FicheroSubidaService } from '@appNeo/neoShared/services/fichero-subida/fichero-subida.service';
import { NotificacionEnum } from '@appNeo/neoShared/helpers/enums/Notificacion.enum';
import { IconEnum } from '@appNeo/neoShared/helpers/enums/Icon.enum';

export interface IConfiguracionGestorFicheros {
  externalizarProcesoEditar?: boolean,
  externalizarVerInformacion?: boolean,
  externalizarProcesoEliminar?: boolean
}

export const CONFIGURACION_GESTORFICHEROS_DEFECTO: IConfiguracionGestorFicheros = {
  externalizarProcesoEditar: true,
  externalizarVerInformacion: false,
  externalizarProcesoEliminar: false
}
export enum AccionesMultiplesFicherosEnum {
  invertir = 'invertir',
  seleccionar = 'seleccionar',
  eliminar = 'eliminar',
  hacerFoto = 'hacerFoto',
  elegirFichero = 'elegirFichero',
  eliminarFichero = 'eliminarFichero',
}


export interface IRowTabla {
  imagen: IColumnaTablaImagen;
  nombre: string;
  peso: number;
  tituloSeo: string;
  textoSeo: string;
  acciones: IAccionRowTabla;
}

@Component({
  selector: 'neo-fichero',
  templateUrl: './fichero.component.html',
  styleUrls: ['./fichero.component.scss'],
  providers: [TablaService, FormularioService, BotonDesplegableService, FicheroSubidaService]
})
export class FicheroComponent implements OnInit {
  // TODO si se configura desde servicio, eliminiar INPUT
  // configuracion servicio interno
  @Input('idObjeto') idObjeto: string = '1';
  @Input('camposExtra') camposExtra: object;
  @Input('objetoRelacionado') objetoRelacionado;
  @Input('urlArchivos') urlArchivos;

  @Input('configuracion') configuracion: IConfiguracionGestorFicheros = Object.assign({}, CONFIGURACION_GESTORFICHEROS_DEFECTO);
  visibilidadBuscador: boolean = true;
  @ViewChild('buscadorArchivos') buscadorArchivos: BusquedaComponent;

  @Input('formInputEdicion') formInputEdicion: IFormInput[] = FORM_INPUT_EDICION;
  @ViewChild('formularioEdicion') formularioEdicion: FormularioComponent;

  @ViewChild('appFicheroSubida') appFicheroSubida: FicheroSubidaComponent;
  @ViewChild('appFicheroSubidaEdicion') appFicheroSubidaEdicion: FicheroSubidaComponent;


  // INPUT PARA APP_FICHERO
  @Input('tipoFichero') tipoFichero: AliasFicherosEnum = AliasFicherosEnum.generico;
  @Input('tipoFicheroPlural') tipoFicheroPlural: AliasPluralFicherosEnum = AliasPluralFicherosEnum.generico;
  @Input('iconoTipoFichero') iconoTipoFichero: IconoTipoFicherosEnum = IconoTipoFicherosEnum.generico;
  @Input('titulo') titulo: AliasPluralFicherosEnum = AliasPluralFicherosEnum.generico;

  // INPUT PARA neo-FICHERO-SUBIDA
  @Input('subidaMultiple') subidaMultiple;
  @Input('formatosPermitidos') formatosPermitidos;
  @Input('maximoElementosASubir') maximoElementosASubir;
  @Input('tamanoMaximoTotal') tamanoMaximoTotal;
  @Input('tamanoMaximoFichero') tamanoMaximoFichero;

  // INPUT PARA neo-FICHERO-LISTA/CARD
  @Input('visualizarDetalleItems') visualizarDetalleItems;
  @Input('ordenable') ordenable;
  @Input('arrayArchivos') arrayArchivos: IFicheroExtendido[] = [];
  @Input('vista') vista = 'card';
  @Input('deshabilitarArrastre') deshabilitarArrastre = false;
  @Input('seleccionable') seleccionable = true;




  @ViewChild('dialogSubirArchivos') dialogSubirArchivos: TemplateRef<any>;
  @ViewChild('dialogConfirmarEliminarArchivos') dialogConfirmarEliminarArchivos: TemplateRef<any>;
  @ViewChild('dialogEditarArchivo') dialogEditarArchivo: TemplateRef<any>;
  @ViewChild('dialogConfirmarCerrarSubirArchivos') dialogConfirmarCerrarSubirArchivos: TemplateRef<any>;
  @ViewChild('tablaDraggable') tablaDraggable: TablaDraggableComponent;
  @ViewChild('dialogoMasInfo') dialogoMasInfo: TemplateRef<any>;

  // OUTPUTS AL COMPONENTE DONDE LLAMAMOS A <neo-fichero>
  @Output() borrarItems = new EventEmitter ();
  @Output() cambiarPosicion = new EventEmitter();
  @Output() subirArchivos = new EventEmitter();
  @Output() borrarArchivo = new EventEmitter();
  @Output() editarArchivo = new EventEmitter();/**puede devolver solo los campos edicion de un file que no cambia o campos edicion mas otro archivo que sobreescribe al original */

  archivosPendientesDeEliminar: any = [];

  testImage:any;

  // busqueda activacion
  busqueda: boolean = true;
  valorBusqueda: string;

  /** subscripcion al filtro de busqueda */
  filtroBusqueda: Subject<string> = new Subject<string>();
  termino: string;

  ficheroEdicion: any;
  subMenuDespegableAccionesMultiples: Subscription;
  propiedadesMasInfoFichero;
  constructor(
    private dialog: MatDialog,
    private botonDesplegableService: BotonDesplegableService,
    public auxiliarService: AuxiliarService,
    private tablaService: TablaService,
    private ficheroSubidaService: FicheroSubidaService
  ) {}

  @HostListener('window:resize', ['$event'])
  windowResizecall(event) {
    this.chequearEstadoPorTamanho();
  }

  ngOnInit(): void {
    console.log("***************************");
    console.log("***************************");
    console.log("Datos componente fichero padre ", this.arrayArchivos);
    console.log("***************************");
    console.log("***************************");
    this.ficheroSubidaService.objetoRelacionado = this.objetoRelacionado;
    // this.iniciarCarga();
    this.tablaService.columnas = this.deshabilitarArrastre ? columnasTablaGenericaFicheros : columnasTablaDraggableFicheros;

    // subscripcion proceso busqueda
    this.filtroBusqueda.pipe(debounceTime(600))
    .subscribe(termino => {
      if (termino && termino.length >= 3) {
        this.termino = termino;
        this.filtrar();
      } else {
        this.filtroLimpiar();
      }
    });
    this.determinarAccionesBotonDesplegable();
    this.subscripcionMenuDesplegableAccionesMultiples();
    this.chequearEstadoPorTamanho();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.arrayArchivos) {
      this.iniciarCarga();
    }
  }

  iniciarCarga() {
    if (this.arrayArchivos.length) {
      this.arrayArchivos = this.ordenarItemsPorPosicion(this.arrayArchivos);
      this.arrayArchivos = this.mapearData();
    }
    this.cargarDatos(this.arrayArchivos);
    this.subscripcionDataSourceTabla();
    this.asociarSeleccionados(false);
    this.subscripcionItemsSeleccionadosTabla();
  }


  subscripcionDataSourceTabla() {
    this.tablaService.data$.subscribe(data => {
      data?.data ? this.arrayArchivos = data.data : this.arrayArchivos = [];
    });
  }

  determinarAccionesBotonDesplegable() {
    this.botonDesplegableService.acciones = [
      {id: AccionesMultiplesFicherosEnum.invertir, texto: 'Invertir selección', disabled: !this.numeroItemSeleccionados()},
      {id: AccionesMultiplesFicherosEnum.seleccionar, texto: this.determinarAccionMultipleSeleccionarTodos()},
      {id: AccionesMultiplesFicherosEnum.eliminar, texto: this.determinarAccionMultipleEliminacion(), clase: 'mat-menu-item-warn'},
    ];
  }

  determinarAccionMultipleSeleccionarTodos() {
    let texto = '';
    this.estanTodosSeleccionados() ?  texto = 'Deseleccionar todos' : texto = 'Seleccionar todos';
    return texto;
  }

  determinarAccionMultipleEliminacion() {
    let texto = '';
    this.numeroItemSeleccionados()  ?  texto = 'Eliminar seleccionados' : texto = 'Eliminar todos';
    return texto;
  }

  cargarDatos(datos) {
    this.tablaService.data = new MatTableDataSource(datos);
    this.tablaService.filteredData = datos;
  }

  mapearData(): any  {
    return this.arrayArchivos.map((obj: IFicheroExtendido) => ({ ...obj,
        data: {icon: 'add'},
        visibilidadFiltrado: true,
      })
    );
  }

  subscripcionItemsSeleccionadosTabla() {
    this.tablaService.seleccionarItems$.subscribe(items => {
      if (items) {
        items.forEach(item => {
          this.arrayArchivos.forEach(archivo => {
            if (archivo.id === item['id']) {
              item['seleccionado'] ? archivo.seleccionado = true : archivo.seleccionado = false;
            }
          });
        });
      }
      this.determinarAccionesBotonDesplegable();
    });
  }

  subscripcionMenuDesplegableAccionesMultiples() {
    if (this.subMenuDespegableAccionesMultiples) {
      this.subMenuDespegableAccionesMultiples.unsubscribe();
    }
    this.subMenuDespegableAccionesMultiples = this.botonDesplegableService.accionSeleccionada$
      .pipe(
        debounceTime(environment.boton_desplegable_debounce)
      )
      .subscribe(accionSeleccionada => {
        console.log('Accion seleccionada', accionSeleccionada);
        switch(accionSeleccionada) {
          case AccionesMultiplesFicherosEnum.invertir:
            this.invertirSeleccion();
          break;
          case AccionesMultiplesFicherosEnum.seleccionar:
              this.convertirSeleccion();
          break;
          case AccionesMultiplesFicherosEnum.eliminar:
            this.abrirDialogoConfirmarEliminarArchivos();
          break;
          case AccionesMultiplesFicherosEnum.hacerFoto:
            console.log('Dispositivo movil');
          break;
          case AccionesMultiplesFicherosEnum.eliminar:
            console.log('borrar fichero');
            // confirmar borrar
            // this.eventoBorrarFichero();
          break;
          case AccionesMultiplesFicherosEnum.elegirFichero:
            // TODO al seccionar elemento
            this.abrirDialogoSubirArchivos();
            // this.appFicheroSubida.seleccionarArchivo();
          break;
        }
        this.determinarAccionesBotonDesplegable();
    });
  }

  dropList(event: CdkDragDrop<string[]>) {
    console.log(event);

    moveItemInArray(this.arrayArchivos, event.previousIndex, event.currentIndex);

    console.log(this.arrayArchivos);

  }


  dropCard(event: CdkDragDrop<any>) {
    let indiceDeseado = event.container.data.index;
    let indiceActual = event.previousContainer.data.index;
    // recolocacion posicion deseada
    this.arrayArchivos.splice(indiceActual,1);
    this.arrayArchivos.splice(indiceDeseado, 0, event.previousContainer.data.item);
    // intercambios posiciones
    // this.arrayArchivos[event.previousContainer.data.index] = event.container.data.item;
    // this.arrayArchivos[event.container.data.index] = event.previousContainer.data.item;
    this.asociarIndexItemAPosicion();
    this.tablaService.filteredData = this.arrayArchivos.filter(x => x.visibilidadFiltrado);
  }

  aplicarAccionRow(accionRow: [object, IAccionRowTabla]) {
    switch (accionRow[1].idAccion) {
      case AccionesTablaEnum.Editar:
        this.abrirDialogoEdicionArchivo(accionRow[0]);
        break;
      case AccionesTablaEnum.Eliminar:
        this.abrirDialogoConfirmarEliminarArchivos(accionRow[0]);
        break;
    }
  }

  /*
  MODAL DE EDICIÓN DE ARCHIVO
  */

  abrirDialogoEdicionArchivo(item){
    let archivo: IFicheroExtendido = item;
    this.ficheroEdicion = item;
    console.log('Fichero edicion ', item);
    // this.formEdicion = this.formBuilder.group({
    //   tituloSeo: [archivo.tituloSeo, Validators.required],
    //   textoAlternativoSeo: [archivo.textoAlternativoSeo, Validators.required],
    //   item: this.formBuilder.group({
    //     src: [item.imagen.src],
    //     nombre: [item.nombre],
    //     peso: [item.peso]
    //   })
    // });
    this.dialog.open(this.dialogEditarArchivo, {disableClose: true});

  }

  submitAccionDialogEditarArchivo(){
    console.log('SUBIDA FICHERO EDICION ');
    let valores = {};

    let ficheros = this.appFicheroSubidaEdicion.obtenerFicherosASubir();
    let ficheroBorrar = this.appFicheroSubidaEdicion.obtenerFicherosPendientesBorrado();
    if (ficheros && ficheros.length) {
      let fichero = ficheros[0];
      valores = { fichero: fichero?.file, idFichero: fichero?.fichero?.id, idFicheroBorrar: (ficheroBorrar[0]) ? ficheroBorrar[0]?.id : null };
    } else {
      if (ficheroBorrar && ficheroBorrar.length) {
        valores = { idFicheroBorrar: (ficheroBorrar[0]) ? ficheroBorrar[0]?.id : null };
      }
    }

    let objeto = this.formularioEdicion.validaCampos( false );
    if (objeto && objeto.formulario && objeto.formulario.valid) {
      valores = {...valores, ...objeto.valores};
    }
    console.log('SUBIDA FICHERO EDICION ', ficheros, valores);
    if (Object.keys(valores).length && valores['idFichero']) {
      // en caso de sobreescritura del file obtenedremos los parametros
    /**
     * fichero.file| idFichero junto con los campos editables para cada fichero
     */
      this.editarArchivo.emit(valores);

    }
  }
  /*
  MODAL DE SUBIDA DE ARCHIVOS
  */

  abrirDialogoSubirArchivos(){
    // Pensar lógica para controlar subida de archivos, si ya hay 2 de 10, si hemos añadido 5, si se borran etc
    this.dialog.open(this.dialogSubirArchivos, {disableClose: true});
  }

  submitAccionDialogSubirArchivos(ev){
    console.log('Subir archivos...');
    this.appFicheroSubida.submit();
  }

  submitDatosFicheroSubida(archivosASubir){
    this.dialog.closeAll();
    this.subirArchivos.emit(archivosASubir);
  }

  eventoBorrarFichero(archivoABorrar) {
    if (archivoABorrar) {
      console.log('Borrar fichero ', archivoABorrar);
      if(archivoABorrar?.file && archivoABorrar.file?.size){
        console.log('Borrar archivo temporal ', archivoABorrar.fichero);
        // borrado archivo directorio temporal
      } else {
        console.log('Borrar archivo en directorio final ', archivoABorrar.fichero);
        // borrado archivo directorio final
      }
      this.borrarArchivo.emit(archivoABorrar);
    }
  }

  calcularMaximosElementos(){
    return (this.maximoElementosASubir - this.arrayArchivos.length)>0 ? this.maximoElementosASubir : 0;
  }

  calcularTamanoMaximoTotal(){
    let total = 0;
    for (let i = 0; i < this.arrayArchivos.length; i++) {
      total += this.arrayArchivos[i].peso;
    }
    // console.log(this.tamanoMaximoTotal - total);
    return this.tamanoMaximoTotal - total;
  }

  /*
  MODAL DE CONFIRMACIÓN DE ELIMINACIÓN DE ARCHIVOS
  */

  abrirDialogoConfirmarEliminarArchivos(itemDesdeVistaCard?){
    this.archivosPendientesDeEliminar = [];
    if(itemDesdeVistaCard){
      this.archivosPendientesDeEliminar.push(itemDesdeVistaCard);
    } else if(this.numeroItemSeleccionados()){
      this.archivosPendientesDeEliminar = this.obtenerItemsSeleccionados();
    } else {
      this.archivosPendientesDeEliminar = this.arrayArchivos;
    }
    this.dialog.open(this.dialogConfirmarEliminarArchivos, {disableClose: true});
  }

  submitAccionDialogConfirmarEliminarArchivos(itemsEliminar){
    this.dialog.closeAll();
    let idsItems = itemsEliminar.map(x => x.id);
    this.borrarItems.emit(idsItems);
  }


  obtenerItemsSeleccionados(){
    return this.arrayArchivos.filter(x=> x.seleccionado == true);
  }

  numeroItemSeleccionados(){
    return this.arrayArchivos.filter(x=> x.seleccionado == true).length;
  }

  numeroFicherosBusqueda() {
    return this.arrayArchivos.filter(x=> x.visibilidadFiltrado == true).length;
  }

  asociarSeleccionados(seleccionado){
    this.arrayArchivos.map(item => {
      item.seleccionado = seleccionado;
    });
  }

  convertirSeleccion() {
    if (this.estanTodosSeleccionados()) {
      this.asociarSeleccionados(false);
      this.tablaService.deSeleccionarTodo();
    } else {
      this.asociarSeleccionados(true);
      this.tablaService.seleccionarTodo();
    }
  }

  invertirSeleccion() {
    this.arrayArchivos.forEach(archivo => {
      archivo.seleccionado ? archivo.seleccionado = false : archivo.seleccionado = true;
    });
    this.tablaService.data = new MatTableDataSource(this.arrayArchivos);
  }

  estanTodosSeleccionados(): boolean {
    return this.arrayArchivos.every(x => x.seleccionado === true);
  }

  vaciarSeleccion() {
    this.arrayArchivos.forEach(archivo => {
      archivo.seleccionado = false;
    });
    this.tablaService.data = new MatTableDataSource(this.arrayArchivos);
  }

  asociarIndexItemAPosicion(){
    this.arrayArchivos.map((item, index) => {
      item.posicion = index;
    });
    this.cambiarPosicion.emit(this.arrayArchivos);
  }

  ordenarItemsPorPosicion(array){
    return array.sort(function (a, b) {
      if (a.posicion > b.posicion) {
        return 1;
      }
      if (a.posicion < b.posicion) {
        return -1;
      }
      return 0;
    });
  }

  borrarItem(item){
    this.abrirDialogoConfirmarEliminarArchivos(item);
  }

  seleccionarItem() {
    this.tablaService.seleccionarItems = this.obtenerItemsSeleccionados();
    this.determinarAccionesBotonDesplegable();
  }

  filtroLimpiar() {
    this.termino = '';
    this.valorBusqueda = null;
    if (this.buscadorArchivos && this.buscadorArchivos?.termino) {
      this.buscadorArchivos.termino = this.valorBusqueda;
    }
    this.arrayArchivos.map(x => this.establecerVisibilidad(x, true));
    this.tablaService.filteredData = this.arrayArchivos;
    // this.valorBusqueda = null;

  }

  mostrarBuscador() {
    this.visibilidadBuscador = true;
    setTimeout(() => {
      this.buscadorArchivos.establecerFoco();
      this.buscadorArchivos.termino = this.valorBusqueda;
    });
  }

  filtroPierdeFoco() {
    setTimeout( ()=> {
      this.chequearEstadoPorTamanho();
      this.buscadorArchivos.termino  = this.valorBusqueda;
    })
  }

  filtrar() {
    // this.arrayArchivos = this.arrayArchivos.filter(x => x.nombre.toLowerCase().indexOf(this.termino.toLowerCase())  >= 0);
    this.arrayArchivos.map(x => this.establecerVisibilidad(x, this.comparacionBusqueda(x)));
    this.tablaService.filteredData = this.arrayArchivos.filter(x => x.visibilidadFiltrado);
  }

  comparacionBusqueda(x) {
    return x.nombre.toLowerCase().indexOf(this.termino.toLowerCase())  >= 0;
  }

  establecerVisibilidad(x, visibilidad: boolean) {
    return x.visibilidadFiltrado =  visibilidad;
  }

  cierreDialogoSubirArchivos(){
    console.log('CONFIRMAR CIERRE DIALOGO');
    this.abrirDialogoConfirmarCerrarSubirArchivos();
  }

  abrirDialogoConfirmarCerrarSubirArchivos(){
    if (this.appFicheroSubida.existenFicheroCargados()) {
      this.dialog.open(this.dialogConfirmarCerrarSubirArchivos, {disableClose: true});
    } else {
      this.dialog.closeAll();
    }
  }

  cierreDialogoConfirmarCerrarSubirArchivos(ev){
    console.log('Dejamos todo como esta, subir fichero en plaano anterior');
  }

  submitAccionDialogoConfirmarCerrarSubirArchivos(button: IBotonAccionesDialog){
    console.log(button);
    // ELIMINAR FICHEROS
    // if ( button.id === 'btn-cancelar-subida') {
      if (this.appFicheroSubida.existenFicheroCargados()) {
        this.appFicheroSubida.cancelarSubida();
      }
      this.dialog.closeAll();
    // }
  }

  getAliasFicherosEnum() {
    return AliasFicherosEnum;
  }

  getAliasPluralFicherosEnum(){
    return AliasPluralFicherosEnum;
  }

  getAccionesMultiplesFicherosEnum() {
    return AccionesMultiplesFicherosEnum;
  }

  chequearEstadoPorTamanho() {
    this.visibilidadBuscador = (window.innerWidth >= environment.MEDIATOTABLETV || window.innerWidth < environment.MEDIA_MOBILE);
  }

  cambiarVista(e){
    this.vista = (e) ? 'card' : 'lista';
  }

  abrirDialogoMasInfo(datos) {
    this.propiedadesMasInfoFichero = datos;
    this.propiedadesMasInfoFichero.map( propiedad => propiedad.valor = (propiedad.identificador == 'peso')
    ? this.auxiliarService.formatBytes(propiedad.valor)
    : propiedad.valor);
    this.dialog.open(this.dialogoMasInfo, {disableClose: true});
  }
}
