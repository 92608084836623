import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuxiliarService } from '@app/neoShared/services/auxiliar/auxiliar.service';
import { UtilidadesCrudService } from '@appNeo/neoShared/services/utilidades/utilidades-crud.service';
import { Observable, catchError, forkJoin, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ProyectosService extends UtilidadesCrudService {

    constructor(http: HttpClient, auxiliar: AuxiliarService) {
        super(
            http,
            auxiliar,
            'proyectos',
            'proyectos',
            'proyectos'.charAt(0).toUpperCase() + 'proyectos'.slice(1),
            'proyectos'.charAt(0).toUpperCase() + 'proyectos'.slice(1),
            [
                'id'
            ]
        );
    }

    /************************************ 
  * Funciones apoyo                  *
  *************************************/
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        let peticion = {
            listadoEntidad: this.getAll().pipe(
                catchError(err => of(null))
            )
        };
        const id = route.params.id;
        if (id) {
            peticion['entidad'] = this.get(id).pipe(
                catchError(err => of(null))
            )
        }
        return forkJoin(peticion);
    }
}
