import { IClasesCeldas, IColumnaTabla, TipoColumnaTablaEnum } from "@appNeo/neoShared/services/tabla/tabla.service";
import { TagCampoEnum } from '@appNeo/neoShared/helpers/enums/TagCampo.enum';

export const COLUMNAS_VER_MAS: IColumnaTabla[] = [
{
    key: 'id',
    nombre: 'Identificador',
    tipo: TipoColumnaTablaEnum.Texto,
    tagCampo: TagCampoEnum.Input,
    ordenacion: true,
    clasePersonalizada: [IClasesCeldas.cell40],
},
{
    key: 'nombre',
    nombre: 'Nombre',
    tipo: TipoColumnaTablaEnum.Texto,
    tagCampo: TagCampoEnum.Input,
    ordenacion: true,
    clasePersonalizada: [IClasesCeldas.cell40]
},
]