import { Injectable } from '@angular/core';
import { UtilidadesCrudService } from '../utilidades/utilidades-crud.service';
import { HttpClient } from '@angular/common/http';
import { AuxiliarService } from '../auxiliar/auxiliar.service';

@Injectable({
  providedIn: 'root'
})
export class GenericoService extends UtilidadesCrudService{

  constructor(http: HttpClient, auxiliar: AuxiliarService) {
    super(
      http,
      auxiliar,
      '',
      '',
      '',
      '',
      []
    );
  }
}
