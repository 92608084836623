import { Component, Input, OnInit } from '@angular/core';
import { PROPIEDADES_PROYECTO } from '@app/mapa/mapa-data';
import { ClasesPerfilAnchoEnum } from '@app/neoShared/helpers/enums/perfil-propiedad-tag.enum';

@Component({
  selector: 'neo-proyecto-detalle-personalizado',
  templateUrl: './proyecto-detalle-personalizado.component.html',
  styleUrls: ['./proyecto-detalle-personalizado.component.scss']
})
export class ProyectoDetallePersonalizadoComponent implements OnInit {

  @Input() proyecto = undefined;
  propiedadesProyecto = PROPIEDADES_PROYECTO;
  perfilAncho: ClasesPerfilAnchoEnum.perfilAnchoMedio;

  constructor() { }

  ngOnInit(): void {
    // eliminamos propiedades vacias
    this.propiedadesProyecto.map(propiedad => propiedad.valor = (this.proyecto.hasOwnProperty(propiedad.identificador))
      ? (propiedad.identificador == 'actividadPrincipal' || propiedad.identificador == 'proyectoEstado') ? this.proyecto[propiedad.identificador]?.nombre : this.proyecto[propiedad.identificador]
      : ''
    );
    let propiedades = Object.assign([], this.propiedadesProyecto);
    propiedades = propiedades.filter(item => item.valor != '' && item.valor != null);
    this.propiedadesProyecto = propiedades;
  }


}
