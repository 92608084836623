import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TipoPaginadorEnum } from '@appNeo/neoShared/helpers/enums/TipoPaginador.enum';
import { IRespApi } from '@appNeo/neoShared/helpers/interfaces/IResp-api';
import { Paginador } from '@appNeo/neoShared/models/Paginador/Paginador';
import { AuxiliarService } from '@appNeo/neoShared/services/auxiliar/auxiliar.service';
import { environment } from '@environments/environment';
import { BehaviorSubject, catchError, forkJoin, map, Observable, of, Subject, tap, throwError } from 'rxjs';
import { Paginator } from '@appNeo/neoShared/models/paginator/paginator';
import { DISABLE_DELAY_LOADER } from '@appNeo/neoCore/interceptors/loaderInterceptor/loader.interceptor';
import { IEntidad } from '@appNeo/admin/pages/demo-base-perfil/demo-base-perfil-data';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class DemoService {
  contextLoader = { context: new HttpContext().set(DISABLE_DELAY_LOADER, true) };
  entidad: any;
  nombreEntidad = 'Demo';
  public editandoEntidad$ = new BehaviorSubject<boolean>(false);
  set editandoEntidad(flag: boolean) {
    this.editandoEntidad$.next(flag);
  }
  public error422Api$: Subject<IRespApi> = new Subject<IRespApi>();

  urlEntidad = environment.api_base_url + '/entidad';

  public entidad$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public entidades$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public totalEntidades$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  constructor(
    private http: HttpClient,
    private auxiliar: AuxiliarService
  ) { }

  getAll(tipoPaginador?: TipoPaginadorEnum, paginator?: Paginator, filtros?: any): Observable<any> {
    let request = (tipoPaginador === TipoPaginadorEnum.offline)
      ? this.urlEntidad
      : `${this.urlEntidad}?${this.auxiliar.extraerFiltrosPaginacion(paginator)}${this.auxiliar.extraerFiltros(filtros)}`;
    // TODO: Eliminar contextLoader, añadido para pruebas loader
    return this.http.get(request, { ... this.contextLoader }).pipe(
      tap((data: IRespApi) => this.totalEntidades$.next(data.total)),
      tap((data: IRespApi) => this.entidades$.next(this.mapearEntidades(data.data))),
      map((data: IRespApi) => this.mapearEntidades(data.data)),
      catchError((err: IRespApi) => {
        return this.handleError(err, 'IRequestAll' + this.nombreEntidad);
      }));
  }

  get(id): Observable<IEntidad> {
    return this.http.get(`${this.urlEntidad}/${id}`).pipe(
      map((data: IRespApi) => this.mapearEntidad(data['data'])),
      catchError((err: IRespApi) => {
        return this.handleError(err, 'IRequestGet' + this.nombreEntidad);
      }));
  }

  mapearEntidades(entidades: IEntidad[]) {
    if (entidades.length) {
      entidades.forEach((element, index) => {
        entidades[index] = this.mapearEntidad(element);
      });
    }
    return entidades;
  }

  mapearEntidad(entidad: IEntidad): IEntidad {
    let item = Object.assign({}, entidad);
    let _item = { ...item };
    return _item;
  }


  create(request: any): Observable<any> {
    return this.http.post<IRespApi>(`${this.urlEntidad}`, request).pipe(
      map((resp: IRespApi) => resp.data),
      catchError((err: IRespApi) => {
        return this.handleError(err, 'IRequestPost' + this.nombreEntidad);
      })
    );
  }

  update(id: string, request: any) {
    return this.http.put(`${this.urlEntidad}/${id}`, request).pipe(
      map((data: IRespApi) => data['data']),
      catchError((err: IRespApi) => {
        return this.handleError(err, 'IRequestPut ' + this.nombreEntidad);
      })
    );
  }

  delete(id: string) {
    return this.http.delete(`${this.urlEntidad}/${id}`).pipe(
      map((valor: string) => this.auxiliar.getBoolean(valor)),
      catchError((err: IRespApi) => {
        return this.handleError(err, 'IRequestDelete' + this.nombreEntidad);
      })
    );
  }

  /************************************ 
  * Funciones apoyo                  *
  *************************************/
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    let peticion = {
      listadoEntidad: this.getAll().pipe(
        catchError(err => of(null))
      )
    };
    const id = route.params.id;
    if (id) {
      peticion['entidad'] = this.get(id).pipe(
        catchError(err => of(null))
      )
    }
    return forkJoin(peticion);
  }

  private handleError(err: IRespApi, idRequest = '') {

    // control error server con un html ok
    if (err == 'OK') {
      let mensaje = environment.errorServidorSinCodigo;
      return throwError({ 'error': true, 'data': mensaje, mensaje, 'err': err });
    }

    // dialog específico
    if (err.codigoError === 422) {
      err.request = idRequest;
      this.error422Api$.next(err);
    } 

    // pantalla común
    let mensaje = err?.mensaje;
    if (err.codigoError === 403) {
      mensaje = `${err.mensaje}: `;
      err.data.forEach(element => {
        mensaje += element.mensaje;
      });
    }
    if (err.codigoError === 404) {
      let avisos = '';
      if (err.data) {
        if (typeof err.data === 'string') {
          avisos = err.data;
        } else {
          err.data.forEach(element => {
            if (element.id === 999) {
              avisos = ' ' + element.mensaje + ' ';
            }
          });
        }
      }
      mensaje = err?.mensaje + avisos;
    }
    return throwError({ 'error': true, 'data': err.data, mensaje });
  }


  /****************************/
  /* FICHEROS                */
  /*****************************/
  subirFichero(ficheros: File[], idObjeto = 1): Observable<any> {
    const formData: FormData = new FormData();
    if (ficheros.length) {
      for (let i = 0; i < ficheros.length; i++)
        formData.append('fichero[]', ficheros[i], ficheros[i].name);
    }
    return this.http.post(`${this.urlEntidad}/${idObjeto}/ficheros`, formData).pipe(
      map((data: IRespApi) => data),
      catchError((err: IRespApi) => {
        return throwError(err);
      })
    );
  }

  getFicheros(id: string) {
    return this.http.get(`${this.urlEntidad}/${id}/ficheros`).pipe(
      map((data: IRespApi) => data));
  }

  getFichero(id: string) {

    return this.http.get(`${environment.api_base_url}/ficheros/12`);
    //return this.http.get( `${environment.api_base_url}/ficheros/${id}`);
  }

  eliminarFichero(id: string) {
    return this.http.delete(`${environment.api_base_url}/ficheros/${id}`).pipe(
      map((data: IRespApi) => data));
  }

}
