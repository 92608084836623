import { Component, EventEmitter, OnInit, Output, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { FiltrosService } from '@appNeo/neoCore/services/filtros/filtros.service';
import { AccionesDialogComponent } from '@appNeo/neoShared/components/acciones-dialog/acciones-dialog.component';
import { FormularioComponent } from '@appNeo/neoShared/components/formulario/formulario.component';
import { NotificacionComponent } from '@appNeo/neoShared/components/notificacion/notificacion.component';
import { TablaComponent } from '@appNeo/neoShared/components/tabla/tabla/tabla.component';
import { AccionDialogEnum } from '@appNeo/neoShared/helpers/enums/AccionDialog.enum';
import { FormTagEnum } from '@appNeo/neoShared/helpers/enums/FormTag.enum';
import { IconEnum } from '@appNeo/neoShared/helpers/enums/Icon.enum';
import { NotificacionEnum } from '@appNeo/neoShared/helpers/enums/Notificacion.enum';
import { TipoPaginadorEnum } from '@appNeo/neoShared/helpers/enums/TipoPaginador.enum';
import { ClasesFormularioAnchoEnum, IFormInput } from '@appNeo/neoShared/helpers/interfaces/IForm-input';
import { Paginator } from '@appNeo/neoShared/models/paginator/paginator';
import { environment } from '@environments/environment';
import { format } from 'date-fns';
import { BehaviorSubject, Subscription } from 'rxjs';

import { AccionesTablaEnum, IBotonAccionCabeceraTabla, IColumnaTabla, IColumnaTablaEstado, IColumnaTablaLink, TablaService, TipoColumnaTablaEnum } from '@appNeo/neoShared/services/tabla/tabla.service';
import { BotonDesplegableService, IAccionesBotonDesplegable } from '@appNeo/neoShared/services/boton-desplegable/boton-desplegable.service';
import { FormularioFiltrosTablaService } from '@appNeo/neoShared/services/formulario-filtros-tabla/formulario-filtros-tabla.service';
import { AuxiliarService } from '@appNeo/neoShared/services/auxiliar/auxiliar.service';
import { Query } from '@datorama/akita';
import { AuthState } from '@appNeo/neoCore/authState/state/auth.store';
import { FormularioService } from '@appNeo/neoShared/services/formulario/formulario.service';
import { UtilidadesCrudService } from '@appNeo/neoShared/services/utilidades/utilidades-crud.service';

export enum FormPerfilFormularioEnum {
  entidad = 'entidad',
}

export interface IConfiguracionLpeListado {
  paginas_routes_admin: string,
  identificadorFiltro: any
  item: string,
  columnas: IColumnaTabla[],
  columnasFiltro?: IFormInput[],
  inputsFiltros?: IFormInput[],
  formInput?: IFormInput[],
  paginacion?: boolean,
  excel?: ModoBotonDescargaExcelEnum
}

export enum ModoBotonDescargaExcelEnum {
  Desactivado = '0',
  Cabecera = '1',
  BotoneraTabla = '2'
}

export const ConfiguracionExcelCabecera = {
  btnDescargarPdfCabeceraIcono: 'icon-20 icon-ibm-XLS',
  textoDescargarPdfCabeceraIcono: 'Descarga excel',
  tipoBotonDescarga: 'stroked'
}

@Component({
  selector: 'neo-layout-lpe-listado',
  template: `
    <p>
      Base Layout listado con formulario creación
    </p>
  `,
  styles: [
  ],
  providers: [
    FormularioService,
    TablaService,
    FormularioFiltrosTablaService,
    FiltrosService,
    BotonDesplegableService
  ]
})
export class LayoutLpeListadoComponent implements OnInit {
  configuracionExcelCabecera = ConfiguracionExcelCabecera;
  modoBotonDescargaExcelEnum = ModoBotonDescargaExcelEnum;
  item: string = 'evento privado';
  cargando = true;
  grabando = false;
  refDialogFormulario: MatDialogRef<any, any>;
  // // TODO interface clase
  // // entidad: ITarifa[] = [];
  entidad: any[] = [];
  @ViewChild('notificacion') notificacion: NotificacionComponent;
  @ViewChildren(NotificacionComponent) notificacionesTabla: QueryList<NotificacionComponent>;

  @Output() notificarCambios = new EventEmitter();
  /***************************************************************** */
  // subscriptions
  /***************************************************************** */
  subDataTable: Subscription;
  subTotalData: Subscription;
  subscriptionPermisoEdicion: Subscription;
  permisoEdicion$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  subErrorApi: Subscription;

  /***************************************************************** */
  // configuracion necesaria para tabla genérica. Datos filas y filtros
  /***************************************************************** */
  // TODO nombre tabla
  @ViewChild('tablaEntidad') tablaEntidad: TablaComponent;
  paginator = new Paginator();
  tipoPaginador: TipoPaginadorEnum = TipoPaginadorEnum.online;
  tablaOnline: boolean = true;
  columnasFiltro = [];
  valoresInicialesFiltradoTabla = {};
  accionesMultiplesDesplegableTablaDemo: IAccionesBotonDesplegable[] = [];
  columnas: IColumnaTabla[] = [];
  identificadorFiltro = undefined;
  inputsFiltros: IFormInput[] = [];
  botoneraAccionesCabecera: IBotonAccionCabeceraTabla[] = [];
  botonCrearElemento: IBotonAccionCabeceraTabla =
    {
      id: AccionesTablaEnum.Crear,
      color: 'primary',
      bloqueClass: 'btn-add',
      mostrarBtnLg: true,
      neoButtonClassLg: 'btn-crear-lg',
      iconClassLg: 'icon-16 icon-ibm--add',
      neoButtonInputClassLg: 'btn-sm',
      mostrarBtnXs: true,
      textoLg: `Nuevo ${this.item}`,
      neoButtonClassXs: 'btn-crear-xs',
      iconClassXs: 'icon-16 icon-ibm--add',
      neoButtonInputClassXs: 'btn-sm',
      textoXs: '',
    };
  botonDescargaExcel: IBotonAccionCabeceraTabla =
    {
      id: 'btn-descargaExcel',
      color: 'primary',
      bloqueClass: 'btn-add',
      mostrarBtnLg: true,
      neoButtonClassLg: 'btn-crear-lg',
      // iconClassLg: 'icon-16 icon-ibm--file-download',
      iconClassLg: 'icon-16  icon-ibm-XLS',
      neoButtonInputClassLg: 'btn-sm',
      mostrarBtnXs: true,
      textoLg: 'Descarga excel',
      neoButtonClassXs: 'btn-crear-xs',
      // iconClassXs: 'icon-16 icon-ibm--file-download',
      iconClassXs: 'icon-16 icon-ibm-XLS',
      neoButtonInputClassXs: 'btn-sm',
      textoXs: '',
    };
  accionesMultiplesDesplegableTabla: IAccionesBotonDesplegable[] = [
    // {id: 'previsualizacion', iconoClase: '', texto: 'Previsualización'},
    // {id: 'remesa_santander', iconoClase: '', texto: 'Remesa Santander'},
    // {id: 'remesa_abanca', iconoClase: '', texto: 'Remesa Abanca'},
    // {id: 'remesa_bbva', iconoClase: '', texto: 'Remesa BBVA'},
    // {id: 'remesa_caixabank', iconoClase: '', texto: 'Remesa CaixaBank'},
    // {id: 'girar_seleccinadas', iconoClase: '', texto: 'Girar seleccionadas'},
    // {id: 'anular_seleccinadas', iconoClase: '', texto: 'Anular giros seleccionadas'}
  ];

  /***************************************************************** */
  // terceros
  /***************************************************************** */
  @ViewChild('dialogFormulario') dialogFormulario: TemplateRef<any>;
  @ViewChild('accionDialogFormulario') accionDialogFormulario: AccionesDialogComponent;
  @ViewChild('formulario') formulario: FormularioComponent;
  // set dialogFormulario(dialogFormulario: TemplateRef<any>) { };
  // set accionDialogFormulario(accionDialogFormulario: AccionesDialogComponent) { };
  // set formulario(formulario: FormularioComponent) { };
  formularioAncho: ClasesFormularioAnchoEnum.formularioAnchoMedio;
  formInput: IFormInput[] = [];
  notificacionHeredada: any;

  paginas_routes_admin;
  precarga;


  // espero
  // item : 'nombre entidad'
  // columnasFiltro
  // columnas
  // inputsFiltros
  // formInput
  // paginas_routes_admin PAGINAS_ROUTES_ADMIN.eventos_privados
  // identificadorFiltro

  objetoConfiguracion: IConfiguracionLpeListado;

  constructor(
    public dialog: MatDialog,
    public tablaService: TablaService,
    public filtrosService: FiltrosService,
    public filtrosTablaService: FormularioFiltrosTablaService,
    public authQuery: Query<AuthState>,
    public router: Router,
    public route: ActivatedRoute,
    public auxiliarService: AuxiliarService,
    public entidadService: UtilidadesCrudService
  ) {

    this.iniciarPaginador();
    // POSIBILDAD DE RECIBIR NOTIFICACION EXTERNA POR ROUTE, ej eliminacion desde detalle
    this.notificacionHeredada = this.router.getCurrentNavigation()?.extras?.state;
  }

  private iniciarPaginador() {
    this.paginator = new Paginator();
    if (this.tipoPaginador === TipoPaginadorEnum.online) {
      if (this.paginator) {
        this.paginator._numeroElementosPagina = environment.numero_elementos_paginador;
        this.paginator._pagina = 0;
      }
    }
  }

  ngOnInit(): void {
    // establecemos la configuracion
    this.item = (this.objetoConfiguracion?.item) ?? 'entidad';
    this.columnasFiltro = (this.objetoConfiguracion?.columnasFiltro) ?? [];
    this.columnas = (this.objetoConfiguracion?.columnas) ?? [];
    this.inputsFiltros = (this.objetoConfiguracion?.inputsFiltros) ?? [];
    this.formInput = (this.objetoConfiguracion?.formInput) ?? [];
    this.paginas_routes_admin = (this.objetoConfiguracion?.paginas_routes_admin) ?? '';
    this.identificadorFiltro = (this.objetoConfiguracion?.identificadorFiltro) ?? '';
    if (this.objetoConfiguracion.hasOwnProperty('paginacion')) {
      if (!this.objetoConfiguracion.paginacion) {
        this.paginator = undefined;
      } else {
        this.iniciarPaginador();
      }
    }




    this.establecerBotoneraTabla();
    this.establecerColumnasTabla();
    this.actuarAntesEstablecerFiltros();
    this.subscriptionTotalData();
    this.subcripcionPermisoEdicion();
    this.route.data.subscribe(
      response => {
        this.precarga = response.precarga;
          // carga opciones combos con datos precarga
        if (this.precarga) {
          this.inputsFiltros?.forEach((item, key, array) => {
            if (item.tag == FormTagEnum.select) {
              this.inputsFiltros[key].datos = this.precarga[item.formControlName];
            }
            if (item.tag == FormTagEnum.estado) {
              this.inputsFiltros[key].datos = this.precarga[item.formControlName];
            }
          })
          this.formInput?.forEach((item, key, array) => {
            if (item.tag == FormTagEnum.select) {
              this.formInput[key].datos = this.precarga[item.formControlName];
            }
            if (item.tag == FormTagEnum.estado) {
              this.formInput[key].datos = this.precarga[item.formControlName];
            }

          })
        }
        this.actuarDespuesEstablecerFiltros();
      }
    );
  }

  ngAfterViewInit() {
    // this.subcripcionEntidadesRelacionadas();
    if (this.notificacionHeredada?.notificacion) {
      let params = this.notificacionHeredada?.notificacion.queryParams;
      this.showResult(params.message, params.status, false);
    } else {
      this.cargarTabla();
    }
  }

  ngOnDestroy() {
    if (this.subDataTable) {
      this.subDataTable.unsubscribe();
      this.subTotalData.unsubscribe();
    }

    if (this.subscriptionPermisoEdicion) {
      this.subscriptionPermisoEdicion.unsubscribe();
    }
  }

  // // subscripcion
  subcripcionPermisoEdicion() {
    // this.subscriptionPermisoEdicion = this.authQuery.hasPermission(Permissions.OPORTUNIDADES_FRONT_EDITAR).pipe(
    //   take(1),
    //   tap( data => this.permisoEdicion$.next( data ))).subscribe();
  }

  // // TABLA CONFIGURACION Y carga tabla
  cargarTabla() {
    setTimeout(() => {
      let filtros = this.obtenerFiltros();
      if (this.paginator) {
        this.paginator._numeroElementosPagina = (this.paginator._numeroElementosPagina && this.paginator._numeroElementosPagina !== null)
          ? this.paginator._numeroElementosPagina
          : environment.numero_elementos_paginador;
      }
      this.subDataTable = this.entidadService.getAll(this.tipoPaginador, this.paginator, filtros, true)
        .subscribe((entidades: any[]) => {
          this.tablaService.data = new MatTableDataSource(this.mapearDataTabla(entidades));
          this.cargando = false;
        });
    });
  }

  obtenerFiltros() {
    let filtros = this.filtrosService.getFiltros(this.identificadorFiltro);

    // auto fechas, detecta los definidos como fecha y los transforma a FECHA API corta
    Object.keys(filtros).forEach(item => {
      let propiedad: IFormInput = this.auxiliarService.busquedaPropiedad(this.inputsFiltros, 'formControlName', item);
      if (propiedad && propiedad.tag == FormTagEnum.date) {
        // conversion a formato API
        let propiedadFormatt = (filtros[item]) ? format(new Date(filtros[item]), environment.formatFechaApi) : undefined;
        if (propiedadFormatt) {
          let keyValor = new Object();
          keyValor[propiedad.formControlName] = propiedadFormatt;
          filtros = { ...filtros, ...keyValor }
        }
      }
    })
    filtros = this.actuarDespuesCargarTablaFiltros(filtros);
    return filtros;
  }

  actuarDespuesCargarTablaFiltros(filtros) {
    return filtros;
  }

  mapearDataTabla(entidades) {
    let entidadMapeadas;
    entidadMapeadas = entidades.map(tarifa => {
      let objecto = Object.assign({}, tarifa);

      objecto = this.actuarAntesMapearDatatabla(objecto);

      Object.keys(objecto).forEach(item => {
        let propiedad: IColumnaTabla = this.auxiliarService.busquedaPropiedad(this.columnas, 'key', item);

        if (propiedad && (propiedad.tipo == TipoColumnaTablaEnum.EnlacePrincipal || propiedad.tipo == TipoColumnaTablaEnum.EnlaceSecundario)) {
          // conversion a formato API
          // si data esta configurado como urlExterna auto establecemo el valor en URL para que lo realice, sino lo resuelve con el routing normal. Si queremos hacerlo personalizado, sería asegurarnos que el row tenga el campo .url en el mapping
          if (propiedad?.urlExterna) {
            let dataLinkColumnTable: IColumnaTablaLink = { url: objecto[item] };
            objecto[propiedad.key + 'Mapping'] = dataLinkColumnTable;
          } else {
          let dataLinkColumnTable: IColumnaTablaLink = { id: objecto.id, pag: environment.ruta_padre_admin + this.paginas_routes_admin };
          objecto[propiedad.key + 'Mapping'] = dataLinkColumnTable;
          }
        }

        if (propiedad && propiedad.tipo == TipoColumnaTablaEnum.Boolean) {
          let configuracion = this.obtenerConfiguracionDataTipoBoolean(objecto[propiedad.key]);
          let dataColumnaTipoBoolean: IColumnaTablaEstado = { id: objecto.id, nombre: '', iconoClase: configuracion.claseIcono, color: configuracion.colorIcono };
          objecto[propiedad.key] = dataColumnaTipoBoolean;
        }
      });

      objecto = this.actuarDespuesMapearDatatabla(objecto)
      return objecto;
    });
    return entidadMapeadas;
  }



  actuarDespuesMapearDatatabla(objecto) {
    return objecto;
  }

  actuarAntesMapearDatatabla(objecto) {
    return objecto;
  }

  private subscriptionTotalData() {
    this.subTotalData = this.entidadService.totalEntidades$.subscribe(total => {
      this.tablaService.total = total;
    });
  }

  establecerBotoneraTabla() {
    this.botoneraAccionesCabecera = this.actuarAntesEstablecerBotoneraTabla([this.botonCrearElemento]);
    if (this.botonDescargaActivo() && this.objetoConfiguracion['excel'] && this.objetoConfiguracion.excel == ModoBotonDescargaExcelEnum.BotoneraTabla) {
      this.botoneraAccionesCabecera.push(this.botonDescargaExcel);
    }
    // this.subscriptionPermisoCrear = this.authQuery.hasPermission(Permissions.OPORTUNIDADES_FRONT_CREAR).pipe(
    //   take(1)).subscribe( permiso => {
    //     if (!permiso) {
    //       this.botoneraAccionesCabecera = [];
    //     }else{
    //       this.botoneraAccionesCabecera = [this.botonCrearElemento];
    //     }
    // });
  }
  actuarAntesEstablecerBotoneraTabla(botones: IBotonAccionCabeceraTabla[]) {
    return botones;
  }

  establecerColumnasTabla() {
    let columnasSinPermiso = [];
    if (!this.permisoEdicion$.value) {
      columnasSinPermiso.push('actions');
    }
    this.tablaService.columnas = this.columnas.filter(columna => !columnasSinPermiso.includes(columna.key));
  }

  actuarAntesEstablecerFiltros() {
    this.filtrosTablaService.inputsForm = this.inputsFiltros;
  }

  actuarDespuesEstablecerFiltros() {
    this.filtrosTablaService.inputsForm = this.inputsFiltros;
  }



  // TABLA emits
  public aplicarAccionCabeceraClick(accion) {
    console.log('Accion cabecera tabla', accion);
    switch (accion.id) {
      case AccionesTablaEnum.Crear:
        this.abrirDialogo(AccionDialogEnum.Crear);
        break;

      case 'btn-descargaExcel':
        this.excel();
        break;
    }
  }
  public aplicarClickAccionRowTabla(accion) {
    // console.log('Accion fila tabla', accion);
    // if (accion[0] &&  accion[1] && accion[1]?.idAccion) {
    //   switch(accion[1]?.idAccion) {
    //     case 'crear' :
    //       this.abrirDialogo(AccionDialogEnum.Crear);
    //     break;

    //     case 'actualizar' :
    //       this.girar([accion[0].id]);
    //     break;
    //   }

    // }
  }
  public aplicarAccionMultipleSeleccion(dataAccionSeleccion: any) {
    // console.log('Accion multiple y elementos seleccionados: ', dataAccionSeleccion);
    // if (dataAccionSeleccion?.seleccion?.length) {
    //   let seleccion = dataAccionSeleccion.seleccion.map(item => item.id);
    //   seleccion = [... new Set(seleccion)];
    //   switch(dataAccionSeleccion.accion) {
    //     case 'previsualizacion' :
    //       this.descargaMultiplePdf(seleccion);
    //     break;
    //     case 'remesa_abanca' :
    //       this.remesa(BancosRemesaEnum.Abanca, seleccion);
    //     break;
    //     case 'remesa_santander' :
    //       this.remesa(BancosRemesaEnum.Santander, seleccion);
    //     break;
    //     case 'remesa_bbva' :
    //       this.remesa(BancosRemesaEnum.BBVA, seleccion);
    //     break;
    //     case 'remesa_caixabank' :
    //       this.remesa(BancosRemesaEnum.CaixaBank, seleccion);
    //     break;
    //     case 'girar_seleccinadas' :
    //       this.girar(seleccion);
    //     case 'anular_seleccinadas' :
    //       this.anularGiro(seleccion);
    //     break;
    //   }
    // }
  }
  public aplicarFiltrosTablaOnline(filtros) {
    this.filtrosService.setFiltros(this.identificadorFiltro, filtros);
    if (this.paginator) {
      this.paginator._pagina = 0;
    }
    this.cargarTabla();
  }
  public aplicarOrdenarColumnas(datos) {
    const entidadFiltros = Object.assign({}, this.filtrosService.getFiltros(this.identificadorFiltro));
    entidadFiltros.orden = datos.active;
    entidadFiltros.tipoOrden = datos.direction;
    this.filtrosService.setFiltros(this.identificadorFiltro, entidadFiltros);
    if (this.paginator) {
      this.paginator._pagina = 0;
    }
    this.cargarTabla();
  }
  public aplicarCambiarPaginador(paginacion) {
    if (this.tipoPaginador === TipoPaginadorEnum.online) {
      if (this.paginator) {
        this.paginator._numeroElementosPagina = paginacion.pageSize;
        this.paginator._pagina = paginacion.pageIndex;
      }
      this.cargarTabla();
    }
  }
  public aplicarAccionDescargarPdf(accion) {
    this.excel();
  }

  // tercero emits
  public onAccionDialogoFormulario(accion) {
    // TODO PERSONALIZAR
    if (accion.id == 'btn-confirmar') {
      this.accionDialogFormulario.notificacionDialogo.eliminarNotificaciones();
      this.formulario.formValueReset = true;
      let objFormulario = this.formulario.validaCampos(false);
      if (objFormulario.formulario.valid) {
        this.cargando = true;
        let valores = { ...objFormulario.valores };
        valores = this.convertirInputMatDateFormatoApi(valores);
        this.crearEntidad(valores);
      } else {
        this.accionDialogFormulario.notificacionDialogo.crearNotificacion(NotificacionEnum.Error, 'Error!', 'Rellene los campos obligatorios', true, IconEnum.Error);
      }
    }
  }

  convertirInputMatDateFormatoApi(valores) {
    Object.keys(valores).forEach(item => {
      let propiedad: IFormInput = this.auxiliarService.busquedaPropiedad(this.formInput, 'formControlName', item);
      if (propiedad && propiedad.tag === FormTagEnum.date) {
        // conversion a formato API
        let propiedadFormat = item.replace('Date', '');
        valores[item] ? valores[propiedadFormat] = format(new Date(valores[item]), environment.formatFechaApi) : valores[propiedadFormat] = undefined;
      }
    });
    return valores;
  }

  public onAccionDialogoCerrarFormulario() {
    if (this.subErrorApi) {
      this.subErrorApi.unsubscribe();
    }
  }

  // funcionalidades
  public abrirDialogo(dialog: AccionDialogEnum, entity?): void {
    switch (dialog) {
      case AccionDialogEnum.Crear:
        this.subscriptionErrorApi();
        this.refDialogFormulario = this.dialog.open(this.dialogFormulario, { disableClose: true });
        break;
    }
  }

  public subscriptionErrorApi() {
    this.subErrorApi = this.entidadService.errorApi$.subscribe(data => {
      if (data && data.data) {
        this.formulario?.cleanError();
        data.data.forEach(campoError => {
          if ([`IRequestCreate${this.entidadService.identificadorEntidad}`].includes(data?.request)) {
            this.formulario.generarErrorValidacionCausaExternos({ errorApi: campoError.mensaje }, campoError.id);
          }
        });
      }
    });
  }

  public excel() {
    let filtros = this.obtenerFiltros();
    this.entidadService.getAllExcel(filtros).subscribe(
      x => { this.crearLinkDescarga(x); },
      e => {
        let mensaje = this.auxiliarService.extraerMensajeApiError(e);
        this.showResult(`Se ha producido un error al descarga el fichero asociado al listado ${this.entidadService.nombreEntidadPlural.toLowerCase()}. ` + mensaje, 'ko', true, undefined, this.accionDialogFormulario.notificacionDialogo)
      }
    );
  }

  public crearEntidad(peticion) {
    const peticionMapeada = this.actuarAntesCrearEntidad(peticion);
    this.entidadService.create(peticionMapeada).subscribe(
      x => { this.onAccionDialogoCerrarFormulario(), this.showResult(`${this.entidadService.nombreEntidad} creado con éxito!`, 'ok') },
      e => {
        let mensaje = this.auxiliarService.extraerMensajeApiError(e);
        this.showResult(`Se ha producido un error al crear el ${this.entidadService.nombreEntidad.toLowerCase()}. ` + mensaje, 'ko', true, undefined, this.accionDialogFormulario.notificacionDialogo)
      }
    );
  }

  public actuarAntesCrearEntidad(peticion) {
    return peticion;
  }

  public showResult(message: string, status: string, vaciarNotificaciones: boolean = true, identificadorFormulario?: FormPerfilFormularioEnum, objetoNotificacion?) {

    if (vaciarNotificaciones) {
      this.notificacion.eliminarNotificaciones();
    }
    let notificacion: NotificacionComponent;
    if (identificadorFormulario) {
      let idNotf = (identificadorFormulario) ? 'notificacion' + identificadorFormulario.charAt(0).toUpperCase() + identificadorFormulario.slice(1) : undefined;
      notificacion = (idNotf) ? this.notificacionesTabla.find(item => {
        item.eliminarNotificaciones();
        return item.identificador == idNotf;
      }) : undefined;
    } else {
      if (objetoNotificacion) {
        notificacion = objetoNotificacion;
      }
    }
    if (!notificacion) {
      notificacion = this.notificacion;
    }
    if (status === 'ok') {
      this.dialog.closeAll();
      notificacion.crearNotificacion(NotificacionEnum.Exito, message, message, false, IconEnum.Exito, '', true);
      this.cargarTabla();
    } else {
      notificacion.crearNotificacion(NotificacionEnum.Error, 'Error!', message, true, IconEnum.Error);
    }
  }

  crearLinkDescarga(data) {
    const linkElement = document.createElement('a');
    linkElement.setAttribute('href', data);
    linkElement.setAttribute('download', this.entidadService.nombreEntidadPlural.toLowerCase());
    const clickEvent = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: false
    });
    linkElement.dispatchEvent(clickEvent);
    this.showResult(`Fichero asociado al listado ${this.entidadService.nombreEntidadPlural.toLowerCase()} ha sido generado con éxito!`, 'ok')
  }

  public botonDescargaActivo(tipo?: ModoBotonDescargaExcelEnum): boolean {
    return (tipo)
      ? tipo === this.objetoConfiguracion?.excel
      : [ModoBotonDescargaExcelEnum.BotoneraTabla, ModoBotonDescargaExcelEnum.Cabecera].includes(this.objetoConfiguracion?.excel);
  }

  obtenerConfiguracionDataTipoBoolean(valor: boolean) {
    let claseIcono = '';
    let colorIcono = '';
    if (valor) {
      claseIcono = 'icon-20 icon-ibm--checkmark--outline'
      colorIcono = '#00D385'
    } else {
      claseIcono = 'icon-20 icon-ibm--close--outline';
      colorIcono = 'red'
    }
    return { claseIcono, colorIcono };
  }
}