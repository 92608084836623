import { IIdioma } from "./idiomas.service";

export const DEMO_IDIOMAS_ACTIVOS :IIdioma[]=  [
    {
        id: '1',
        nombre: 'Español',
        codigo: 'es',
        orden: 1,
        activoAdmin: true
    },
    {
        id: '2',
        nombre: 'Inglés',
        codigo: 'en',
        orden: 2,
        activoAdmin: false
    },
    {
        id: '3',
        nombre: 'Gallego',
        codigo: 'gl',
        orden: 3,
        activoAdmin: false    
    },
]