export enum FormTagEnum {
  boolean = 'boolean', /* filtros formulario será combo, resto switch*/
  checkbox = 'checkbox',
  radio = 'radio',
  clipboard = 'clipboard', //enlaces
  contrasena = 'contrasena',
  input = 'input',
  select = 'select', //select individual y multiple
  selectBuscador = 'selectBuscador', //select individual y multiple
  estado = 'estado', //
  chipsAutocomplete = 'chipsAutocomplete',
  inputFilter = 'inputFilter',
  date = 'date',
  importe = 'importe',
  cantidad = 'cantidad',
  telefono = 'telefono',
  textarea = 'textarea',
  captcha = 'captcha',
  fichero = 'fichero',
  ficheroZonaArrastre = 'ficheroZonaArrastre',
  editor = 'editor',
  colorPicker = 'colorPicker',
  contenidoHtml = 'contenidoHtml',
  video = 'video'
}

export enum ExtensionFormInput {
  barraFortaleza = 'barraFortaleza',
  criteriosValidez = 'criteriosValidez',
}
