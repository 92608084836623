import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

// ARRASTRAR MODULO AUTHSTATE A CORE PERSONALIZADO
export interface AuthState {
  permissions: Permissions[];
  roles: RolesEnum[];
}

export enum Permissions {
  ADMINISTRADOR = 'administrador',
}



export enum RolesIdentificadorEnum {
  ADMINISTRADOR = '2',
  ADMIN = '2',
  SW_D  = '3',
  SW_C  = '4',
  SW_T  = '5',
  DIS_D = '6',
  DIS_C = '7',
  DIS_T = '8',
  RRHH_D= '9',
  RRHH_C= '10',
  RRHH_T= '11',
  ADM_D = '12',
  ADM_C = '13',
  ADM_T = '14',
  MKV_D = '15',
  MKV_C = '16',
  MKV_T = '17',
}

export enum RolesEnum {
  ADMINISTRADOR = 'ADMINISTRADOR',
  ADMIN = 'ADMIN',
  SW_D  = 'SW-D',
  SW_C  = 'SW-C',
  SW_T  = 'SW-T',
  DIS_D = 'DIS-D',
  DIS_C = 'DIS-C',
  DIS_T = 'DIS-T',
  RRHH_D= 'RRHH-D',
  RRHH_C= 'RRHH-C',
  RRHH_T= 'RRHH-T',
  ADM_D = 'ADM-D',
  ADM_C = 'ADM-C',
  ADM_T = 'ADM-T',
  MKV_D = 'MKV-D',
  MKV_C = 'MKV-C',
  MKV_T = 'MKV-T',
}


export function createInitialState(): AuthState {

  return {
    permissions: [],
    roles: []
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'auth' })
export class AuthStore extends Store<AuthState> {
  constructor() {
    super(createInitialState());
  }

}
