import { HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IEstado } from '@app/neoShared/helpers/interfaces/IEstado';
import { LOADER_TRANSPARENT } from '@appNeo/neoCore/interceptors/loaderInterceptor/loader.interceptor';
import { ImagenPrincipalPerfilComponent } from '@appNeo/neoShared/components/imagen-principal-perfil/imagen-principal-perfil.component';
import { TipoPaginadorEnum } from '@appNeo/neoShared/helpers/enums/TipoPaginador.enum';
import { IRespApi } from '@appNeo/neoShared/helpers/interfaces/IResp-api';
import { Paginator } from '@appNeo/neoShared/models/paginator/paginator';
import { environment } from '@environments/environment';
import { BehaviorSubject, catchError, forkJoin, map, Observable, of, Subject, tap, throwError } from 'rxjs';

export const FILTRO_ORDEN_DEFECTO = 'id';
type EntidadTipo<T> = {
    something: string;
    data: T;
}

@Injectable({
    providedIn: 'root'
})
export class UtilidadesCrudService {

    public errorApi$: Subject<IRespApi> = new Subject<IRespApi>();
    public editandoEntidad$ = new BehaviorSubject<boolean>(false);
    public entidad$: BehaviorSubject<object> = new BehaviorSubject(null);
    public entidades$: BehaviorSubject<object[]> = new BehaviorSubject<object[]>([]);
    public totalEntidades$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

    contextLoaderTransparent = { context: new HttpContext().set(LOADER_TRANSPARENT, true) };

    urlEntidad = environment.api_base_url;
    urlEntidades = environment.api_base_url;
    aliasAllControlador = '';
    aliasControlador = '';
    nombreEntidad = 'Entidad';
    nombreEntidadPlural = 'Entidades';
    filtrosOrdenPermitidosEntidad: string[] = [];

    constructor(
        public http,
        public auxiliar,
        aliasAllControlador = undefined,
        aliasControlador = undefined,
        nombreEntidad = undefined,
        nombreEntidadPlural = undefined,
        filtrosOrdenPermitidosEntidad = ['id']       
    ) {
        this.aliasControlador = aliasControlador;
        this.aliasAllControlador = aliasAllControlador;
        this.filtrosOrdenPermitidosEntidad = filtrosOrdenPermitidosEntidad;
        this.urlEntidad = (this.aliasControlador && this.aliasControlador !== '') ? this.urlEntidad + '/' + this.aliasControlador : this.urlEntidad;
        this.urlEntidades = (this.aliasAllControlador && this.aliasAllControlador !== '') ? this.urlEntidades + '/' + this.aliasAllControlador : this.urlEntidades;
        this.nombreEntidad = (nombreEntidad && nombreEntidad !== '') ? nombreEntidad : this.nombreEntidad;
        this.nombreEntidadPlural = (nombreEntidadPlural && nombreEntidadPlural !== '') ? nombreEntidadPlural : this.nombreEntidad;
        console.log('[Establecemos service ]', this.urlEntidad, this.aliasControlador, this.nombreEntidad);
    }

    set editandoEntidad(flag: boolean) {
        this.editandoEntidad$.next(flag);
    }


    set entidad(entidad) {
        this.entidad$.next(entidad);
    }

    get identificadorEntidad(): string {
        return this.nombreEntidad.replace(/ /g, "");
    }
    getAll(paginatorType?: TipoPaginadorEnum, paginator?: Paginator, filtros?: any, loaderTransparente?: boolean): Observable<object[] | any> {
        let paramContext = (loaderTransparente) ? { ...this.contextLoaderTransparent } : {};

        let request = (paginatorType === TipoPaginadorEnum.offline)
            ? this.urlEntidades
            : `${this.urlEntidades}?${this.auxiliar.extraerFiltrosPaginacion(paginator)}${this.auxiliar.extraerFiltros(this.obtenerFiltros(filtros))}`;
        return this.http.get(request, paramContext).pipe(
            tap((data: IRespApi) => { this.entidades$.next(data['data']); }),
            tap((data: IRespApi) => { this.totalEntidades$.next(data.total); }),
            map((data: IRespApi) => this.mapearEntidades(data['data'])),
            catchError((err: IRespApi) => {
                return this.handleError(err, `IRequestGetAll${this.nombreEntidad}`);
            })
        );
    }



    getAllExcel(filtros?: any, loaderTransparente?: boolean): Observable<string> {
        let paramContext = (loaderTransparente) ? { ...this.contextLoaderTransparent } : {};

        return this.http.get(`${this.urlEntidades}/excel?${this.auxiliar.extraerFiltros(this.obtenerFiltros(filtros))}`, paramContext).pipe(
            map((data: IRespApi) => data['data']),
            catchError((err: IRespApi) => {
                return this.handleError(err, `IRequestGetAllExcel${this.nombreEntidad}`);
            })
        );

    }

    obtenerFiltros(filtros?: any) {
        let _filtros = { ...filtros, orden: (!filtros || !Object.entries(filtros).hasOwnProperty('orden')) ? FILTRO_ORDEN_DEFECTO : filtros.orden }

        // controlamos filtros no permitidos.
        if (_filtros && _filtros?.orden) {
            if (!this.filtrosOrdenPermitidosEntidad.includes(_filtros.orden)) {
                _filtros.orden = FILTRO_ORDEN_DEFECTO;
            }
        }
        return _filtros;
    }

    mapearEntidades(entidades: object[]) {
        entidades.map((value: object) => (value.hasOwnProperty('activo')) ? value['activo'] = this.auxiliar.getBoolean(value['activo']) : value);
        return entidades;
    }

    mapearEntidad(entidad: object) {
        if (entidad.hasOwnProperty('activo')) {
            entidad['activo'] = this.auxiliar.getBoolean(entidad['activo']);
        }
        return entidad;
    }

    entidadesRelacionadas(entidad: object) {
        let peticion = {};
        if (Object.keys(peticion).length > 0) {
            return forkJoin(peticion);
        } else {
            return of([]);
        }
    }

    get(id): Observable<any> {
        return this.http.get(`${this.urlEntidad}/${id}`).pipe(
            map((data: IRespApi) => this.mapearEntidad(data['data'])),
            catchError((err: IRespApi) => {
                return this.handleError(err, `IRequestGet${this.identificadorEntidad}`);
            })
        );
    }

    getEstados(): Observable<IEstado[]> {
        return this.http.get(`${this.urlEntidad}/${this.aliasControlador}Estados`).pipe(
            map((data: IRespApi) => data['data']),
            catchError((err: IRespApi) => {
                console.log('Error ', err);
                return this.handleError(err, `IRequestGetEstados${this.identificadorEntidad}`);
            })
        );
    }

    create(request: any): Observable<any> {
        return this.http.post(`${this.urlEntidad}`, request).pipe(
            map((resp: IRespApi) => {
                return resp.data;
            }),
            catchError((err: IRespApi) => {
                return this.handleError(err, `IRequestCreate${this.identificadorEntidad}`);
            })
        );
    }

    update(id: any, request?: any): Observable<any> {
        return this.http.patch(`${this.urlEntidad}/${id}`, request).pipe(
            map((data: any[]) => data['data']),
            catchError((err: IRespApi) => {
                return this.handleError(err, `IRequestUpdate${this.identificadorEntidad}`);
            })
        );
    }

    updatePut(id: any, request?: any): Observable<any> {
        return this.http.put(`${this.urlEntidad}/${id}`, request).pipe(
            map((data: any[]) => data['data']),
            catchError((err: IRespApi) => {
                return this.handleError(err, `IRequestUpdatePut${this.identificadorEntidad}`);
            })
        );
    }

    delete(id: any): Observable<any> {
        return this.http.delete(`${this.urlEntidad}/${id}`).pipe(
            catchError((err: IRespApi) => {
                return this.handleError(err, `IRequestDelete${this.identificadorEntidad}`);
            })
        );
    }

    /********************************/
    /* FICHEROS           */
    /********************************/
    postFicheros(idEntidad: string, request: any) {
        return this.http.post( `${this.urlEntidad}/${idEntidad}/ficheros`, request ) .pipe(
          map( (resp: IRespApi) => resp.data),
          catchError( (err: IRespApi) => {
            return this.handleError( err,  `IRequestPostFicheros${this.nombreEntidad}`);
          })
        );
      }

    patchFicheros(idEntidad: string, request: any) {
        return this.http.patch( `${this.urlEntidad}/${idEntidad}/ficheros`, request ) .pipe(
          map( (resp: IRespApi) => resp.data),
          catchError( (err: IRespApi) => {
            return this.handleError( err,  `IRequestPatchFicheros${this.nombreEntidad}`);
          })
        );
    }

    deleteFicheros(idFichero: string) {
        return this.http.delete(`${this.urlEntidad}/${idFichero}/ficheros`).pipe(
          map((valor: string) => this.auxiliar.getBoolean(valor)),
          catchError( (err: IRespApi) => {
            return this.handleError( err,  `IRequestDeleteFicheros${this.nombreEntidad}`);
          })
        );
    }

    get configuracionCamposMultimedia() {
        let peticion = {
          tamanoMaximoFichero: this.getConfiguracionFicheros('ficheros_maxTamanoFichero'),
          formatosPermitidos: this.getConfiguracionFicheros('ficheros_formatos'),
        };
        return forkJoin(peticion);
    }

    getConfiguracionFicheros(clave: string) {
        return this.http.get(`${this.urlEntidad}/configuracionFicheros/${clave}`).pipe(
          map(  (data: IRespApi) =>  data['valor'] )
        );
    }

    deleteImagenPrincipal(idEntidad: string) {
        return this.http.delete(`${this.urlEntidad}imagenPrincipal/${idEntidad}`).pipe(
          map((data: IRespApi) => data),
          catchError((err: IRespApi) => {
            return this.handleError(err, `IRequestDeleteImagenPrincipal${this.nombreEntidad}`);
          })
        );
    }

    postImagenPrincipal(entidad, entidadService, imagenPrincipalProducto: ImagenPrincipalPerfilComponent) {
        entidadService.getImagenPrincipal(entidad.id).subscribe(imgPrincipal => {
          imagenPrincipalProducto.establecer(imgPrincipal);
        }
        )
      }

    deleteImagenDefecto(entidad, entidadService, imagenPrincipalProducto: ImagenPrincipalPerfilComponent){
        console.log('Eliminar img principal ', entidad.id);
          entidadService.eliminarImagenPrincipal(entidad.id).subscribe(()=>{
            this.postImagenPrincipal(entidad, entidadService,imagenPrincipalProducto);
          });
      }

    putImagenPrincipal(idEntidad: string, request: any) {
        return this.http.put(`${this.urlEntidad}imagenPrincipal/${idEntidad}`, request).pipe(
          map((data: IRespApi) => data),
          catchError((err: IRespApi) => {
            return this.handleError(err, `IRequestPutImagenPrincipal${this.nombreEntidad}`);
          })
        );
    }

    /********************************/
    /* MANEJADOR ERRORES            */
    /********************************/
    public handleError(err: any, idRequest = '') {
        // control error server con un html ok
        if (err == 'OK') {
            let mensaje = environment.errorServidorSinCodigo;
            return throwError({ 'error': true, 'data': mensaje, mensaje, 'err': err });
        }
        if (err == 'Unknown Error') {
            let mensaje = environment.errorServidorUnknown;
            return throwError({ 'error': true, 'data': mensaje, mensaje, 'err': err });
        }
        // dialog específico
        err.request = idRequest;
        if (err.codigoError === 422) {
            console.log('Manejador CRUD error 422', err);
            this.errorApi$.next(err);
        }

        // pantalla común
        let mensaje = err?.mensaje;
        if (err.codigoError === 403) {
            mensaje = `${err.mensaje}: `;
            err.data.forEach(element => {
                mensaje += element.mensaje;
            });
        }
        if (err.codigoError === 404) {
            let avisos = '';
            if (err.data) {
                if (typeof err.data === 'string') {
                    avisos = err.data;
                } else {
                err.data.forEach(element => {
                    if (element.id === 999) {
                        avisos = ' ' + element.mensaje + ' ';
                    }
                });
              }
            }
            mensaje = err?.mensaje + avisos;

        }
        return throwError({ 'error': true, 'data': err.data, mensaje, 'err': err });
    }
}
