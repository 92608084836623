import {Component, OnInit, ViewChild} from '@angular/core';
import {FormatosFicherosEnum} from '@appNeo/neoShared/helpers/enums/FormatosFicheros.enum';
import {IFichero, IFicheroExtendido} from '@appNeo/neoShared/helpers/interfaces/IFichero';
import {ArbolService} from '@appNeo/neoShared/services/arbol/arbol.service';
import {MatDialog} from '@node_modules/@angular/material/dialog';
import {DragDropService, IColumnaDragDrop} from '@appNeo/neoShared/services/drag-drop/drag-drop.service';
import {LoremIpsum} from '@node_modules/lorem-ipsum';
import {ArbolAnidadoService} from '@appNeo/neoShared/services/arbol-anidado/arbol-anidado.service';
import {CHIPS_ITEMS_PRESTABLECIDOS_MUCHOS, TREE_DATA} from '@appNeo/neoShared/pages/demo-data';
import {DemoService} from '@appNeo/neoCore/services/demo/demo.service';
import {
  AccionesTablaEnum,
  IColumnaTabla,
  TablaService, TipoColumnaEditableEnum,
  TipoColumnaTablaEnum
} from '@appNeo/neoShared/services/tabla/tabla.service';
import {MatTableDataSource} from '@node_modules/@angular/material/table';
import {Subscription, timer} from 'rxjs';
import {ArbolComponent} from '@appNeo/neoShared/components/arbol/arbol.component';
import {
  CirculoProgresoIconoCompletadoComponent
} from '@appNeo/neoShared/components/circulo-progreso-icono-completado/circulo-progreso-icono-completado.component';
import {FORMINPUTCHIPSNORESPONSIVO, FORMINPUTCHIPSRESPONSIVO, ITEMS_HISTORIAL} from './demo-componentes-data';
import {HistorialComponent, IHistoriaItem} from '@appNeo/neoShared/components/historial/historial/historial.component';
import {IKanbanItem, KanbanService} from '@appNeo/neoShared/services/kanban/kanban.service';
import {IFormInput} from '@appNeo/neoShared/helpers/interfaces/IForm-input';
import {FormularioService} from '@appNeo/neoShared/services/formulario/formulario.service';

export interface IArbolData {
  item: string;
  id?: string;
  children?: IArbolData[];
  seleccionado?: boolean;
}



@Component({
  selector: 'neo-demo-componentes',
  templateUrl: './demo-componentes.component.html',
  styleUrls: ['./demo-componentes.component.scss'],
  providers: [DragDropService, ArbolAnidadoService, ArbolService, TablaService, KanbanService, FormularioService]
})
export class DemoComponentesComponent implements OnInit {
  // chips
  // items = CHIPS_ITEMS;
  // itemsPreestablecidosMuchos = CHIPS_ITEMS_PRESTABLECIDOS_MUCHOS;
  // itemsPreestablecidos = CHIPS_ITEMS_PRESTABLECIDOS;
  formInputChipsNoResponsivo: IFormInput[] = FORMINPUTCHIPSNORESPONSIVO;
  formInputChipsResponsivo: IFormInput[] = FORMINPUTCHIPSRESPONSIVO;
  datosChips = {
    idChipsAutocompleteResponsivo: CHIPS_ITEMS_PRESTABLECIDOS_MUCHOS
  }

  @ViewChild('historialDemo') historialDemo: HistorialComponent;
  items_historial = ITEMS_HISTORIAL;

  testArrayArchivos: IFicheroExtendido[] = [
    {
      id: '1',
      iconDrag: {nombre: 'Icono Arrastre Drag', class: 'icon-20 icon-ibm--draggable text-grey500'},
      nombre: 'imagen1.jpg',
      extension: '.jpg',
      peso: 1500,
      posicion: 1,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: ''
      },
      acciones: [
        {
          idAccion: AccionesTablaEnum.Editar,
          tooltip: 'Editar',
          icono: true,
          iconClass: 'icon-20 icon-ibm--edit',
          disabled: false,
        },
        {
          idAccion: AccionesTablaEnum.Eliminar,
          tooltip: 'Eliminar',
          icono: true,
          iconClass: 'icon-20 icon-ibm--trash-can text-danger',
          disabled: false,
        },
      ],
    },
    {
      id: '2',
      iconDrag: {nombre: 'Icono Arrastre Drag', class: 'icon-20 icon-ibm--draggable text-grey500'},
      nombre: 'imagen2.jpg',
      extension: '.jpg',
      peso: 200,
      posicion: 3,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: 'https://www.cleverfiles.com/howto/wp-content/uploads/2018/03/minion.jpg'
      },
      acciones: [
        {
          idAccion: AccionesTablaEnum.Editar,
          tooltip: 'Editar',
          icono: true,
          iconClass: 'icon-20 icon-ibm--edit',
          disabled: false,
        },
        {
          idAccion: AccionesTablaEnum.Eliminar,
          tooltip: 'Eliminar',
          icono: true,
          iconClass: 'icon-20 icon-ibm--trash-can text-danger',
          disabled: false,
        },
      ],
    },
    {
      id: '3',
      iconDrag: {nombre: 'Icono Arrastre Drag', class: 'icon-20 icon-ibm--draggable text-grey500'},
      nombre: 'imagen3.pdf',
      extension: '.pdf',
      peso: 1300,
      posicion: 2,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: ''
      },
      acciones: [
        {
          idAccion: AccionesTablaEnum.Editar,
          tooltip: 'Editar',
          icono: true,
          iconClass: 'icon-20 icon-ibm--edit',
          disabled: false,
        },
        {
          idAccion: AccionesTablaEnum.Eliminar,
          tooltip: 'Eliminar',
          icono: true,
          iconClass: 'icon-20 icon-ibm--trash-can text-danger',
          disabled: false,
        },
      ],
    },
    {
      id: '4',
      iconDrag: {nombre: 'Icono Arrastre Drag', class: 'icon-20 icon-ibm--draggable text-grey500'},
      nombre: 'imagen4.jpg',
      extension: '.jpg',
      peso: 2300,
      posicion: 0,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: 'https://s03.s3c.es/imag/_v0/770x420/1/4/f/El-plnaeta-Tierra-de-cristal-iStock.jpg'
      },
      acciones: [
        {
          idAccion: AccionesTablaEnum.Editar,
          tooltip: 'Editar',
          icono: true,
          iconClass: 'icon-20 icon-ibm--edit',
          disabled: false,
        },
        {
          idAccion: AccionesTablaEnum.Eliminar,
          tooltip: 'Eliminar',
          icono: true,
          iconClass: 'icon-20 icon-ibm--trash-can text-danger',
          disabled: false,
        },
      ],
    },
    {
      id: '5',
      iconDrag: {nombre: 'Icono Arrastre Drag', class: 'icon-20 icon-ibm--draggable text-grey500'},
      nombre: 'imagen5.jpg',
      extension: '.jpg',
      peso: 3300,
      posicion: 4,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: 'https://kinsta.com/es/wp-content/uploads/sites/8/2019/09/jpg-vs-jpeg.jpg'
      },
      acciones: [
        {
          idAccion: AccionesTablaEnum.Editar,
          tooltip: 'Editar',
          icono: true,
          iconClass: 'icon-20 icon-ibm--edit',
          disabled: false,
        },
        {
          idAccion: AccionesTablaEnum.Eliminar,
          tooltip: 'Eliminar',
          icono: true,
          iconClass: 'icon-20 icon-ibm--trash-can text-danger',
          disabled: false,
        },
      ],
    },
    {
      id: '6',
      iconDrag: {nombre: 'Icono Arrastre Drag', class: 'icon-20 icon-ibm--draggable text-grey500'},
      nombre: 'imagen6.jpg',
      extension: '.jpg',
      peso: 3300,
      posicion: 6,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: 'https://kinsta.com/es/wp-content/uploads/sites/8/2019/09/jpg-vs-jpeg.jpg'
      },
      acciones: [
        {
          idAccion: AccionesTablaEnum.Editar,
          tooltip: 'Editar',
          icono: true,
          iconClass: 'icon-20 icon-ibm--edit',
          disabled: false,
        },
        {
          idAccion: AccionesTablaEnum.Eliminar,
          tooltip: 'Eliminar',
          icono: true,
          iconClass: 'icon-20 icon-ibm--trash-can text-danger',
          disabled: false,
        },
      ],
    },
    {
      id: '7',
      iconDrag: {nombre: 'Icono Arrastre Drag', class: 'icon-20 icon-ibm--draggable text-grey500'},
      nombre: 'imagen7.jpg',
      extension: '.jpg',
      peso: 2300,
      posicion: 0,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: 'https://s03.s3c.es/imag/_v0/770x420/1/4/f/El-plnaeta-Tierra-de-cristal-iStock.jpg'
      },
      acciones: [
        {
          idAccion: AccionesTablaEnum.Editar,
          tooltip: 'Editar',
          icono: true,
          iconClass: 'icon-20 icon-ibm--edit',
          disabled: false,
        },
        {
          idAccion: AccionesTablaEnum.Eliminar,
          tooltip: 'Eliminar',
          icono: true,
          iconClass: 'icon-20 icon-ibm--trash-can text-danger',
          disabled: false,
        },
      ],
    },
  ];

  testArrayArchivosSubida: IFichero[] = [
    {
      id: '1',
      nombre: 'imagen1.jpg',
      extension: '.jpg',
      peso: 1500,
      posicion: 1,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: 'https://www.cleverfiles.com/howto/wp-content/uploads/2018/03/minion.jpg'
      },
    },
    {
      id: '2',
      nombre: 'imagen2.pdf',
      extension: '.pdf',
      peso: 200,
      posicion: 3,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: ''
      }
    },
    {
      id: '3',
      nombre: 'imagen3.jpg',
      extension: '.jpg',
      peso: 1300,
      posicion: 2,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: 'https://tinypng.com/images/social/website.jpg'
      }
    },
    {
      id: '4',
      nombre: 'imagen4.jpg',
      extension: '.jpg',
      peso: 2300,
      posicion: 0,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: 'https://s03.s3c.es/imag/_v0/770x420/1/4/f/El-plnaeta-Tierra-de-cristal-iStock.jpg'
      }
    },
    {
      id: '5',
      nombre: 'imagen5.jpg',
      extension: '.jpg',
      peso: 3300,
      posicion: 4,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: 'https://kinsta.com/es/wp-content/uploads/sites/8/2019/09/jpg-vs-jpeg.jpg'
      }
    }
  ];

  formatosPermitidos = [FormatosFicherosEnum.png, FormatosFicherosEnum.jpg, FormatosFicherosEnum.jpeg, FormatosFicherosEnum.pdf];

  //TABLA
  columnasTablaEditable: IColumnaTabla[] = [
    {key: 'proyecto', nombre: 'Nombre Proyecto', tipo: TipoColumnaTablaEnum.Editable, placeholder: 'Introducir Nombre Proyecto', ordenacion: false},
    {key: 'presupuesto', nombre: 'Presupuesto', tipo: TipoColumnaTablaEnum.Editable, placeholder: '0.00', tipoColumnaEditable: TipoColumnaEditableEnum.Importe, ordenacion: false},
    {key: 'incidencias', nombre: 'Nº Incidencias', tipo: TipoColumnaTablaEnum.Editable, placeholder: '0', tipoColumnaEditable: TipoColumnaEditableEnum.Cantidad, ordenacion: false},
  ];

  @ViewChild('progresoComponente') progresoComponente: CirculoProgresoIconoCompletadoComponent;

  @ViewChild('arbolContendorCabecera') arbolContendorCabecera: ArbolComponent;


  tiempoProcesoSubscripcion: Subscription;
  tiempoProceso: number = 0;
  constructor(
    private arbolService: ArbolService,
    private dialog: MatDialog,
    private dragDropService: DragDropService,
    private arbolAnidadoService: ArbolAnidadoService,
    private demoService: DemoService,
    private tablaService: TablaService,
    private kanbanService: KanbanService
  ) {
    this.tablaService.columnas = this.columnasTablaEditable;
    this.mapearDataTablaEditable();
    this.subscripcionCambiosTablaEditable();
  }

  ngOnInit(): void {
    this.iniciarArbol();
    this.setItemsDragDrop();
    this.subscripcionCambiosDragDrop();
    this.getFicheros();
    this.setItemsKanban();
  }

  ngAfterViewInit(): void {
    setTimeout(()=>{
      this.inciarHistorial();
    })

  }
  inciarHistorial() {
    this.historialDemo?.establecerHistorial(this.items_historial);
  }

  getFicheros(){
    // this.demoService.getFicheros('1').subscribe(res=>{
    //   console.log(res);
    //   this.testArrayArchivos = res.data;
    //   console.log(this.testArrayArchivos);
    // });
  }

  // ARBOL
  iniciarArbol() {
    // definimos el nivel maximo de profundidad para el servicio
    this.arbolService.nivelProfundidadMaximo = 3;
    // establecemos el objeto JSON a partir del cuál se construye el arbol
    // this.arbolService.setDatos(TREE_DATA_BASICA);
    // this.arbolService.setDatos(TREE_DATA_BASICA);

    this.arbolService.setDatos(TREE_DATA);
    this.arbolAnidadoService.setDatos(TREE_DATA);
  }

  submitRespuestaArbol(respuesta) {
    console.log(`Items arbol seleccionados son:  `, respuesta);
  }

  eliminarFichero(ids){
    /*
    LA IDEA ES QUE A LA API PODREMOS MANDARLE UN ARRAY DE IDS PARA ELIMINAR VARIOS,
    MIENTRAS RECORRO EL ARRAY Y HAGO PETICIONES INDIVIDUALES, Y AL REALIZARLAS HAGO EL GET DE FICHEROS
    */
    for(let id of ids){
      console.log(id);
      this.demoService.eliminarFichero(id).subscribe(()=>{
        this.getFicheros();
      });
    }
  }



  peticionCambiarPosicionEjemplo(nuevoArray){
    // AQUÍ HARÍAMOS PETICIÓN A LA API PARA EDITAR LOS ELEMENTOS
    console.log(nuevoArray);
  }

  subirFicheros(ficheros){
     // AQUÍ HARÍAMOS PETICIÓN A LA API PARA SUBIR NUEVOS ARCHIVOS
    //this.demoService.subirFichero(ficheros).subscribe( data => console.log('Respuesta subida ficheros ', data));
    this.getFicheros();
   // this.testArrayArchivos = this.testArrayArchivosSubida;
  }

  preSubirFicheros(ficheros){
    // AQUÍ HARÍAMOS PETICIÓN A LA API PARA SUBIR NUEVOS ARCHIVOS
    this.demoService.subirFichero(ficheros).subscribe(data => console.log('Respuesta subida ficheros ', data));

  // this.testArrayArchivos = this.testArrayArchivosSubida;
 }

 eventoEditarFichero(datos){
   console.log('Edicion datos fichero ', datos);
 }

  // DRAG DROP
  private setItemsDragDrop() {
    // TODO: SUSTITUIR POR MOCK SERVICE
    const loremIpsum = new LoremIpsum();
    const columnaIzqDrag: IColumnaDragDrop[] = [];
    for (let i = 1; i < 150; i ++) {
      columnaIzqDrag.push(
        {
          visibilidadFiltrado: true,
          id: `${i}`,
          nombre: `Categoría ${loremIpsum.generateWords(2)}`,
        }
      );
    }
    this.dragDropService.columnaIzquierda = columnaIzqDrag;
  }

  private subscripcionCambiosDragDrop() {
    this.dragDropService.columnaIzquierda$.subscribe(items => {
      console.log('Cambio items columna izq drag: ', items);
    });
    this.dragDropService.columnaDerecha$.subscribe(items => {
      console.log('Cambio items columna dcha drag: ', items);
    });
  }

  // CHIPS
  submitChipsAutocompletable(event) {}


  // TABLA EDITABLE
  mapearDataTablaEditable() {
    let data = [];
    const loremIpsum = new LoremIpsum();
    for (let i = 0; i < 49; i++) {
        data.push({
          id: String(Math.floor(Math.random() * 999999)),
          proyecto: {
            nombre: `${loremIpsum.generateWords(2)}`,
          },
          presupuesto: {
            nombre:  Math.floor(Math.random() * 555),
          },
          incidencias: {
            nombre:  Math.floor(Math.random() * 555),
          },
          acciones: AccionesTablaEnum.Eliminar
        });
    }
    this.tablaService.data =  new MatTableDataSource(data);
    this.tablaService.total = data.length;
  }

  subscripcionCambiosTablaEditable() {
    this.tablaService.dataTablaEditable$.subscribe(dataModificada => {
      console.log('NUEVO DATASOURCE TABLA EDITABLE: ', dataModificada);
    });
  }

  iniciarCirculoProcesoCarga() {
    this.tiempoProcesoSubscripcion = timer(100, 10).subscribe((x) => this.establecerValorProgreso(x));
  }

  private establecerValorProgreso (valor ) {
    if (valor < 101 ) {
      this.tiempoProceso = valor;
      this.progresoComponente?.setProgreso(this.tiempoProceso)
    }else {
      this.tiempoProcesoSubscripcion.unsubscribe();
    }
  }

  onAccionContenedorCabecera(evento) {
    console.log('Guardar arbol desde contenedor cabecera => Seleccion: ', this.arbolContendorCabecera.obetenerSeleccion());
  }


  onCrearItemHistorial(event: IHistoriaItem) {
    console.log('Crear el comentario ', event);
    let items = this.items_historial;
    items.push(event);
    this.historialDemo.establecerHistorial(items);
    this.historialDemo.mostrarResultado('ok');

  }


  private setItemsKanban() {
    let itemsColumna1 = this.obtenerDataItemKanban(0,5);
    this.kanbanService.establecerColumna({id: 'columna1', items: itemsColumna1, nombre: 'Título columna 01', totalCabecera: itemsColumna1.length.toString()});
    this.kanbanService.establecerColumna({id: 'columna2', items: this.obtenerDataItemKanban(5,10), nombre: 'Título columna 02'});
    this.kanbanService.establecerColumna({id: 'columna3', items: this.obtenerDataItemKanban(10,15), nombre: 'Título columna 03'});
    this.kanbanService.establecerColumna({id: 'columna4', items: this.obtenerDataItemKanban(15,20), nombre: 'Título columna 04'});
  }

  // TODO: SUSTITUIR POR MOCK SERVICE
  private obtenerDataItemKanban(indiceInicio= 0, elementos = 5){
    const loremIpsum = new LoremIpsum();
    const dataColumna: IKanbanItem[] = [];
    for (let i = indiceInicio; i < elementos; i ++) {
      const rutaImagen = (i % 2 === 0) ? `https://picsum.photos/40/40?random=${i}` : '';
      dataColumna.push(
        {
          visibilidadFiltrado: true,
          id: `${i}`,
          nombre: `Categoría ${loremIpsum.generateWords(2)}`,
          texto: `Filium morte ${loremIpsum.generateWords(5)}`,
          avatar: {nombre: 'Nombre Apellidos', src: rutaImagen},
          flag: (i % 2 === 0), // para alarmas o iconos extra
          extra: [{
            label: 'Fecha',
            iconoClase: 'icon-20 icon-ibm--calendar',
            texto: '19 Feb 2022',
          },
          {
            label: 'Estimación',
            iconoClase: 'icon-20 icon-ibm--time',
            texto: 'min 300h / máx. 500h',
          },
          {
            label: 'Precio',
            iconoClase: 'icon-20 icon-ibm--currency--euro',
            texto: 'min 15 / 18k',
          },
        ]
        }
      );
    }
    return dataColumna;
  }




  onClickMovimientoItemColumna(event) {
    // IKanbanItem
    console.log('Accion KANBAN onClickMovimientoItemColumna ',event);
    this.kanbanService.establecerColumna({id: event.id, items: event.items, totalCabecera: event.items.length.toString()});
  }

  onClickMovimientoItemEntreColumnas(event) {
    console.log('Accion KANBAN onClickMovimientoItemEntreColumnas ',event);
    this.kanbanService.establecerColumna({id: event.idOrigen, items: event.itemsOrigen, totalCabecera: event.itemsOrigen.length.toString()});
    this.kanbanService.establecerColumna({id: event.idDestino, items: event.itemsDestino, totalCabecera: event.itemsDestino.length.toString()});
  }

}

