import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import * as L from 'leaflet';
import { PROYECTOS } from './mapa-data';
import { ProyectosService } from '@app/core/services/proyectos.service';
import 'leaflet.markercluster';
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import 'leaflet/dist/leaflet.css';

import { NgElement, WithProperties } from '@angular/elements';
import { ProyectoDetallePersonalizadoComponent } from './components/proyecto-detalle-personalizado/proyecto-detalle-personalizado.component';
import {environment} from '@environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { LeyendaMapaComponent } from './components/leyenda-mapa/leyenda-mapa.component';

@Component({
  selector: 'neo-mapa',
  templateUrl: './mapa.component.html',
  styleUrls: ['./mapa.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MapaComponent implements OnInit {

  // @ViewChild('dialogLeyenda') dialogLeyenda: TemplateRef<any>;

  proyectos = PROYECTOS;
  mostrarErrorSinMapa: boolean = false;
  cargandoOpenStreetMap: boolean = true;
  marcadorPersonalizado: boolean = true;
  dialogRef;
  markerIconDefault =
    L.icon({
      iconSize: [25, 41],
      iconAnchor: [10, 41],
      popupAnchor: [2, -40],
      // specify the path here
      iconUrl: "https://unpkg.com/leaflet@1.4.0/dist/images/marker-icon.png",
      shadowUrl: "https://unpkg.com/leaflet@1.4.0/dist/images/marker-shadow.png"
    })

  mapContainerId: string = '';

  map: L.Map | undefined | null; 
  customOptions: L.PopupOptions = {
    'className': 'customPopup', // name custom popup,
    'maxWidth': 800,
    'maxHeight': 500,
    'autoPan': true,
    'keepInView': true
  }
  markerCluster = L.markerClusterGroup({
			iconCreateFunction: function (cluster) {
        var childCount = cluster.getChildCount();
        var cssClass = ' marker-cluster-';
        if (childCount < 5) {
          cssClass += 'small';
        } else if (childCount < 15) {
          cssClass += 'medium';
        } else {
          cssClass += 'large';
        }

        return new L.DivIcon({html: '<div><span>' + childCount + '</span></div>', className: 'marker-cluster' + cssClass, iconSize: new L.Point(40, 40)});
      },
			showCoverageOnHover: false,
		});

  coordenadasCentro = {
    latitud: 40.4165000,
    longitud: -3.7025600
  }
  constructor(
    private entidadService: ProyectosService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit() {

    this.mapContainerId = 'idMapContainer_' + Date.now();
    setTimeout(() => {
      this.cargandoOpenStreetMap = false;
      this.entidadService.getAll().subscribe(
        x => {
          if (x.length) { // Si openStreetMap obtiene ubicación
            this.proyectos = x;
            // Creacion y centramos el mapa
            // TODO ver si existe algun algoritmos para conseguir desde latitud y longitud ver cual es el centro
            this.cargarMapa(this.coordenadasCentro.latitud, this.coordenadasCentro.longitud);
            this.proyectos.forEach(proyecto => {
              if (proyecto?.actividadPrincipal)
                this.cargamosProyecto(proyecto);
            });
          } else {
            this.mostrarErrorSinMapa = true;
          }
        },
        e => {
          console.log(e);
          this.mostrarErrorSinMapa = true;
        }
      )
    }, 200);
  }

  cargarMapa(latitud: any, longitud: any) {
    if (!this.map) {
      this.map = L.map(this.mapContainerId, { zoomControl: true }).setView([latitud, longitud], 6);
      L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
         attribution:
           '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attributions">CARTO</a>',
      }).addTo(this.map);
    }
  }

  cargamosProyecto(proyecto) {
    let iconoMarcador = this.obtenerMarcadorProyecto(proyecto.actividadPrincipal.id, proyecto.proyectoEstado.id);
    let mark = L.marker([proyecto.latitud, proyecto.longitud], { icon: iconoMarcador });
    mark.bindTooltip(proyecto?.nombre);
    // mark.bindPopup(proyecto.descripcion, this.customOptions).openPopup();
    mark.bindPopup(fl => this.createPopupComponentWithMessage(proyecto), this.customOptions).openPopup();

    //  sin agrupacion iconos
    // this.map.addLayer(mark);

    // con agrupacion iconos
    this.markerCluster.addLayer(mark);
    this.map.addLayer(this.markerCluster);



  }

  public createPopupComponentWithMessage(data: any) {
    const popupEl: NgElement & WithProperties<ProyectoDetallePersonalizadoComponent> = document.createElement('popup-element') as any;
    // // Listen to the close event
    popupEl.addEventListener('closed', () => document.body.removeChild(popupEl));
    popupEl.proyecto = data;
    // // Add to the DOM
    document.body.appendChild(popupEl);
    return popupEl;
    // return data?.descripcion;
  }

  private getIconoActividadPrinicipalProyectoEstado(idActividadPrincipal, idProyectoEstado) {
    return environment.base_url_iconos + `/assets/icons/markers/marker_${idActividadPrincipal}_${idProyectoEstado}.png`;
  }
  private obtenerMarcadorProyecto(idActividadPrincipal, idProyectoEstado) {
    let marcador = undefined;
    if (idActividadPrincipal && idProyectoEstado) {
      const iconUrl = environment.base_url_iconos + `/assets/icons/markers/marker_${idActividadPrincipal}_${idProyectoEstado}.png`;
      marcador = new L.Icon({
        iconUrl: iconUrl,
        iconSize: [45, 51],
        iconAnchor: [22.5, 51],
        popupAnchor: [0, -51],
      });
    }
    if (!marcador) {
      marcador = this.markerIconDefault;
    }
    return marcador;
  }

  abrirInfoLeyenda() {
    this.map.closePopup();
    if (this.dialogRef) {
      this.dialog.closeAll();
    } else {
      this.dialogRef = this.dialog.open(LeyendaMapaComponent, { panelClass: 'dialogoLeyenda' });
    }

    this.dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      this.dialogRef = undefined;
    });
  }

  // onSubmitAccionDialogLeyenda() {
  //   this.dialog.closeAll();
  // }

  // abrirInfoLeyenda() {
  //   // COMPROBAR NO ABIERTO PROYECTO
  //   this.map.closePopup();
  //   this.dialog.open(this.dialogLeyenda);
  // }

}
