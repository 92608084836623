<section>
	<!-- seleccion modoVista -->
	<div
		[ngClass]="{'vista-horizontal'	: modoVista === modoTablaEnum.MODO_HORIZONTAL, 
				  	'vista-vertical'	: modoVista === modoTablaEnum.MODO_VERTICAL }">

		<div class="blq-filtros">
			<!-- titulo cabecera tabla modoHorizontal -->
			<div *ngIf="mostrarTituloCabecera 
						&& modoVista === modoTablaEnum.MODO_HORIZONTAL 
						&& titulo != ''"
				class="section-header">
				
				<div>
					<h2 class="text-h2">{{ titulo }}</h2>
				</div>	
				<ng-template [ngTemplateOutlet]="botonDescargaCabecera">
				</ng-template>
			</div>
			<!-- titulo filtros modoVertical -->
			<div *ngIf="mostrarTituloCabecera 
						&& modoVista === modoTablaEnum.MODO_VERTICAL 
						&& tituloFiltros != ''"
				class="section-header">
				
				<div>
					<h2 class="text-h2">{{ tituloFiltros }}</h2>
				</div>

			</div>
			<!-- filtros entidad-->
			<div *ngIf="filtrado" 
				[ngClass]="{'contenedorFiltrosOcultarM'	: numeroFiltros>1, 
							'contenedorFiltrosOcultarTV': numeroFiltros>2}"
				class="tabla-filtros blq-card">
			
				<neo-filtros-tabla 
					[keyFiltrado]="keyFiltrado" 
					[valoresIniciales]="valoresInicialesFiltrado"
					(cambioFormulario)="eventoCambioFormularioFiltros($event)">
		
					<div class="campo-busqueda">
						<neo-busqueda #filtrosBusqueda 
							*ngIf="filtrado_por_termino_busqueda" 
							class="mr-4 ml-4" 
							placeholder="Busqueda"
							[valorDefault]="guardarValorBuscadorEnSesion"
							[keyFiltrado]="keyFiltrado" 
							[botonLimpiarBuscador]="true" 
							(limpiarBuscador)="clearBuscador()"
							(changeBuscador)="eventoCambioBuscador($event)">
						</neo-busqueda>
					</div>
						
					<div *ngIf="!filtradoSinBotonSubmit 
								&& numeroFiltros > 1"
						class="btns-filtrado">

						<neo-button 
							[disabled]="deshabilitarLimpiarFiltros" 
							[text]="'Limpiar filtros'" 
							[basic]="true" 
							[color]="'primary'"
							[iconClass]="'icon-20 icon-ibm--clean'"
							(accion)="!deshabilitarLimpiarFiltros ? clearFilter(): null;">
						</neo-button>	
						<neo-button 
							[text]="textoBotonAplicarFiltros" 
							[color]="'primary'" 
							[flat]="true" 
							(accion)="submitFiltrado()">
						</neo-button>
						
					</div>
					
				</neo-filtros-tabla>
			</div>
			<!-- filtros entidad-->
			<div *ngIf="filtrado"
				class="blq-cont">

				<!-- boton acceso filtros en caso de estar ocultos por temas responsivos -->
				<neo-button 
					[ngClass]="{'botonAccesoFiltrosOcultarM'	: numeroFiltros==1, 
								'botonAccesoFiltrosOcultarTV'	: numeroFiltros==2 || numeroFiltros==1}"
					class="botonAccesoFiltros btn-block"
					[text]="'Filtrar'" 
					[flat]="true" 
					[color]="'secondary'" 
					[iconClass]="'icon-20 icon-ibm--filter'"
					(accion)="abrirFiltros()">
					
					<span *ngIf="cambioValoresFiltrado" 
						class="filtro-activo">
					</span>
				
				</neo-button>

			</div>
			<!--fin filtros entidad-->
		</div>
	  
		<div class="blq-tabla">
			<!-- titulo cabecera tabla modoVertical -->
			<div *ngIf="mostrarTituloCabecera 
						&& modoVista === modoTablaEnum.MODO_VERTICAL 
						&& titulo != ''" 
				class="section-header">
				
				<div>
					<h2 class="text-h2">{{ titulo }}</h2>
				</div>
				<ng-template [ngTemplateOutlet]="customBotoneraTablaVertical || defaultBotoneraTablaVertical">
				</ng-template>
				<ng-template [ngTemplateOutlet]="botonDescargaCabecera">
				</ng-template>
				
			</div>
			
			<!-- contenido completo tabla -->
			<div *ngIf="!ocultarTabla"
				[ngClass]="{'mt-15': mostrarTituloCabecera && modoVista === modoTablaEnum.MODO_HORIZONTAL} " 
				id="container-cards">
			
				<div *ngIf="visualizarTotales"
					class="blq-card"
					id="blq-totales">

					<neo-totales #totales 
						[data]="dataTotales" 
						(cambioOpcionSeleccionada)="cambioSelectTotales($event)">
					</neo-totales>

				</div>

				<div 
					class="blq-card"
					id="blq-tabla">
					
					<div *ngIf="mostrarTotalElementos"
						class="blq-header">
							
						<h3 class="text-p6">
							<span *ngIf="selection.selected.length>1">{{selection.selected.length}} elementos seleccionados</span>
							<span *ngIf="selection.selected.length == 1">{{selection.selected.length}} elemento seleccionado</span>
							<span *ngIf="selection.selected.length<=0">{{ totalResultados }} elementos</span>
						</h3>
						<div *ngIf="btnAccionesLote 
									|| botoneraAccionesCabecera" 
							class="blq-acciones">
						
							<div *ngIf="btnAccionesLote" 
								class="mr-2">
								
								<neo-boton-desplegable #btnDepegableAccioneMultiples 
									[referenciaElemento]="'tabla'"
									[disabled]="selection.selected.length < 1" 
									[iconoClaseBotonPrincipal]="'icon-20 icon-mdi--arrow_drop_down'"
									[textoBotonPrincipal]="'Acciones múltiples'" 
									[strokedBotonPrincipal]=true
									[claseBotonPrincipal]="'btn-sm'">
								</neo-boton-desplegable>
							
							</div>
							
							<ng-container *ngIf="botoneraAccionesCabecera">
								<div *ngFor="let accionCabecera of botoneraAccionesCabecera" 
									[ngClass]="accionCabecera.bloqueClass">

									<neo-button *ngIf="accionCabecera.mostrarBtnLg" 
									[ngClass]="accionCabecera.neoButtonClassLg" 
									[text]="accionCabecera.textoLg"
									[clase]="accionCabecera.neoButtonInputClassLg"
									[iconClass]="accionCabecera.iconClassLg" 
									[flat]="true"
									[color]="accionCabecera.color" 
									(accion)="accionCabeceraClick.emit(accionCabecera)">
									</neo-button>
									
									<neo-button *ngIf="accionCabecera.mostrarBtnXs" 
									[ngClass]="accionCabecera.neoButtonClassXs" 
									[text]="accionCabecera.textoXs"
									[clase]="accionCabecera.neoButtonInputClassXs"
									[iconClass]="accionCabecera.iconClassXs" 
									[flat]="true"
									[color]="accionCabecera.color" 
									(accion)="accionCabeceraClick.emit(accionCabecera)">
									</neo-button>
									
								</div>
							</ng-container>
						</div>

					</div>
							
					<div #content 
						[ngClass]="{'scroll-lateral': !responsiveDinamico}"
						class="table-container " >

						<table mat-table multiTemplateDataRows matSort #tabla
							[dataSource]="dataSource"
							(matSortChange)="ordenarDataSourceByColumna($event)">
							
							<ng-container *ngFor="let columna of columnas" 
								matColumnDef="{{columna.key}}">
								
								<ng-container [ngSwitch]="columna.tipo">
								
									<ng-container *ngIf="columna.tipo === 'selector'">
										
										<th mat-header-cell *matHeaderCellDef  
											[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-center') + ' ' +  
														((columna?.classResponsive) ? columna.classResponsive : '')  + 
														((aplicarClasesPersonalizadaDefecto) ? ' cell-40 cell-center' : '')"
											[attr.key]="columna.key">
										
											<mat-checkbox #toogleCheckbox 
												[ngClass]="'tbl-checkbox'" 
												[color]="'primary'"
												[checked]="toogleMasterChecked()"
												[indeterminate]="toogleMasterIndeterminate()"
												(click)="toogleMasterClick($event)">
											</mat-checkbox>
										</th>

										<td mat-cell 
											*matCellDef="let row; index as i;"
											[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-center') + ' ' +  
														((columna?.classResponsive) ? columna.classResponsive : '')  + 
														((aplicarClasesPersonalizadaDefecto) ? ' cell-40 cell-center' : '')"
											[attr.key]="columna.key">

											<mat-checkbox #childCheckbox 
												[ngClass]="'tbl-checkbox'" 
												[color]="'primary'"
												[checked]="row['seleccionado']"
												(click)="$event.stopPropagation()" 
												(change)="$event ? selection.toggle(row) : null" 
												(change)="seleccionarRow(row)" >
											</mat-checkbox>
										</td>
									</ng-container>


									<ng-container *ngIf="columna.tipo === columnaDespligueMasInfoKey">
										
										<th mat-header-cell *matHeaderCellDef 
											[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-center') + ' ' +  
														((columna?.classResponsive) ? columna.classResponsive : '')  + 
														((aplicarClasesPersonalizadaDefecto) ? ' cell-60 cell-center' : '')"
											[attr.key]="columna.key">
											<!-- columna vacia -->
										</th>
										<td mat-cell 
											*matCellDef="let row;"
											[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-center') + ' ' + 
														columna.classResponsive"
											[attr.key]="columna.key">
										
											<neo-icono-despliegue [identificador]="'fila_' + dataSource.filteredData.indexOf(row) +'_'">
											</neo-icono-despliegue>
										</td>
									</ng-container>
									
									<!-- COLUMNA EDITABLE -->
									<ng-container *ngSwitchCase="tablaService._TipoColumna.Editable">
										
										<ng-container *ngIf="columna.ordenacion">
											<th mat-header-cell mat-sort-header *matHeaderCellDef 
												[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-left') + ' ' +  
															((columna?.classResponsive) ? columna.classResponsive : '')"
												[attr.key]="columna.key">
												{{columna.nombre}}
											</th>
										</ng-container>
										
										<ng-container *ngIf="!columna.ordenacion">
											<th mat-header-cell *matHeaderCellDef 
												[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-left') + ' ' +  
															((columna?.classResponsive) ? columna.classResponsive : '')"
												[attr.key]="columna.key">
												{{columna.nombre}}
											</th>
										</ng-container>
										
										<td mat-cell 
											*matCellDef="let row; let posicion = index"
											[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-left') + ' ' + 
														columna.classResponsive"
											[attr.key]="columna.key" 
											(click)="$event.stopPropagation()">
											
											<ng-container *ngIf="(columna.tipoColumnaEditable === tablaService._TipoColumnaEditable.TextArea 
																|| columna.tipoColumnaEditable === tablaService._TipoColumnaEditable.Cantidad 
																|| columna.tipoColumnaEditable === tablaService._TipoColumnaEditable.Importe 
																|| columna.tipoColumnaEditable === tablaService._TipoColumnaEditable.Select); 
																else inputEdicion">

												<container-element [ngSwitch]="columna.tipoColumnaEditable">

													<some-element *ngSwitchCase="tablaService._TipoColumnaEditable.Importe">
														<neo-input-importe-model 
															[importe]="row[columna.key].nombre" 
															[placeholder]="columna.placeholder"
															(nuevoImporte)="this.actualizarDataSource({row: row, columnaKey: columna.key, valorAnterior: row[columna.key].nombre, valorActualizado: $event})">
														</neo-input-importe-model>
													</some-element>
												
													<some-element *ngSwitchCase="tablaService._TipoColumnaEditable.Cantidad">
														<neo-input-cantidad-model 
															[cantidad]="row[columna.key].nombre" 
															[placeholder]="columna.placeholder"
															(nuevaCantidad)="this.actualizarDataSource({row: row, columnaKey: columna.key, valorAnterior: row[columna.key].nombre, valorActualizado: $event})">
														</neo-input-cantidad-model>
													</some-element>
													
													<some-element *ngSwitchCase="tablaService._TipoColumnaEditable.Select">
														<neo-campo-select-model 
															[opciones]="row[columna.key].opciones" 
															[opcion]="row[columna.key].nombre"
															[placeholder]="columna.placeholder"
															(nuevaOpcion)="this.actualizarDataSource({row: row, columnaKey: columna.key, valorAnterior: row[columna.key].nombre, valorActualizado: $event})">
														</neo-campo-select-model>
													</some-element>
							
													<some-element *ngSwitchCase="tablaService._TipoColumnaEditable.TextArea">
														<mat-form-field 
															[ngClass]="{'textarea-abierto': textAreaOpen[dataSource.filteredData.indexOf(row)]}"
															class="campo-editable-textarea"
															appearance="outline" 
															floatLabel="never">

														<!-- TODO buscar solucion para aplicar clase  campo-editable-ellipsis, mientras usamos pipe-->
															<input matInput #inputTextAreaEditable
																[ngClass]="{ 'campo-editable-oculto': campoEditableFoco == row}"
																placeholder="Introducir texto" 
																[value]="row[columna.key].nombre | truncarTexto : 30"
																(focus)="focusInputMascaraTextareaEdicion(row, dataSource.filteredData.indexOf(row))">

															<textarea matInput cdkTextareaAutosize
																[ngClass]="{'campo-editable-oculto': campoEditableFoco != row}" 
																#textAreaColumnaEditable="cdkTextareaAutosize" 
																cdkAutosizeMinRows="{{numeroRowsCeldaEditableInicial}}"
																cdkAutosizeMaxRows="{{numeroRowsCeldaEditableInicial}}" 
																[value]="row[columna.key].nombre"
																(click)="$event.stopPropagation()"
																(focusout)="actualizarDataSource({row: row, columnaKey: columna.key, valorAnterior: valorTextAreaEdicion(row[columna.key].nombre), valorActualizado: $event.target['value']}); focusOutTextAreaEdicion(dataSource.filteredData.indexOf(row));">
															</textarea>
														</mat-form-field>
													</some-element>
													
												</container-element>
											</ng-container>

											<ng-template #inputEdicion>
												<mat-form-field 
													appearance="outline" 
													floatLabel="never">
													
													<input matInput
														[(ngModel)]="row[columna.key].nombre"
														class="campo-editable-ellipsis" 
														placeholder="Introducir texto" 
														[value]="row[columna.key].nombre">

												</mat-form-field>
											</ng-template>

										</td>
									</ng-container>
									
									<!-- COLUMNA IMAGEN -->
									<ng-container *ngSwitchCase="tablaService._TipoColumna.Imagen">

										<ng-container *ngIf="columna.ordenacion">
											<th mat-header-cell mat-sort-header *matHeaderCellDef 
												[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-center') + ' ' + 
															columna.classResponsive + 
															((aplicarClasesPersonalizadaDefecto) ? ' cell-60 cell-center' : '')"
												[attr.key]="columna.key">
												{{columna.nombre}}
											</th>
										</ng-container>

										<ng-container *ngIf="!columna.ordenacion">
											<th mat-header-cell *matHeaderCellDef 
												[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-center') + ' ' + 
															columna.classResponsive + 
															((aplicarClasesPersonalizadaDefecto) ? ' cell-60 cell-center' : '')"
												[attr.key]="columna.key">
												{{columna.nombre}}
											</th>
										</ng-container>

										<td mat-cell 
											*matCellDef="let row"
											[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-center') + ' ' + 
														columna.classResponsive + 
														((aplicarClasesPersonalizadaDefecto) ? ' cell-60 cell-center' : '')"
											[attr.key]="columna.key">

											<img [src]="row[columna.key]?.src ? row[columna.key]?.src : imgDefault" 
												alt="{{row[columna.key]?.alt}}" />
										</td>
									</ng-container>
									
									<!-- COLUMNA AVATAR -->
									<ng-container *ngSwitchCase="tablaService._TipoColumna.Avatar">

										<ng-container *ngIf="columna.ordenacion">
											<th mat-header-cell mat-sort-header *matHeaderCellDef 
												[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-left') + ' ' + 
															columna.classResponsive + 
															((aplicarClasesPersonalizadaDefecto) ? ' cell-220 cell-left' : '')"
												[attr.key]="columna.key">
												{{columna.nombre}}
											</th>
										</ng-container>
										
										<ng-container *ngIf="!columna.ordenacion">
											<th mat-header-cell *matHeaderCellDef 
												[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-left') + ' ' + 
															columna.classResponsive + 
															((aplicarClasesPersonalizadaDefecto) ? ' cell-220 cell-left' : '')"
												[attr.key]="columna.key">
												{{columna.nombre}}
											</th>
										</ng-container>
										
										<td mat-cell *matCellDef="let row"
												[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-left') + ' ' + 
															columna.classResponsive + 
															((aplicarClasesPersonalizadaDefecto) ? ' cell-220 cell-left' : '')"
												[attr.key]="columna.key">
												
											<div class="avatar-txt">
												<ngx-avatar 
													class="ngx-mi-avatar"
													name="{{row[columna.key]?.nombre}}" 
													initialsSize="1" 
													src="{{row[columna.key]?.src}}" 
													size="40">
												</ngx-avatar>
												<span>{{row[columna.key]?.nombre}}</span>
											</div>
										</td>
									</ng-container>
										
									<!-- COLUMNA ICONO -->
									<ng-container *ngSwitchCase="tablaService._TipoColumna.Icono">

										<ng-container *ngIf="columna.ordenacion">
											<th mat-header-cell mat-sort-header *matHeaderCellDef 
												[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'hidden-xs cell-center') + ' ' + 
															columna.classResponsive + 
															((aplicarClasesPersonalizadaDefecto) ? ' hidden-xs cell-40 cell-center ' : '')"
												[attr.key]="columna.key">
												{{columna.nombre}}
											</th>
										</ng-container>
										
										<ng-container *ngIf="!columna.ordenacion">
											<th mat-header-cell *matHeaderCellDef 
												[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'hidden-xs cell-center') + ' ' + 
															columna.classResponsive + 
															((aplicarClasesPersonalizadaDefecto) ? ' hidden-xs cell-40 cell-center ' : '')"
												[attr.key]="columna.key">
												{{columna.nombre}}
											</th>
										</ng-container>

										<td mat-cell 
											*matCellDef="let row; index as i;"
											[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'hidden-xs cell-center') + ' ' + 
														columna.classResponsive + 
														((aplicarClasesPersonalizadaDefecto) ? ' hidden-xs cell-40 cell-center ' : '')"
											[attr.key]="columna.key">
											
											<neo-icono 
												[color]="row[columna.key]?.color" 
												[iconoClase]="row[columna.key]?.class">
											</neo-icono>
										</td>
									</ng-container>
									
									<!-- COLUMNA ESTADO -->
									<ng-container *ngSwitchCase="tablaService._TipoColumna.Estado">

										<ng-container *ngIf="columna.ordenacion">
											<th mat-header-cell mat-sort-header *matHeaderCellDef 
												[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-center') + ' ' + 
															columna.classResponsive + 
															((aplicarClasesPersonalizadaDefecto) ? ' cell-80 cell-center' : '')"
												[attr.key]="columna.key">
												{{columna.nombre}}
											</th>
										</ng-container>
										
										<ng-container *ngIf="!columna.ordenacion">
											<th mat-header-cell *matHeaderCellDef 
												[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-center') + ' ' + 
															columna.classResponsive + 
															((aplicarClasesPersonalizadaDefecto) ? ' cell-80 cell-center' : '')"
												[attr.key]="columna.key">
												{{columna.nombre}}
											</th>
										</ng-container>
										
										<td mat-cell 
											*matCellDef="let row" 
											[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-center') + ' ' + 
														columna.classResponsive + 
														((aplicarClasesPersonalizadaDefecto) ? ' cell-80 cell-center' : '')"
											class="" 
											[attr.key]="columna.key">
											
											<neo-estado-label [estado]="row[columna.key]"></neo-estado-label>
										</td>
									</ng-container>
									
									<!--COLUMNA ESTADO BOOLEAN -->
									<ng-container *ngSwitchCase="tablaService._TipoColumna.EstadoBoolean">
										<ng-container *ngIf="columna.ordenacion">
											<th mat-header-cell mat-sort-header *matHeaderCellDef 
												[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-center') + ' ' + 
															columna.classResponsive + 
															((aplicarClasesPersonalizadaDefecto) ? ' cell-80 cell-center' : '')"
												[attr.key]="columna.key">
												{{columna.nombre}}
											</th>
										</ng-container>

										<ng-container *ngIf="!columna.ordenacion">
											<th mat-header-cell *matHeaderCellDef 
												[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-center') + ' ' + 
															columna.classResponsive + 
															((aplicarClasesPersonalizadaDefecto) ? ' cell-80 cell-center' : '')"
												[attr.key]="columna.key">
												{{columna.nombre}}
											</th>
										</ng-container>
										
										<td mat-cell 
											*matCellDef="let row" 
											[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-center') + ' ' + 
														columna.classResponsive + 
														((aplicarClasesPersonalizadaDefecto) ? ' cell-80 cell-center' : '')"
											class="" 
											[attr.key]="columna.key">

											<neo-estado-boolean-label 
												[valor]="row[columna.key]"
												[configuracion]="(row[columna.key +'_configuracion']) ? row[columna.key + '_configuracion'] : undefined">
											</neo-estado-boolean-label>
										</td>
									</ng-container>
										
									<!-- COLUMNA BOOLEAN -->
									<ng-container *ngSwitchCase="tablaService._TipoColumna.Boolean">
									
										<ng-container *ngIf="columna.ordenacion">
											<th mat-header-cell mat-sort-header *matHeaderCellDef 
												[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-center') + ' ' + 
															columna.classResponsive + 
															((aplicarClasesPersonalizadaDefecto) ? ' cell-80 cell-center' : '')"
												[attr.key]="columna.key">
												{{columna.nombre}}
											</th>
										</ng-container>
									
										<ng-container *ngIf="!columna.ordenacion">
											<th mat-header-cell *matHeaderCellDef 
												[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-center') + ' ' + 
															columna.classResponsive + 
															((aplicarClasesPersonalizadaDefecto) ? ' cell-80 cell-center' : '')"
												[attr.key]="columna.key">
												{{columna.nombre}}
											</th>
										</ng-container>
									
										<td mat-cell 
											*matCellDef="let row" 
											[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-center') + ' ' + 
														columna.classResponsive + 
														((aplicarClasesPersonalizadaDefecto) ? ' cell-80 cell-center' : '')"
											class=""
											[attr.key]="columna.key">
											
											<neo-campo-boolean-palanca-model 
												[valor]="row[columna.key]"
												[color]="'primary'"
												(nuevoValor)="this.actualizarDataSource({row: row, columnaKey: columna.key, valorAnterior: getValorAnteriorCampoBoolean(row, columna.key) , valorActualizado: $event});">
											</neo-campo-boolean-palanca-model>
										</td>        
									</ng-container>
				
									<!-- COLUMNA OBJETO -->
									<ng-container *ngSwitchCase="tablaService._TipoColumna.Objecto">

										<ng-container *ngIf="columna.ordenacion">
											<th mat-header-cell mat-sort-header *matHeaderCellDef 
												[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-left') + ' ' + 
															columna.classResponsive + 
															((aplicarClasesPersonalizadaDefecto) ? ' cell-160 cell-left' : '')"
												[attr.key]="columna.key">
												{{columna.nombre}}
											</th>
										</ng-container>
										
										<ng-container *ngIf="!columna.ordenacion">
											<th mat-header-cell *matHeaderCellDef 
												[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-left') + ' ' + 
															columna.classResponsive + 
															((aplicarClasesPersonalizadaDefecto) ? ' cell-160 cell-left' : '')"
												[attr.key]="columna.key">
												{{columna.nombre}}
											</th>
										</ng-container>
										
										<td mat-cell 
											*matCellDef="let row" 
											[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-left') + ' ' + 
														columna.classResponsive + 
														((aplicarClasesPersonalizadaDefecto) ? ' cell-160 cell-left' : '')"
											class="limite-lineas campo-texto" 
											[attr.key]="columna.key">
											
											<span>{{row[columna.key + 'Mapping']}} {{columna.unidad}}</span>
										</td>
									</ng-container>
				
									<!-- COLUMNA TEXTO -->
									<ng-container *ngSwitchCase="tablaService._TipoColumna.Texto">

										<ng-container *ngIf="columna.ordenacion">
											<th mat-header-cell mat-sort-header *matHeaderCellDef 
												[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-left') + ' ' + 
															columna.classResponsive + 
															((aplicarClasesPersonalizadaDefecto) ? ' cell-160 cell-left' : '')"
												[attr.key]="columna.key">
												{{columna.nombre}}
											</th>
										</ng-container>
										
										<ng-container *ngIf="!columna.ordenacion">
											<th mat-header-cell *matHeaderCellDef 
												[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-left') + ' ' + 
															columna.classResponsive + 
															((aplicarClasesPersonalizadaDefecto) ? ' cell-160 cell-left' : '')"
												[attr.key]="columna.key">
												{{columna.nombre}}
											</th>
										</ng-container>
										
										<td mat-cell 
											*matCellDef="let row" 
											[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-left') + ' ' + 
														columna.classResponsive + 
														((aplicarClasesPersonalizadaDefecto) ? ' cell-160 cell-left' : '')"
											class="limite-lineas campo-texto" 
											[attr.key]="columna.key">
												
											<span>{{ row[columna.key] }} {{columna.unidad}}</span>
										</td>
									</ng-container>
										
									<!-- COLUMNA ENLACE PRINCIPAL -->
									<ng-container *ngSwitchCase="tablaService._TipoColumna.EnlacePrincipal" class="">
										
										<ng-container *ngIf="columna.ordenacion">
											<th mat-header-cell mat-sort-header *matHeaderCellDef 
												[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-left') + ' ' + 
															columna.classResponsive + 
															((aplicarClasesPersonalizadaDefecto) ? ' cell-320 cell-left' : '')"
												[attr.key]="columna.key">
											{{columna.nombre}}
											</th>
										</ng-container>

										<ng-container *ngIf="!columna.ordenacion">
											<th mat-header-cell *matHeaderCellDef 
												[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-left') + ' ' + 
															columna.classResponsive + 
															((aplicarClasesPersonalizadaDefecto) ? ' cell-320 cell-left' : '')"
												[attr.key]="columna.key">
											{{columna.nombre}}
											</th>
										</ng-container>
										
										<td mat-cell 
											*matCellDef="let row" 
											[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-left') + ' ' + 
											columna.classResponsive + 
											((aplicarClasesPersonalizadaDefecto) ? ' cell-320 cell-left' : '')"
											class="limite-lineas campo-enlace" 
											[attr.key]="columna.key">

											<a 
												class="link linkPrincipal" 
												[routerLink]="(row[columna.key + 'Mapping']) ? obtenerRouterLink(row[columna.key + 'Mapping']): undefined"
												(click)="$event.stopPropagation()">
												{{ row[columna.key] }} {{columna.unidad}}
											</a>
										</td>
									</ng-container>
										
									<!-- COLUMNA ENLACE SECUNDARIO -->
									<ng-container *ngSwitchCase="tablaService._TipoColumna.EnlaceSecundario" class="">

										<ng-container *ngIf="columna.ordenacion">
											<th mat-header-cell mat-sort-header *matHeaderCellDef 
												[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-left') + ' ' + 
															columna.classResponsive + 
															((aplicarClasesPersonalizadaDefecto) ? ' cell-160 cell-left' : '')"
												[attr.key]="columna.key">
												{{columna.nombre}}
											</th>
										</ng-container>
										
										<ng-container *ngIf="!columna.ordenacion">
											<th mat-header-cell *matHeaderCellDef 
												[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-left') + ' ' + 
															columna.classResponsive + 
															((aplicarClasesPersonalizadaDefecto) ? ' cell-160 cell-left' : '')"
												[attr.key]="columna.key">
												{{columna.nombre}}
											</th>
										</ng-container>
										
										<td mat-cell 
											*matCellDef="let row" 
											[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-left')  + ' ' + 
														columna.classResponsive  + 
														((aplicarClasesPersonalizadaDefecto) ? ' cell-160 cell-left' : '')"
											class="limite-lineas campo-enlace"
											[attr.key]="columna.key">
											
											<ng-container *ngIf="row[columna.key + 'Mapping']?.url; else urlInterna">
												<a 
													class="link linkSecundario"
													(click)="(row[columna.key + 'Mapping']) ? gestionarEnlace(row[columna.key + 'Mapping']) : $event.stopPropagation()">
													{{ row[columna.key] }} {{columna.unidad}}
												</a>
											</ng-container>

											<ng-template #urlInterna>
												<a class="link linkSecundario" 
													[routerLink]="(row[columna.key + 'Mapping']) ? obtenerRouterLink(row[columna.key + 'Mapping']): undefined"
													(click)="$event.stopPropagation()">
													{{ row[columna.key] }} {{columna.unidad}}
												</a>
											</ng-template>
										</td>                            
									</ng-container>
										
									<!-- COLUMNA IMPORTE -->
									<ng-container *ngSwitchCase="tablaService._TipoColumna.Importe">

										<ng-container *ngIf="columna.ordenacion">
											<th mat-header-cell mat-sort-header *matHeaderCellDef 
												[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-right') + ' ' + 
															columna.classResponsive  + 
															((aplicarClasesPersonalizadaDefecto) ? ' cell-140 cell-right ' : '')"
												[attr.key]="columna.key">
												{{columna.nombre}}
											</th>
										</ng-container>
										
										<ng-container *ngIf="!columna.ordenacion">
											<th mat-header-cell *matHeaderCellDef 
												[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-right') + ' ' + 
															columna.classResponsive + 
															((aplicarClasesPersonalizadaDefecto) ? ' cell-140 cell-right ' : '')"
												[attr.key]="columna.key">
												{{columna.nombre}}
											</th>
										</ng-container>
										
										<td mat-cell 
											*matCellDef="let row"
											[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-right') + ' ' + 
														columna.classResponsive + 
														((aplicarClasesPersonalizadaDefecto) ? ' cell-140 cell-right ' : '')"
											[attr.key]="columna.key">
											{{ row[columna.key] }} {{columna.unidad}}
										</td>
									</ng-container>
									
									<!-- COLUMNA FECHA, FECHAHORA, HORA -->
									<ng-container *ngSwitchCase="[tablaService._TipoColumna.Fecha, tablaService._TipoColumna.FechaHora, tablaService._TipoColumna.Hora].includes(columna.tipo) ? columna.tipo : '' ">
										
										<ng-container *ngIf="columna.ordenacion">
											<th mat-header-cell mat-sort-header *matHeaderCellDef 
												[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : '  cell-left ') + ' ' + 
															columna.classResponsive +
															((aplicarClasesPersonalizadaDefecto) ? ' cell-120 cell-left ' : '')"
												[attr.key]="columna.key">
												{{columna.nombre}}
											</th>
										</ng-container>

										<ng-container *ngIf="!columna.ordenacion">
											<th mat-header-cell *matHeaderCellDef 
												[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : '  cell-left ') + ' ' + 
															columna.classResponsive +
															((aplicarClasesPersonalizadaDefecto) ? ' cell-120 cell-left ' : '')"
												[attr.key]="columna.key">
												{{columna.nombre}}
											</th>
										</ng-container>
							
										<td mat-cell 
											*matCellDef="let element" 
											[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : '  cell-left ') + ' ' + 
														columna.classResponsive  + 
														((aplicarClasesPersonalizadaDefecto) ? ' cell-120 cell-left ' : '')"
											class="limite-lineas campo-fecha"
											[attr.key]="columna.key">
										
											<div *ngIf="!esString(element[columna.key])">
												<ng-container *ngIf="tablaService._TipoColumna.Fecha == columna.tipoDatePicker ">
													{{element[columna.key] | date: 'd/M/y'}}
												</ng-container>
												<ng-container *ngIf="tablaService._TipoColumna.FechaHora == columna.tipoDatePicker ">
													{{element[columna.key] | date: 'd/M/y, h:mm'}}
												</ng-container>
												<ng-container *ngIf="tablaService._TipoColumna.Hora == columna.tipoDatePicker ">
													{{element[columna.key] | date: 'HH:mm'}}
												</ng-container>
											</div>
											<div *ngIf="esString(element[columna.key])">
												{{element[columna.key]}}
											</div>
										</td>
									</ng-container>

									<!-- COLUMNA VERMAS -->
									<ng-container *ngSwitchCase="tablaService._TipoColumna.VerMas">
              
										<ng-container *ngIf="columna.ordenacion">
											<th mat-header-cell mat-sort-header *matHeaderCellDef [attr.key]="columna.key"
											[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-right') + ' ' + columna.classResponsive  + ((aplicarClasesPersonalizadaDefecto) ? ' cell-140 cell-right ' : '')">
											{{columna.nombre}}
											</th>
										</ng-container>
										
										<ng-container *ngIf="!columna.ordenacion">
											<th mat-header-cell *matHeaderCellDef 
												[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-right') + ' ' +
												columna.classResponsive + 
												((aplicarClasesPersonalizadaDefecto) ? ' cell-140 cell-right ' : '')"
												[attr.key]="columna.key">
												{{columna.nombre}}
											</th>
										</ng-container>
										
										<td mat-cell 
											*matCellDef="let row"
											[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-right') + ' ' +
														columna.classResponsive + 
														((aplicarClasesPersonalizadaDefecto) ? ' cell-140 cell-right ' : '')"
											[attr.key]="columna.key">
											
											<neo-dialogo-ver-mas 
												[data]="row[columna.key]" 
												[configuracionTabla]="columna?.data" 
												[customProyeccion]="customTemplateVerMas">
											</neo-dialogo-ver-mas>
										</td>
									</ng-container>
					
									<!-- COLUMNA OTRO -->
									<ng-container *ngSwitchCase="tablaService._TipoColumna.Otro">
										
										<ng-container *ngIf="columna.ordenacion">
											<th mat-header-cell mat-sort-header *matHeaderCellDef 
												[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-left') + ' ' + 
															columna.classResponsive  +
															((aplicarClasesPersonalizadaDefecto) ? ' cell-160 cell-left ' : '')"
												[attr.key]="columna.key">
												
												<div>{{columna.nombre}}</div>
											</th>
										</ng-container>
										
										<ng-container *ngIf="!columna.ordenacion">
											<th mat-header-cell *matHeaderCellDef 
												[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-left') + ' ' + 
															columna.classResponsive + 
															((aplicarClasesPersonalizadaDefecto) ? ' cell-160 cell-left ' : '')"
												[attr.key]="columna.key">
											
												<div>{{columna.nombre}}</div>
											</th>
										</ng-container>
										
										<td mat-cell 
											*matCellDef="let row" 
											[ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-left'  + 
														((aplicarClasesPersonalizadaDefecto) ? ' cell-160 cell-left ' : '')"
											class="campo-otro" 
											[attr.key]="columna.key">
											
											<div>{{ row[columna.key] }}</div>
										</td>
									</ng-container>
										
									<!-- COLUMNA ACCIONES -->
									<ng-container *ngSwitchCase="tablaService._TipoColumna.Acciones">
										
										<th mat-header-cell *matHeaderCellDef 
											[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-center') + ' ' + 
														columna.classResponsive + 
														((aplicarClasesPersonalizadaDefecto) ? ' cell-140 cell-center ' : '')"
											[attr.key]="columna.key">
											
											<span [ngClass]="{'botonera-acciones': columna.tipo == tablaService._TipoColumna.Acciones}">
											{{columna.nombre}}
											</span>
										</th>
										
										<td mat-cell 
											*matCellDef="let row" 
											class=""
											[ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-center') + ' ' + 
														columna.classResponsive + 
														((aplicarClasesPersonalizadaDefecto) ? ' cell-140 cell-center ' : '')">
										
											<div 
												[ngClass]="{'ocultar-botonera-acciones-responsive': ocultarBotoneraAccionesRowResponsive}"
												class="botonera-acciones">
					
												<!--  boton fijo para mostrar columnas ocultas por responsivo-->
												<neo-button *ngIf="columnasOcultas?.length 
																	&& mostrarColumnasOcultasEnDialogo" 
													matTooltip="Ver información"
													class="btn-accion" 
													[disabled]="deshabilitarAcciones"
													[icono]="true" 
													[iconClass]="'icon-20 icon-ibm--information'" 
													(accion)="$event.stopPropagation()" 
													(accion)="verInformacionRowClick(row)">
												</neo-button>
																
												<!--  botonera configurable-->
												<neo-button *ngFor="let accion of row[columna.key]" 
													matTooltip="{{accion.tooltip}}" 
													class="btn-accion" 
													[disabled]="deshabilitarAccionRow(accion)"
													[icono]="accion.icono" 
													[iconClass]="accion.iconClass"
													(accion)="$event.stopPropagation()"
													(accion)="!deshabilitarAccionRow(accion) && clickAccionRowTabla.emit([row, accion])">
												</neo-button>      
											</div>
											<div *ngIf="ocultarBotoneraAccionesRowResponsive"
												class="botonera-acciones-desplegable">

												<neo-boton-desplegable 
													[referenciaElemento]="'tabla'" 
													[item]="row"
													[iconoBotonPrincipal]="'more_vert'"
													[colorBotonPrincipal]="'black'" 
													[iconoTipoBotonPrincipal]=true 
													[textoBotonPrincipal]="''" 
													[accionesBotonDesplegable]="obtenerAccionesBotonDesplegable(row)"
													[disabled]="deshabilitarAcciones" 
													(click)="rowSeleccionadaBotonDesplegable = row; $event.stopPropagation()">
												</neo-boton-desplegable>
											</div>
										</td>
									</ng-container>
										
									<!-- COLUMNA DEFAULT -->
									<ng-container *ngSwitchDefault>
										<!--TODO: Codigo duplicado. Se puede hacer depender la directiva mat-sort-top de withSorting en default y para cada columna tipada?-->
										
										<ng-container *ngIf="columna.ordenacion">
											<th mat-header-cell mat-sort-header *matHeaderCellDef 
												[ngClass]="(columna?.classResponsive + ' ' + 
															columna?.clasePersonalizada?.join(' ')) + ' ' + 
															columna.classResponsive"
												[attr.key]="columna.key">
												{{columna.nombre}}
											</th>
										</ng-container>
										
										<ng-container *ngIf="!columna.ordenacion">
											<th mat-header-cell *matHeaderCellDef 
												[ngClass]="(columna?.classResponsive + '  ' + 
															columna?.clasePersonalizada?.join(' ')) + ' ' + 
															columna.classResponsive"
												[attr.key]="columna.key">
												{{columna.nombre}}
											</th>
										</ng-container>
										
										<td mat-cell 
											*matCellDef="let element" 
											[ngClass]="'  ' + columna?.clasePersonalizada?.join(' ') + ' ' + 
														columna.classResponsive" 
											class="align-left"
											[attr.key]="columna.key">
											{{element[columna.key]}} {{columna?.unidad}} 
										</td>
									</ng-container>
								<!--Fin switch columnas-->
								</ng-container>                    
							<!-- Fin contenedor bucle columnas -->
							</ng-container>                    
								
							<ng-container matColumnDef="expandedDetail">
								<td mat-cell 
									*matCellDef="let row;" 
									[attr.colspan]="columnas?.length">

									<div 
										class="contenedor-mas-info" 
										[@detailExpand]="row == filaExpandida ? 'expanded' : 'collapsed'">
										
										<neo-contenedor-masinfo 
											[propiedades]="propiedadesMasInfo"
											(cambioCeldaEditable)="$event.row = row; actualizarDataSource($event)">
										</neo-contenedor-masinfo>
									</div>
								</td>
							</ng-container>
									
							<tr mat-header-row 
								*matHeaderRowDef="keysColumnas; sticky: true">
							</tr>
							<tr mat-row #tableRow
								*matRowDef="let row; columns: keysColumnas;let i = index;"
								[ngClass]="{'seleccionado' : selection.isSelected(row)}"
								(click)="clickRow(row, dataSource.filteredData.indexOf(row))">
							</tr>
							<tr mat-row 
								*matRowDef="let row; columns: ['expandedDetail']" 
								[ngClass]="{'expandida' : row == filaExpandida, 'colapsada': row == null || row != filaExpandida}"
								class="contenedor-mas-info-fila">
							</tr>
						<!-- Fin tabla -->
						</table>

						<ng-container *ngIf="!totalResultados">
							<div class="sin-resultados text-center">
								<div class="text-h3 mt-10">
									<neo-icono [iconoClase]="'icon-60 icon-ibm--information'"></neo-icono>
									<p>No se han encontrado registros.</p>
								</div>
							</div>
						</ng-container>
					<!-- Fin contenedor tabla -->
					</div>

					<ng-template #tableEmpty>
						<div class="sin-resultados">
							<neo-icono [iconoClase]="'icon-60 icon-ibm--information'"></neo-icono>
							<p class="text-h3 mt-10">No se han encontrado registros.</p>
						</div>
					</ng-template>

					<mat-paginator *ngIf="paginacion && totalResultados > 0"
						[iconosClasesPaginador]="{navigationFirst : 'icon-ibm--page--first', navigationPrevious : 'icon-ibm--chevron--left', navigationNext: 'icon-ibm--chevron--right',navigationLast: 'icon-ibm--page--last'}"
						[length]="totalResultados" 
						[pageSizeOptions]="opcionesPaginacion"
						[showFirstLastButtons]=true 
						(page)="eventoCambioPaginador($event)">
					</mat-paginator>
				<!-- Fin bloque contenido tabla (blq-card) -->
				</div>
			<!-- Fin contenido completo tabla -->
			</div>
			
			<!-- contenido alternativo a tabla oculta-->
			<div *ngIf="ocultarTabla"
				id="blq-container-tabla-oculta">

				<ng-template [ngTemplateOutlet]="customTablaOculta || defaultTemplate"></ng-template>
				<ng-template #defaultTemplate>NO MOSTRAMOS LA TABLA</ng-template>
			</div>
		<!-- Fin bloque tabla (blq-tabla) -->
		</div>
	<!-- Fin seleccion modoVista -->  
	</div>
</section>
  
  
<ng-template #dialogoMasInfo>
	
	<neo-acciones-dialog #accionDialogMasInfo
		[dialogTittle]="'Información'"
		[botonera]="[]">
		
		<neo-perfil #perfilMasInfo
			[propiedades]="propiedadesMasInfo">
		</neo-perfil>
	</neo-acciones-dialog>
</ng-template>
  
<!-- filtros responsivos ocultos -->
<ng-template #dialogFiltrosResponsivos>
	<neo-acciones-dialog
		[dialogTittle]="'Filtros'"
		[botonCerrarDialogoCabecera]=true
		[botonera]="botoneraDialogFiltrosResponsivos"
		(accionBoton)="submitAccionDialogFiltrosResponsivos($event)">
	
		<neo-busqueda #filtrosBusquedaDialog 
			*ngIf="filtrado_por_termino_busqueda"
			placeholder="Busqueda"
			[botonLimpiarBuscador]="true"
			[valorDefault]="true"
			[keyFiltrado]="keyFiltrado"
			(limpiarBuscador)="clearBuscador()"
			(changeBuscador)="eventoCambioBuscador($event)">
		</neo-busqueda>

		<neo-filtros-tabla
			[keyFiltrado]="keyFiltrado"
			[valoresIniciales]="valoresInicialesFiltrado"
			(cambioFormulario)="eventoCambioFormularioFiltros($event)">
		</neo-filtros-tabla>
	</neo-acciones-dialog>
</ng-template>
  
<ng-template #defaultBotoneraTablaVertical></ng-template>

<!-- boton decarga cabecera comun a titulo tabla -->
<ng-template #botonDescargaCabecera>
	<ng-container *ngIf="btnDescargarPdfCabecera">
		<div>
			<neo-button [basic]="tipoBotonDescarga == modoBotonEnum.basic"
				[stroked]="tipoBotonDescarga == modoBotonEnum.stroked" 
				[text]="textoDescargarPdfCabeceraIcono"
				[color]="'primary'" 
				[clase]="'btn-outline btn-margin-left'" 
				[iconClass]="btnDescargarPdfCabeceraIcono"
				(accion)="descargarPdf()">
			</neo-button>
		</div>
	</ng-container>
</ng-template>